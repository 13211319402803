import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/auth/AuthService';

// Define the props type
interface NavButtonProps {
  navigationPageTitle?: string;
  startIcon?: React.ReactNode;
  authorizedRoles?: string[];
  title?: string;
  mono?: boolean;
}


const NavButton: React.FC<NavButtonProps> = ({
  navigationPageTitle,
  startIcon,
  authorizedRoles,
  title = '',
  mono = false,
}) => {
  const location = window.location;
  const navigate = useNavigate();
  const { user } = useAuth();
  const { REACT_APP_URL } = process.env;

  if (authorizedRoles == null) {
    return (
      <Button
        variant='text'
        color={
          location.pathname.toLowerCase() === REACT_APP_URL + navigationPageTitle.toLowerCase()
            ? 'primary'
            : 'inherit'
        }
        sx={{
          // color: mono ? 'inherit' : '',
          whiteSpace: 'nowrap',
        }}
        startIcon={startIcon}
        onClick={() => {
          navigate(`/${navigationPageTitle}`);
        }}
      >
        {title}
      </Button>
    );
  } else {
    return (
      user?.roleCheck(authorizedRoles) && (
        <Button
          variant='text'
          color={
            location.pathname.toLowerCase() === REACT_APP_URL + navigationPageTitle.toLowerCase()
              ? 'primary'
              : 'inherit'
          }
          sx={{
            // color: mono ? 'inherit' : '',
            whiteSpace: 'nowrap',
          }}
          startIcon={startIcon}
          onClick={() => {
            navigate(`/${navigationPageTitle}`);
          }}
        >
          {title}
        </Button>
      )
    );
  }
};

export default NavButton;
