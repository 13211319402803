import { Checkbox } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { renderAutocompleteCellDisplay } from '../../../../../utils/components/CrudDataGrid/AutocompleteCellWithTextField';
import { renderAutocompleteEditCellWithTextField } from '../../../../../utils/components/CrudDataGrid/AutocompleteEditCellWithTextField';
import CrudDataGrid from '../../../../../utils/components/CrudDataGrid/CrudDataGrid';

export function OtherPIDataGrid() {
  const [personalItemOptions, setPersonalItemOptions] = useState([]);
  const [currentCellValue, setCurrentCellValue] = useState(null);
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext();

  useEffect(() => {
    fetchPersonalItemOptions();
  }, []);

  // need to create the route
  const fetchPersonalItemOptions = () => {
    fetch(REACT_APP_API_URL + 'getpersonalitemoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPersonalItemOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const createPropertyOtherPI = newRow => {
    let formData = new FormData();
    console.log('this is new row', newRow);
    formData.append('newRow', JSON.stringify(newRow));
    console.log('new row json: ', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);

    fetch(REACT_APP_API_URL + 'createpropertyotherpi', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const updatePropertyOtherPI = updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));

    fetch(REACT_APP_API_URL + 'updatepropertyotherpi', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deletePropertyOtherPI = id => {
    fetch(REACT_APP_API_URL + `deletepropertyotherpi/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const visabilityModel = {
    otherPropInventory: false,
  };

  const propertyOtherPIColumns = [
    {
      flex: 1,
      minWidth: 150,
      field: 'personalItem',
      headerName: 'Personal Item',
      editable: true,
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: personalItemOptions,
      renderEditCell: params => {
        // Directly access the string value from the current row
        // console.log('what is the row here: ', params?.row);
        const textFieldText = params.row?.otherPropInventory;
        return renderAutocompleteEditCellWithTextField({
          ...params,
          textFieldText,
          rowField: 'otherPropInventory',
        });
      },
      renderCell: params => {
        const textFieldText = params.row.otherPropInventory;
        return renderAutocompleteCellDisplay({
          ...params,
          textFieldText,
        });
      },
      toggleTextfieldoption: 'Other',
    },
    // do not comment this out (we are still using this column)
    {
      flex: 1,
      minWidth: 150,
      field: 'otherPropInventory',
      headerName: 'OTH NM',
      headerAlign: 'left',
      type: 'string',
      editable: true,
      hide: true,
    },
    {
      flex: 0.4,
      minWidth: 100,
      field: 'number',
      headerName: 'No.',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      editable: true,
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'description',
      headerName: 'Description',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      editable: true,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'funeralHome',
      headerName: 'FH',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'releasedtoLE',
      headerName: 'LE',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'retained',
      headerName: 'RET',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'note',
      headerName: 'Notes',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      editable: true,
    },
  ];

  return (
    <CrudDataGrid
      // This is getting the necessary values from the case object as opposed
      // to fetching them when the data grid loads.  This conforms to the
      // current use of our case object.
      rows={formik.values?.caseMortuary?.propertyInventory?.otherPI ?? []}
      setRows={data => {
        formik.setFieldValue(`caseMortuary.propertyInventory.otherPI`, data);
      }}
      idcolumn='otherPISeq'
      initialColumns={propertyOtherPIColumns}
      createEntry={createPropertyOtherPI}
      deleteEntry={deletePropertyOtherPI}
      updateEntry={updatePropertyOtherPI}
      columnVisibilityModel={visabilityModel}
      title='Other PI'
    />
  );
}
