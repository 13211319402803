import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Dialog, DialogActions, DialogContent, DialogTitle, Popover } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
  DataGridPremium,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  useGridApiRef,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/en-US';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={props2 => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

function CustomToolbar(props) {
  const {
    setRowModesModel,
    rowModesModel,
    setRows,
    rows,
    title,
    importOption,
    legend,
    idcolumn,
    disableAddNewRow = false,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const openLegend = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOpenLegend = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleNewEntryClick = idcolumn => {
    const id = uuidv4();
    // console.log(id);
    // setRowModesModel(oldModel => ({
    //   ...oldModel,
    //   [id]: { mode: GridRowModes.Edit, fieldToFocus: 'test' },
    // }));

    const oldRows = JSON.parse(JSON.stringify(rows));
    const newRows = [
      {
        [idcolumn]: id,
        isNew: true,
      },
      ...oldRows,
    ];

    setRows(newRows);

    const oldRowModesModel = JSON.parse(JSON.stringify(rowModesModel));
    const newRowModesModel = {
      ...oldRowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'test' },
    };

    setRowModesModel(newRowModesModel);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <GridToolbarContainer>
      <Button
        disabled={disableAddNewRow}
        color='primary'
        startIcon={<AddIcon />}
        onClick={() => handleNewEntryClick(idcolumn)}
      >
        Add {title}
      </Button>

      {importOption && (
        <Button
          color='secondary'
          startIcon={<UploadFileIcon />}
          // onClick={handleOpenUploadDialog}
        >
          Import Results
        </Button>
      )}

      {legend && (
        <React.Fragment>
          <Button
            sx={{ marginLeft: 'auto' }}
            color='secondary'
            startIcon={<InfoIcon />}
            onClick={handleOpenLegend}
          >
            COD Tag Legend
          </Button>

          <Popover
            id={id}
            open={openLegend}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {legend}
          </Popover>
        </React.Fragment>
      )}
    </GridToolbarContainer>
  );
}

// This datagrid is an experimental datagrid that further extends the disable feature in the original; will be incorporated after testing
// Adds `disableActionButtons`, `disableEdit`, `disableEdit`
export default function DisableCrudDataGrid({
  rows,
  setRows,
  initialColumns,
  createEntry,
  deleteEntry,
  updateEntry,
  title,
  rowsperpage = 5,
  disableActionButtons = false,
  disableAddNewRow = false,
  disableEdit = false,
  disableDelete = false,
  importOption = false,
  legend = false,
  idcolumn = 'id',
}) {
  const [rowModesModel, setRowModesModel] = useState({});
  const [columns, setColumns] = useState(initialColumns);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: rowsperpage,
    page: 0,
  });
  const apiRef = useGridApiRef();

  const getActions = (id, model) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
      return [
        <GridActionsCellItem
          icon={<SaveIcon />}
          label='Save'
          sx={{
            color: 'primary.main',
          }}
          onClick={handleSaveClick(id)}
        />,
        <GridActionsCellItem
          icon={<CancelIcon />}
          label='Cancel'
          className='textPrimary'
          onClick={handleCancelClick(id)}
          color='inherit'
        />,
      ];
    }

    return [
      <GridActionsCellItem
        icon={<EditIcon />}
        label='Edit'
        className='textPrimary'
        onClick={handleEditClick(id)}
        color='inherit'
        disabled={disableEdit}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label='Delete'
        onClick={handleDeleteClick(id)}
        color='inherit'
        disabled={disableDelete}
      />,
    ];
  };

  useEffect(() => {
    const updatedColumns = columns.map((item, index) => {
      if (initialColumns[index]) {
        return { ...item, ...initialColumns[index] };
      }
      return item;
    });

    if (updatedColumns.some(column => column.field === 'actions')) {
      setColumns(updatedColumns);
    } else {
      setColumns([
        ...updatedColumns,
        {
          flex: 0.5,
          minWidth: 75,
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          headerAlign: 'center',
          align: 'center',
          cellClassName: 'actions',
          getActions: ({ id }) => getActions(id, rowModesModel),
        },
      ]);
    }
  }, [initialColumns]);

  const updateColumns = async () => {
    const tempModel = await JSON.parse(JSON.stringify(rowModesModel));

    setColumns(previousColumns =>
      previousColumns.map(column =>
        column.field === 'actions'
          ? {
              flex: 0.5,
              minWidth: 75,
              field: 'actions',
              type: 'actions',
              headerName: 'Actions',
              headerAlign: 'center',
              align: 'center',
              cellClassName: 'actions',
              getActions: ({ id }) => getActions(id, tempModel),
            }
          : column
      )
    );
  };

  useEffect(() => {
    updateColumns();
  }, [rowModesModel]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = id => () => {
    setConfirmDialogVisible(true);
    setIdToDelete(id);
  };

  const ConfirmDialog = () => {
    const handleYes = () => {
      setConfirmDialogVisible(false);
      setRows(rows.filter(row => row[idcolumn] !== idToDelete));

      deleteEntry(idToDelete);
    };

    const handleNo = () => {
      setConfirmDialogVisible(false);
    };

    return (
      <Dialog maxWidth='xs' open={confirmDialogVisible}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          Pressing 'Yes' will delete this entry.
          <br />
          This action cannot be reversed.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo}>No</Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    // console.log('yee haw');
    // console.log(data);
  }, [rows]);

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find(row => row[idcolumn] === id);
    if (editedRow.isNew) {
      setRows(rows.filter(row => row[idcolumn] !== id));
    }
  };

  const processRowUpdate = newRow => {
    var updatedRow;

    if (newRow.isNew) {
      updatedRow = { ...newRow, isNew: false };

      setRows(rows.map(row => (row[idcolumn] === newRow[idcolumn] ? updatedRow : row)));
      //   console.log('UPDATED ROW', updatedRow);
      createEntry(updatedRow);
    } else {
      updatedRow = { ...newRow, isNew: false };

      setRows(rows.map(row => (row[idcolumn] === newRow[idcolumn] ? updatedRow : row)));
      //   console.log('UPDATED ROW', updatedRow);
      updateEntry(updatedRow);
    }

    return updatedRow;
  };

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <Box
      sx={{
        flexgrow: 1,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <ConfirmDialog />

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <DataGridPremium
          rows={rows}
          columns={columns}
          apiRef={apiRef}
          editMode='row'
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: true,
            outliersFactor: 1.5,
            expand: true,
          }}
          autoHeight
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          getRowId={row => row[idcolumn]}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            pagination: CustomPagination,
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              disableAddNewRow,
              setRowModesModel,
              rowModesModel,
              setRows,
              rows,
              title,
              importOption,
              legend,
              idcolumn,
              //   disabled,
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
}
