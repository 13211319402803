import { Checkbox } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { renderAutocompleteCellDisplay } from '../../../../../utils/components/CrudDataGrid/AutocompleteCellWithTextField';
import { renderAutocompleteEditCellWithTextField } from '../../../../../utils/components/CrudDataGrid/AutocompleteEditCellWithTextField';
import CrudDataGrid from '../../../../../utils/components/CrudDataGrid/CrudDataGrid';

export function EvidenceDataGrid() {
  const { user } = useAuth();
  const [evidenceOptions, setEvidenceOptions] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext();

  useEffect(() => {
    fetch(REACT_APP_API_URL + 'getevidencetype', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setEvidenceOptions(data);
      })
      .catch(e => {
        alert('Failed to get from evidence Options\nError: ', e);
      });

    // console.log('formik:', formik.values);
  }, []);

  const visabilityModel = {
    otherEvidence: false,
  };

  const propertyEvidenceColumns = [
    {
      flex: 1,
      minWidth: 150,
      field: 'evidenceItem',
      headerName: 'Evidence Item',
      editable: true,
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: evidenceOptions,
      renderEditCell: params => {
        const textFieldText = params.row.otherEvidence;
        return renderAutocompleteEditCellWithTextField({
          ...params,
          textFieldText,
          rowField: 'otherEvidence',
        });
      },
      renderCell: params => {
        const textFieldText = params.row.otherEvidence;
        return renderAutocompleteCellDisplay({
          ...params,
          textFieldText,
        });
      },
      toggleTextfieldoption: 'Other',
    },
    // do not comment this out (we are still using this column)
    {
      flex: 1,
      minWidth: 150,
      field: 'otherEvidence',
      headerName: 'OTH NM',
      headerAlign: 'left',
      type: 'string',
      editable: true,
    },
    {
      flex: 0.5,
      minWidth: 120,
      field: 'number',
      headerName: 'Number',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      editable: true,
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'description',
      headerName: 'Description',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      editable: true,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'funeralHome',
      headerName: 'FH',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'releasedtoLE',
      headerName: 'LE',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'retained',
      headerName: 'RET',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'note',
      headerName: 'Notes',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      editable: true,
    },
  ];

  const createEvidenceItem = newRow => {
    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);

    fetch(REACT_APP_API_URL + 'createpropertyevidence', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteEvidenceItem = id => {
    fetch(REACT_APP_API_URL + `deletepropertyevidence/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error ocured');
        }
        return res.text();
      })
      .then(data => {
        console.log('Sucessfully Deleted!');
      })
      .catch(e => {
        console.log(e);
      });
  };

  const updateEvidenceItem = updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));

    fetch(REACT_APP_API_URL + 'updatepropertyevidence', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        console.log('updated data', data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <CrudDataGrid
      rows={formik.values?.caseMortuary?.propertyInventory?.propertyEvidence ?? []}
      setRows={data => {
        formik.setFieldValue(`caseMortuary.propertyInventory.propertyEvidence`, data);
      }}
      idcolumn='evidenceSeq' // has to be the same as the model's seq
      initialColumns={propertyEvidenceColumns}
      createEntry={createEvidenceItem}
      deleteEntry={deleteEvidenceItem}
      updateEntry={updateEvidenceItem}
      columnVisibilityModel={visabilityModel}
      title='Evidence'
    />
  );
}
