import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Paper, Stack, Box } from '@mui/material';
import { useState } from 'react';
import dimensions from '../../utils/dimensions';
import AppTitle from './AppTitle';
import { CommunicationsButtons } from './CommunicationsButtons';
import NavButton from './NavButton';
import Search from './Search';
import { UserMenuDrawer } from './UserMenuDrawer';

export default function DesktopAppBar() {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);

  const handleOpenUserMenu = () => {
    setUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setUserMenuOpen(false);
  };

  const handleOpenNotificationMenu = () => {
    setNotificationMenuOpen(true);
  };

  const handleCloseNotificationMenu = () => {
    setNotificationMenuOpen(false);
  };

  const NavButtons = () => {
    return (
      <Stack
        direction='row'
        spacing={0.5}
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <NavButton
          navigationPageTitle='dashboard'
          title='Dashboard'
          authorizedRoles={['DASHBOARD-ACCESS']}
          startIcon={<DashboardIcon />}
          mono
        />

        <NavButton
          navigationPageTitle='reports'
          title='Reports'
          authorizedRoles={['REPORTS-MENU-ACCESS']}
          startIcon={<DescriptionIcon />}
          mono
        />

        <NavButton
          navigationPageTitle='casesearch'
          title='Search'
          startIcon={<SearchIcon />}
          mono
        />

        <NavButton
          navigationPageTitle='yourcasesandreports'
          title='Your Cases'
          startIcon={<AssignmentIcon />}
          mono
        />

        <NavButton
          navigationPageTitle='caseintake'
          authorizedRoles={['CREATE-CASE-ACCESS']}
          title='New Case'
          startIcon={<AddIcon />}
        />
      </Stack>
    );
  };

  return (
    <Paper
      sx={{
        position: 'sticky',
        top: 0,
        px: 2,
        alignItems: 'center',
        display: { xs: 'none', md: 'flex' },
        zIndex: 1005,
        height: dimensions.app_bar_height,
        // borderBottom: '1px solid rgba(112, 112, 112, 0.7)',
      }}
      square
      elevation={4}
    >
      <AppTitle />

      <Divider
        orientation='vertical'
        variant='middle'
        flexItem
        sx={{
          borderColor: 'inherit',
          mx: 1.5,
          my: 2.5,
          display: { xs: 'none', md: 'block' },
        }}
      />

      <NavButtons />

      <Stack direction='row' spacing={1} marginLeft='auto' alignItems='center' sx={{ pl: 2 }}>
        <Search startAdornment={<SearchIcon />} includePrevNextArrows />

        <CommunicationsButtons
          handleOpenNotificationMenu={handleOpenNotificationMenu}
          handleCloseNotificationMenu={handleCloseNotificationMenu}
          notificationMenuOpen={notificationMenuOpen}
          userMenuOpen={userMenuOpen}
        />

        <UserMenuDrawer
          handleCloseUserMenu={handleCloseUserMenu}
          handleOpenUserMenu={handleOpenUserMenu}
          open={userMenuOpen}
        />
      </Stack>
    </Paper>
  );
}
