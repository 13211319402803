import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import { renderAutocompleteEditCell } from '../../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import { dateTimeColumnType } from '../../../../utils/components/CrudDataGrid/DateTimeColumn';
import DisableCrudDataGrid from '../../../../utils/components/CrudDataGrid/DisableCrudDataGrid';
import dayjs from 'dayjs';
import { format } from "date-fns";


export default function CaseActivityLogDataGrid() {
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext();

  const [transportationLog, setTransportationLog] = useState(
    formik.values?.caseInvestigations?.transportationLog ?? []
  );

  const [liveryOptions, setLiveryOptions] = useState(null);
  //   const [labOptions, setLabOptions] = useState(null);

  useEffect(() => {
    // INITIALIZE TEST OPTIONS
    fetch(REACT_APP_API_URL + 'getliverystatusoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setLiveryOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
    fetchTransportationLog();
  }, []);

  const fetchTransportationLog = async () => {
    try {
      const caseSeq = formik.values?.caseSummary?.caseSeq;
      const response = await fetch(`${REACT_APP_API_URL}gettransportationlogoncase/${caseSeq}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      const updatedData = data.map(item => {
        return {
          ...item,
          isNew: false, // Set isNew to false initially for each item
        };
      });
      //   console.log('mytrans:', updatedData);
      setTransportationLog(updatedData); // Sets the data with isNew and consultant
      return data; // array of requested procedures
    } catch (error) {
      console.error('There was a problem with the fetch operation: ', error);
    }
  };

  const createTransportationLog = newRow => {
    // console.log('GOT HERE CREATE NEW LOG');
    // console.log('transport new row', newRow);

    // Default geolocation values
    const defaultLatitude = 38.8951;
    const defaultLongitude = -77.0364;

    // Function to handle the fetch request with geolocation
    const sendRequestWithGeolocation = (latitude, longitude) => {
      fetch(REACT_APP_API_URL + 'liverystatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        },
        body: JSON.stringify({
          caseSeq: formik.values?.caseSummary?.caseSeq,
          statusSeq: newRow?.description?.optionSeq,
          userSeq: user.userSeq,
          geocode: { latitude, longitude },
        }),
      })
        .then(res => {
          if (res.status === 401) {
            throw new Error('You are unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occurred');
          }
          return res.text();
        })
        .then(data => {})
        .catch(e => {
          console.error(e);
        });
    };

    // Function to get current location or use default
    const getLocation = () => {
      if (!navigator.geolocation) {
        console.log('Geolocation is not supported, using default values');
        sendRequestWithGeolocation(defaultLatitude, defaultLongitude);
        return;
      }

      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          //   alert('Here is lat and long: Latitude: ' + latitude + ', Longitude: ' + longitude);
          sendRequestWithGeolocation(latitude, longitude);
        },
        error => {
          if (error.code === error.PERMISSION_DENIED) {
            alert(
              'Location access is denied. This application requires your location to function properly. Please allow location access. Any changes made were NOT saved.'
            );
          } else {
            console.error('Error getting location', error);
          }
          //   sendRequestWithGeolocation(defaultLatitude, defaultLongitude);
        }
      );
    };

    getLocation();
  };

  // Because it is a log, only approved personel (likely admin) have the option of updating/editing & deleting logs

  //   const updateLabTestTracking = updatedRow => {
  //     let formData = new FormData();
  //     formData.append('updatedRow', JSON.stringify(updatedRow));
  //     formData.append('userSeq', user.userSeq);

  //     fetch(REACT_APP_API_URL + 'updatelabtesttracking', {
  //       method: 'PUT',
  //       headers: {
  //         Authorization: 'Bearer ' + user.token,
  //       },
  //       body: formData,
  //     })
  //       .then(res => {
  //         if (res.status == 401) {
  //           throw new Error('You are unauthorized to use this tool');
  //         } else if (res.status >= 400) {
  //           throw new Error('An error occured');
  //         }
  //         return res.text();
  //       })
  //       .then(data => {
  //         // let values = labTestTrackingRows.map(row => (row.id === updatedRow.id ? updatedRow : row));
  //         // setcaseinfo({
  //         //   ...caseinfo,
  //         //   caseLabTesting: {
  //         //     ...caseinfo.caseLabTesting,
  //         //     labTestTracking: values,
  //         //   },
  //         // });
  //       })
  //       .catch(e => {
  //         console.log(e);
  //       });
  //   };

  //   const deleteLabTestTracking = id => {
  //     fetch(REACT_APP_API_URL + `deletelabtesttracking/${id}`, {
  //       method: 'DELETE',
  //       headers: {
  //         Authorization: 'Bearer ' + user.token,
  //       },
  //     })
  //       .then(res => {
  //         if (res.status == 401) {
  //           throw new Error('You are unauthorized to use this tool');
  //         } else if (res.status >= 400) {
  //           throw new Error('An error occured');
  //         }
  //         return res.text();
  //       })
  //       .then(data => {
  //         // let values = labTestTrackingRows.filter(row => row.id !== id);
  //         // setcaseinfo({
  //         //   ...caseinfo,
  //         //   caseLabTesting: {
  //         //     ...caseinfo.caseLabTesting,
  //         //     labTestTracking: values,
  //         //   },
  //         // });
  //       })
  //       .catch(e => {
  //         console.log(e);
  //       });
  //   };

  const transportationLogColumns = [
    {
      flex: 1,
      minWidth: 150,
      field: 'description',
      headerName: 'Description',
      editable: true,
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: liveryOptions,
      renderEditCell: params => renderAutocompleteEditCell(params, 'Description'),
    },
    {
      flex: 1,
      field: 'timeStamp',
      headerName: 'Time Stamp',
      headerAlign: 'left',
      align: 'left',
      ...dateTimeColumnType,
      editable: false,
      // valueSetter: params => {
      //   if (params?.value == null || params?.value == 'Invalid Date') {
      //     return { ...params.row, timeStamp: null };
      //   } else {
      //     const localDate = params?.value
      //       ? dayjs(params?.value).format('YYYY-MM-DDTHH:mm:ss')
      //       : null;

      //     return { ...params.row, timeStamp: localDate };
      //   }
      // },
      valueFormatter: params => {
        return params.value ? format(new Date(params.value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'loggedByUser',
      headerName: 'Logged by User',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        // console.log('val: ', value);
        if (!value) return user.userName;
        return value.optionName;
      },
      // type: 'singleSelect',
      editable: false,
      //   valueOptions: labOptions,
      renderEditCell: 'string',
    },
  ];

  //   useEffect(() => {
  //     console.log('my formik', formik.values?.caseInvestigations);
  //   }, [formik.values?.caseInvestigations?.transportationLog]);

  return (
    <DisableCrudDataGrid
      rows={transportationLog}
      setRows={data => {
        setTransportationLog(data);
        // console.log('what you are setting it to', data);
        // formik.setFieldValue(`caseInvestigations.transportationLog`, data);
      }}
      idcolumn='caseActivityLogSeq' // assuming the same as the CaseActivityLog Table
      initialColumns={transportationLogColumns}
      createEntry={createTransportationLog}
      deleteEntry={() => {}}
      updateEntry={() => {}}
      title='Log Entry'
      disableEdit={true}
      disableDelete={true}
    />
  );
}
