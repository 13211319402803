import { Checkbox } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { renderAutocompleteCellDisplay } from '../../../../../utils/components/CrudDataGrid/AutocompleteCellWithTextField';
import { renderAutocompleteEditCellWithTextField } from '../../../../../utils/components/CrudDataGrid/AutocompleteEditCellWithTextField';
import CrudDataGrid from '../../../../../utils/components/CrudDataGrid/CrudDataGrid';

export function DocumentsDataGrid() {
  const { user } = useAuth();
  const [documentOptions, setdocumentOptions] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext();

  useEffect(() => {
    fetch(REACT_APP_API_URL + 'getdocumenttypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setdocumentOptions(data);
      })
      .catch(e => {
        alert('Failed to get from requested Procedure Options\nError: ', e);
      });

    // console.log('formik:', formik.values);
  }, []);

  const visabilitymodel = {
    otherDocument: false,
  };

  const propertyDocumentColumns = [
    {
      flex: 1,
      minWidth: 150,
      field: 'documentItem',
      headerName: 'Document Item',
      editable: true,
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: documentOptions,
      renderEditCell: params => {
        const textFieldText = params.row.otherDocument;
        return renderAutocompleteEditCellWithTextField({
          ...params,
          textFieldText,
          rowField: 'otherDocument',
        });
      },
      renderCell: params => {
        const textFieldText = params.row.otherDocument;
        return renderAutocompleteCellDisplay({
          ...params,
          textFieldText,
        });
      },
      toggleTextfieldoption: 'Other',
    },
    // do not comment this out (we are still using this column)
    {
      flex: 1,
      minWidth: 150,
      field: 'otherDocument',
      headerName: 'Other Document Name',
      headerAlign: 'left',
      type: 'string',
      editable: true,
    },
    {
      flex: 0.5,
      minWidth: 120,
      field: 'number',
      headerName: 'Number',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      editable: true,
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'description',
      headerName: 'Description',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      editable: true,
    },
    {
      flex: 0.4,
      minWidth: 100,
      field: 'funeralHome',
      headerName: 'FH',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.4,
      minWidth: 100,
      field: 'releasedtoLE',
      headerName: 'LE',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.4,
      minWidth: 100,
      field: 'retained',
      headerName: 'RET',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'note',
      headerName: 'Notes',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      editable: true,
    },
  ];

  const createDocumentItem = newRow => {
    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    console.log('newRow: ', newRow);
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }
    fetch(REACT_APP_API_URL + 'createpropertydocuments', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteDocumentItem = id => {
    fetch(REACT_APP_API_URL + `deletepropertydocument/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        console.log('Sucessfully Deleted!');
      })
      .catch(e => {
        console.log(e);
      });
  };

  const updateDocumentItem = updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));

    fetch(REACT_APP_API_URL + 'updatepropertydocuments', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        console.log('updated data', data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <CrudDataGrid
      rows={formik.values?.caseMortuary?.propertyInventory?.propertyDocument ?? []}
      setRows={data => {
        formik.setFieldValue(`caseMortuary.propertyInventory.propertyDocument`, data);
      }}
      idcolumn='documentSeq' // has to be the same as the model's seq
      initialColumns={propertyDocumentColumns}
      createEntry={createDocumentItem}
      deleteEntry={deleteDocumentItem}
      updateEntry={updateDocumentItem}
      columnVisibilityModel={visabilitymodel}
      title='Documents'
    />
  );
}
