import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import {
  DataGridPremium,
  GridActionsCellItem,
  GridToolbarContainer,
} from '@mui/x-data-grid-premium';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ChargeableRequestsForm from '../../../../components/CaseRecords/ChargeableRequests/ChargeableRequestsForm';
import { useAuth } from '../../../../utils/auth/AuthService';



export default function ChargeableRequestsView() {
  const [currentChargeableRequestIndex, setCurrentChargeableRequestIndex] = useState(-1);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({ delete: false });
  const [idToDelete, setIdToDelete] = useState(null);
  const formik = useFormikContext();
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;

  function CustomToolbar() {
    const formik = useFormikContext();
  
    const handleNewEntryClick = () => {
      const chargeableRequestSeq = uuidv4();
      const personSeq = uuidv4();
      const contactInfoSeq = uuidv4();
  
      formik.setFieldValue('caseRecords.chargeableRequests', [     
        {
          chargeableRequestSeq: chargeableRequestSeq,
          requester: [{ personSeq: personSeq }],
          contactInfoSeq: contactInfoSeq,
          isNew: true,
        },
        ...(formik.values?.caseRecords?.chargeableRequests ?? []),
      ]);
  
      setRowSelectionModel([chargeableRequestSeq]);
    };
  
    return (
      <GridToolbarContainer>
        <Button color='primary' startIcon={<AddIcon />} onClick={() => handleNewEntryClick()}>
          New File Request
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleDeleteClick = id => () => {
    setConfirmDialogVisible(true);
    setIdToDelete(id);
  };


  const ConfirmDialog = () => {
    const handleYes = () => {
      setConfirmDialogVisible(false);
      formik.setFieldValue(
        'caseRecords.chargeableRequests',
        formik.values?.caseRecords?.chargeableRequests.filter(
          row => row.chargeableRequestSeq !== idToDelete
        ) || []
      );

      fetch(REACT_APP_API_URL + `deletechargeablerequest/${idToDelete}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You are unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.text();
        })
        .then(data => {
          console.log('successfully deleted entry');
        })
        .catch(e => {
          console.log(e);
        });
    };

    const handleNo = () => {
      setConfirmDialogVisible(false);
    };

    return (
      <Dialog maxWidth='xs' open={confirmDialogVisible}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          Pressing 'Yes' will delete this entry.
          <br />
          This action cannot be reversed.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo}>No</Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const chargeableRequestsColumns = [
    {
      flex: 1,
      minWidth: 150,
      field: 'isFamilyRequest',
      headerName: 'Request Type',
      type: 'boolean',
      headerAlign: 'left',
      align: 'left',
      renderCell: cell => (cell.value ? 'Individual' : 'Facility/Agency/Institution'),
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'requester',
      headerName: 'Requester Name',
      // type: 'string',
      valueFormatter: ({ value }) => {
        const firstName = value?.[0]?.personFirstName || '';
        const lastName = value?.[0]?.personLastName || '';

        if (!firstName && !lastName) return '';
        if (!firstName) return lastName;
        if (!lastName) return firstName;

        return `${lastName}, ${firstName}`;
      },
    },
    {
      flex: 2,
      minWidth: 200,
      field: 'requestedDocuments.itemRequested',
      headerName: 'Requested Item(s)',
      // type: 'singleSelect',
      valueGetter: params => {
        var documentsList = '';
        for (var requestedDocument of params?.row?.requestedDocuments ?? []) {
          if (documentsList !== '') documentsList += ', ';
          documentsList += requestedDocument?.itemRequested?.optionName || '';
        }
        return documentsList;
      },
    },
    {
      flex: 0.5,
      minWidth: 100,
      field: 'requestedDocuments.costPerItem',
      headerName: 'Billed ($)',
      // type: 'singleSelect',
      valueGetter: params => {
        var total = 0;
        for (var requestedDocument of params?.row?.requestedDocuments ?? []) {
          const costPerItem = requestedDocument?.costPerItem || 0;
          const numberOfItemsRequested = requestedDocument?.numberOfItemsRequested || 0;
          total += costPerItem * numberOfItemsRequested;
        }
        return total;
      },
      valueFormatter: ({ value }) => {
        if (value == null) {
          return '';
        }
        return `$${value}`;
      },
    },
    {
      flex: 1,
      field: 'dateSent.dateSent',
      headerName: 'Date Sent',
      type: 'date',
      valueGetter: params => {
        if (params?.row?.requestedDocuments?.length ?? 0 > 0) {
          if (params?.row?.requestedDocuments?.[0].dateSent != null) {
            return new Date(params?.row?.requestedDocuments?.[0].dateSent);
          } else return null;
        }
      },
    },
    {
      flex: 0.5,
      field: 'delete',
      // headerName: 'Delete',
      type: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            onClick={handleDeleteClick(id)}
            color='inherit'
          />,
        ];
      },
    },
  ];

  const handleRowSelect = newRowSelectionModel => {
    setRowSelectionModel(newRowSelectionModel);
  };

  useEffect(() => {
    setCurrentChargeableRequestIndex(
      formik.values?.caseRecords?.chargeableRequests?.findIndex(
        row => row.chargeableRequestSeq === rowSelectionModel[0]
      )
    );

    if (rowSelectionModel && rowSelectionModel?.length > 0 && !formik.status?.editing) {
      formik.setStatus({ editing: true });
    }
  }, [rowSelectionModel]);


  useEffect(() => {
    setColumnVisibilityModel(prevModel => ({ ...prevModel, delete: formik.status?.editing }));
  }, [formik.status?.editing]);

  return (
    <Stack spacing={2}>
      <ConfirmDialog />

      <DataGridPremium
        autoHeight
        rowHeight={30} // Set the row height here
        headerHeight={28} 
        disableMultipleRowSelection
        rows={formik.values?.caseRecords?.chargeableRequests ?? []}
        columns={chargeableRequestsColumns}
        pageSize={5}
        getRowId={row => row?.chargeableRequestSeq}
        onRowSelectionModelChange={handleRowSelect}
        rowSelectionModel={rowSelectionModel}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
        slots={{
          // toolbar: formik.status?.editing ? CustomToolbar : null,
          toolbar: CustomToolbar, // Always display the toolbar
        }}
      />

      {/* <Collapse in={currentChargeableRequestIndex !== undefined && currentChargeableRequestIndex !== null && currentChargeableRequestIndex >= 0}> */}
      {currentChargeableRequestIndex !== undefined && currentChargeableRequestIndex !== null && currentChargeableRequestIndex >= 0 && (
        <ChargeableRequestsForm currentChargeableRequestIndex={currentChargeableRequestIndex} />
      )}
      {/* </Collapse> */}
    </Stack>
  );
}
