import { Box, Link, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router';

export function ErrorFallback_View({ error }: { error: any }) {
  const location = useLocation();

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        minHeight: '100%',
        textAlign: 'center',
      }}
    >
      <Typography variant='h4' gutterBottom sx={{ fontWeight: 'bold' }}>
        Uh oh :(
      </Typography>
      <Typography variant='body1' gutterBottom>
        Something went wrong:
      </Typography>
      <pre style={{ color: 'red' }}>
        {error instanceof Error && (
          <Stack>
            <strong>{error.name} Details:</strong>
            <p>Message: {error.message}</p>
            <p>Stack: {error.stack}</p>
          </Stack>
        )}

        <Stack>
          <strong>Location Details:</strong>
          <p>Pathname: {location.pathname}</p>
          <p>State: {JSON.stringify(location.state)}</p>
        </Stack>

        <Stack>
          <strong>Browser Details:</strong>
          <p>User Agent: {window.navigator.userAgent}</p>
          <strong>Device Details:</strong>
          <p>
            Width: {window.screen.width}, Height: {window.screen.height}
          </p>
        </Stack>
      </pre>

      <Typography variant='body1' gutterBottom>
        Please report this to{' '}
        <Link href='mailto:medex.labs@doh.nj.gov' underline='hover'>
          medex.labs@doh.nj.gov.
        </Link>
      </Typography>
    </Box>
  );
}
