import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../utils/auth/AuthService';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { PrintFileButton } from '../../utils/functions/triggerPrintDialog';
import { DecedentNameCell } from './DecedentNameCell';
import { DownloadFileCell } from './DownloadFileCell';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';

export function OpenCasesDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const navigate = useNavigate();
  const [caseResults, setCaseResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;

  const handleDownload = async (fileseq: string, caseid: string) => {
    if (!fileseq || !caseid) {
      return;
    }

    const fileSeqs: string[] = [];
    fileSeqs.push(fileseq);

    let formData = new FormData();
    formData.append('caseId', caseid);
    formData.append('fileSeqsString', JSON.stringify(fileSeqs));

    var response: Response | null = null;

    setDownloading(true);

    try {
      response = await fetch(REACT_APP_API_URL + 'downloadfiles', {
        method: 'POST',
        headers: {
          responseType: 'blob', // Corrected from 'resposeType' to 'responseType'
          Authorization: 'Bearer ' + (user?.token || ''),
        },
        body: formData,
      });

      if (response.status === 401) {
        throw new Error('You are unauthorized to use this tool');
      } else if (response.status >= 400) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }

      const data: Blob = await response.blob();

      // Single file selected, download the file directly
      const disposition = response.headers.get('content-disposition');
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition || '');
      const filename = matches !== null && matches[1] ? matches[1].replace(/['"]/g, '') : 'file';

      // @ts-ignore
      saveAs(data, filename);
    } catch (error) {
      console.log(error);
    }

    setDownloading(false);
  };

  const renderFileListCell = (params: any) => {
    if (Array.isArray(params.value) && params.value?.length > 0) {
      return (
        <div>
          {
            // downloading ? (<div>Downloading...</div>
            //  ) : (
            params.value?.map((file: any, index: any) => (
              <div key={index}>
                <PrintFileButton
                  fileSeq={file.fileseq}
                  filename={file.filename}
                  caseid={params.row.caseid}
                />
              </div>
            ))
            // )
          }
        </div>
      );
    } else {
      return null;
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      width: 110,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: params => {
        return (
          <Button
            color='primary'
            onClick={() => {
              // handleCaseClick(event, params);
            }}
          >
            <b> {params.formattedValue} </b>
          </Button>
        );
      },
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 170,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'idstatus',
      headerName: 'ID Status',
      width: 80,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'menotified',
      headerName: 'Reported',
      width: 170,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      type: 'dateTime',
      valueGetter: params => {
        return params.value != null ? new Date(params.value) : null;
      },
      valueFormatter: params => {
        return params.value ? format(new Date(params.value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },

    {
      field: 'meaction',
      headerName: 'MEAction',
      width: 120,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },

    {
      field: 'investigator',
      headerName: 'MLDI',
      width: 150,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'datasheet',
      headerName: 'INV Data Sheet',
      width: 200,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: params => <DownloadFileCell {...params} />,
    },
    // {
    //   field: 'blcity',
    //   headerName: 'Location',
    //   width: 150,
    //   flex: 1,
    //   editable: false,
    //   align: 'left',
    //   headerAlign: 'left',
    //   headerClassName: 'header-theme',
    // },
    {
      field: 'pathologist',
      headerName: 'Pathologist',
      width: 150,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    // {
    //   field: 'examtype',
    //   headerName: 'Exam Type',
    //   width: 100,
    //   editable: false,
    //   align: 'left',
    //   headerAlign: 'left',
    //   headerClassName: 'header-theme',
    // },
    {
      field: 'autopsyReport',
      headerName: 'Pathology Report',
      flex: 1,
      width: 200,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: renderFileListCell,
    },
    {
      field: 'toxReport',
      headerName: 'Tox Report',
      width: 200,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: renderFileListCell,
    },

    {
      field: 'nokcount',
      headerName: 'NOK',
      width: 30,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='caseid'
      gridheight={400}
      gridrowheight={true}
      gridcolor='#BA8709'
      title='Open Cases'
      tooltip='Accepted/Admitted cases reported where exam is not complete, Release Cases that have not been confirmed by an Approving Pathologist,  Pathology Report (for accepted), COD and MOD must also be be entered before the case is considered closed. For Cases where ID is required, the ID must be confirmed by before the case is considered closed.'
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
    />
  );
}
