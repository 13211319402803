import { Checkbox } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { renderAutocompleteCellDisplay } from '../../../../../utils/components/CrudDataGrid/AutocompleteCellWithTextField';
import { renderAutocompleteEditCell } from '../../../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import { renderAutocompleteEditCellWithTextField } from '../../../../../utils/components/CrudDataGrid/AutocompleteEditCellWithTextField';
import CrudDataGrid from '../../../../../utils/components/CrudDataGrid/CrudDataGrid';

export function ClothingDataGrid() {
  const [clothingOptions, setClothingOptions] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext();

  useEffect(() => {
    fetchClothingOptions();
    fetchColorOptions();
  }, []);

  const fetchClothingOptions = () => {
    fetch(REACT_APP_API_URL + 'getclothingtypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setClothingOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchColorOptions = () => {
    fetch(REACT_APP_API_URL + 'getcoloroptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setColorOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const createPropertyClothing = newRow => {
    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);

    fetch(REACT_APP_API_URL + 'createpropertyclothing', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const updatePropertyClothing = updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));

    fetch(REACT_APP_API_URL + 'updatepropertyclothing', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deletePropertyClothing = id => {
    fetch(REACT_APP_API_URL + `deletepropertyclothing/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const visabilityModel = {
    otherClothing: false,
  };

  const propertyClothingColumns = [
    {
      flex: 1,
      minWidth: 150,
      field: 'clothingItem',
      headerName: 'Clothing Item',
      editable: true,
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: clothingOptions,
      // renderEditCell: params => renderAutocompleteEditCell(params, 'Clothing Item'),
      renderEditCell: params => {
        const textFieldText = params.row?.otherClothing;
        return renderAutocompleteEditCellWithTextField({
          ...params,
          textFieldText,
          rowField: 'otherClothing',
        });
      },
      renderCell: params => {
        const textFieldText = params.row?.otherClothing;
        return renderAutocompleteCellDisplay({
          ...params,
          textFieldText,
        });
      },
      toggleTextfieldoption: 'Other',
    },
    // do not comment this out (we are still using this column)
    {
      flex: 1,
      minWidth: 150,
      field: 'otherClothing',
      headerName: 'OTH NM',
      headerAlign: 'left',
      type: 'string',
      editable: true,
    },
    {
      flex: 0.4,
      minWidth: 120,
      field: 'number',
      headerName: 'Number',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      editable: true,
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'color',
      headerName: 'Color',
      editable: true,
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: colorOptions,
      renderEditCell: params => renderAutocompleteEditCell(params, 'Color'),
    },
    // {
    //   flex: 1,
    //   minWidth: 80,
    //   field: 'styleType',
    //   headerName: 'Style/Type',
    //   headerAlign: 'left',
    //   align: 'left',
    //   type: 'string',
    //   editable: true,
    // },
    // {
    //   flex: 1,
    //   minWidth: 80,
    //   field: 'size',
    //   headerName: 'Size',
    //   headerAlign: 'left',
    //   align: 'left',
    //   type: 'string',
    //   editable: true,
    // },

    // {
    //   flex: 1,
    //   minWidth: 80,
    //   field: 'markings',
    //   headerName: 'Markings',
    //   headerAlign: 'left',
    //   align: 'left',
    //   type: 'string',
    //   editable: true,
    // },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'funeralHome',
      headerName: 'FH',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'releasedtoLE',
      headerName: 'LE',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'retained',
      headerName: 'RET',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'note',
      headerName: 'Notes',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      editable: true,
    },
  ];

  return (
    <CrudDataGrid
      rows={formik.values?.caseMortuary?.propertyInventory?.propertyClothing ?? []}
      setRows={data => {
        formik.setFieldValue(`caseMortuary.propertyInventory.propertyClothing`, data);
      }}
      idcolumn='propertyClothingSeq'
      initialColumns={propertyClothingColumns}
      createEntry={createPropertyClothing}
      deleteEntry={deletePropertyClothing}
      updateEntry={updatePropertyClothing}
      columnVisibilityModel={visabilityModel}
      title='Clothing Item'
    />
  );
}
