import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Snackbar,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';

// @ts-ignore
import {
  AddCircleOutlineRounded,
  DeleteRounded,
  DownloadRounded,
  EditRounded,
  SaveRounded,
} from '@mui/icons-material';
import CustomHeader from '../../../../utils/components/CustomHeader';

// @ts-ignore
import MLResourceDropzone from '../../../../utils/components/MLResourceDropzone';
import { FileAPI } from '../../../../utils/functions/FileAPI';
import { triggerPrintDialog } from '../../../../utils/functions/triggerPrintDialog';
import { ResourceCard } from '../ResourceFiles/ResourceCard';
import { PublicationCardLoadingSkeleton } from './PublicationCardLoadingSkeleton';

interface PublicationDocument {
  documentString: string;
  documentSeq: string;
  documentLabel: string;
  originalFileName: string;
  thumbnail: string;
}

function EditButton({ loading = false, editing = false, saving = false }) {
  return (
    <IconButton disabled={loading || saving}>
      {editing ? <SaveRounded /> : <EditRounded />}
    </IconButton>
  );
}

export default function PublicationsView({ handleMenuClick }: { handleMenuClick: () => void }) {
  const [editing, setEditing] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [documents, setDocuments] = useState<PublicationDocument[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [open, setOpen] = useState(false);
  const auth = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const [deleteDocumentSeq, setDeleteDocumentSeq] = useState<string | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const appTheme = useTheme();

  const handleSuccessfulUpload = () => {
    fetchDocuments();
  };

  const handleOpenDocument = async (documentSeq: string) => {
    const response = await axios.get(
      `${REACT_APP_API_URL}OpenResourceDocument?documentSeq=${documentSeq}`,
      {
        headers: {
          Authorization: 'Bearer ' + auth?.user?.accessToken,
        },
      }
    );

    const fileData = response.data.data[0];

    const base64String = fileData?.documentString;
    const filename = fileData?.originalFileName;
    const contentType = fileData?.documentMimeType;

    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: contentType });
    triggerPrintDialog({ blob });
    // const url = URL.createObjectURL(blob);

    // window.open(url, '_blank');
  };

  const handleDownloadDocument = async (documentSeq: string) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}OpenResourceDocument?documentSeq=${documentSeq}`,
        {
          headers: {
            Authorization: 'Bearer ' + auth?.user?.accessToken,
          },
        }
      );

      const fileData = response?.data?.data?.[0];
      const base64String = fileData?.documentString;
      const filename = fileData?.originalFileName;
      const contentType = fileData?.documentMimeType;
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: contentType });
      if ('download' in document.createElement('a')) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
      } else {
        const fileReader = new FileReader();
        fileReader.onload = function (event) {
          // @ts-ignore
          const fileURL = event.target.result;
          const link = document.createElement('a');
          // @ts-ignore
          link.href = fileURL;
          link.target = '_blank';
          link.download = filename;
          link.click();
        };
        fileReader.readAsDataURL(blob);
      }
    } catch (error) {
      alert('Error : ' + error);
      console.log('error', error);
    }
  };

  const handleDeleteDocument = async () => {
    try {
      await axios.post(
        `${REACT_APP_API_URL}DeleteResourceDocument?documentSeq=${deleteDocumentSeq}`,
        '',
        {
          headers: {
            Authorization: 'Bearer ' + auth?.user?.accessToken,
            'Content-Type': 'application/json',
          },
        }
      );
      setDocuments(documents?.filter(row => row.documentSeq != deleteDocumentSeq));
    } catch (error) {
      console.log('error', error);
    }
    setShowConfirmation(false);
  };

  const fetchDocuments = async () => {
    setLoadingDocuments(true);
    await fetch(REACT_APP_API_URL + 'getresourcedocuments', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + auth?.user?.accessToken,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occurred');
        }
        return res.json();
      })
      .then(data => {
        setDocuments(data);
        setLoadingDocuments(false);
      })
      .catch(e => {
        setLoadingDocuments(false);
        setErrorMessage(e.message);
        setShowErrorSnackbar(true);
      })
      .finally(() => {
        setLoadingDocuments(false);
      });
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <Stack>
      <CustomHeader
        title={'Publications'}
        description='Helpful reading material and resources'
        handleMenuClick={handleMenuClick}
        endAdornment={<></>}
      />

      {auth.user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']) && (
        <Button
          style={{ marginBottom: '10px' }}
          variant='text'
          startIcon={<AddCircleOutlineRounded />}
          onClick={() => setOpen(true)}
          fullWidth
        >
          Upload New Publication
        </Button>
      )}

      {!loadingDocuments && documents.length === 0 && <p>No publications found.</p>}

      <Grid container spacing={0} padding={0} margin={0}>
        {loadingDocuments &&
          Array.from({ length: 4 }, (_, index) => (
            <div key={index} style={{ display: 'flex', flexWrap: 'wrap' }}>
              <PublicationCardLoadingSkeleton />
            </div>
          ))}
        {documents?.map((document, index) => (
          <Grid item style={{ display: 'flex', flexWrap: 'wrap' }} key={index}>
            <ResourceCard
              resource={{
                title: document.documentLabel,
                thumbnailString: document.thumbnail,
                extraContent: document.originalFileName,
                menuItems: [
                  <MenuItem
                    onClick={() =>
                      FileAPI.openDocumentInNewTab(document.documentSeq, auth?.user?.accessToken)
                    }
                  >
                    <ListItemIcon>
                      <DownloadRounded />
                    </ListItemIcon>
                    Download PDF
                  </MenuItem>,
                  auth.user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']) && (
                    <MenuItem
                      onClick={e => {
                        e.stopPropagation();
                        setShowConfirmation(true);
                        setDeleteDocumentSeq(document.documentSeq);
                      }}
                    >
                      <ListItemIcon>
                        <DeleteRounded />
                      </ListItemIcon>
                      Delete
                    </MenuItem>
                  ),
                ],
              }}
              onResourceSelect={() =>
                FileAPI.openDocumentInNewTab(document.documentSeq, auth?.user?.accessToken)
              }
              fallbackThumbnail={
                <MenuBookIcon
                  sx={{ fontSize: 120, cursor: 'pointer' }}
                  onClick={() =>
                    FileAPI.openDocumentInNewTab(document.documentSeq, auth?.user?.accessToken)
                  }
                />
              }
              showMore={true}
            />
          </Grid>
          // <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          //   <Card
          //     style={{
          //       width: '250px',
          //       margin: '10px',
          //       position: 'relative',
          //     }}
          //     onClick={() => handleOpenDocument(document.documentSeq)}
          //   >
          //     <CardContent style={{ padding: 0 }}>
          //       <div
          //         style={{
          //           position: 'relative',
          //           width: '100%',
          //           overflow: 'hidden',
          //         }}
          //       >
          //         {renderDocumentThumbnail(document)}
          //       </div>
          //       <Box component='div' sx={{ p: 1, justifyContent: 'flex-start' }}>
          //         <Box component='div' mb={4}>
          //           <Typography sx={{ fontWeight: 'bold' }}>{document.documentLabel}</Typography>

          //           <Typography style={{ fontSize: '10px' }} variant='body2'>
          //             {document.originalFileName}
          //           </Typography>
          //         </Box>

          //         <Stack
          //           direction='row'
          //           sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
          //         >
          //           <Tooltip title='Download Publication' arrow>
          //             <Button variant='text' fullWidth>
          //               <DownloadIcon
          //                 color='primary'
          //                 // style={{ marginRight: '8px' }}
          //                 onClick={e => {
          //                   e.stopPropagation();
          //                   handleDownloadDocument(document.documentSeq);
          //                 }}
          //               />
          //             </Button>
          //           </Tooltip>

          //           <div>
          //             {auth.user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']) && (
          //               <Tooltip title='Delete Publication' arrow>
          //                 <IconButton
          //                   color='info'
          //                   onClick={e => {
          //                     e.stopPropagation();
          //                     setShowConfirmation(true);
          //                     setDeleteDocumentSeq(document.documentSeq);
          //                   }}
          //                 >
          //                   <DeleteIcon />
          //                 </IconButton>
          //               </Tooltip>
          //             )}
          //           </div>
          //         </Stack>
          //       </Box>
          //     </CardContent>
          //   </Card>
          // </div>
        ))}
      </Grid>

      <div className='modal'>
        <Dialog
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle id='responsive-dialog-title'>{'Confirm Void File'}</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to void this file?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDocument} autoFocus>
              Yes
            </Button>
            <Button autoFocus onClick={() => setShowConfirmation(false)}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <MLResourceDropzone
        isOpen={open}
        setIsOpen={setOpen}
        onSuccessUpload={handleSuccessfulUpload}
      />
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowErrorSnackbar(false)}
      >
        <Alert onClose={() => setShowErrorSnackbar(false)} severity='error'>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
