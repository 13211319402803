import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import CaseDocuments from '../../../components/CaseRecords/CaseDocuments';
import { useAuth } from '../../../utils/auth/AuthService';
import { renderAutocompleteEditCell } from '../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import { renderAutocompleteEditCellWithDescription } from '../../../utils/components/CrudDataGrid/AutocompleteEditCellWithDescription';
import CrudDataGrid from '../../../utils/components/CrudDataGrid/CrudDataGrid';
import { dateColumnType } from '../../../utils/components/CrudDataGrid/DateColumn';
import { renderMultiAutocompleteEditCell } from '../../../utils/components/CrudDataGrid/MultiAutocompleteEditCell';
import { sortOptionsAlphabetically } from '../../../utils/functions/sortOptionsAlphabetically';
import { FormikTextField } from '../../../utils/components/FormikTextField';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function LabTestingView() {
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext();

  const [labTestTrackingRows, setLabTestTrackingRows] = useState(
    formik.values?.caseLabTesting.labTestTracking ?? []
  );

  const [toxResultsRows, setToxResultsRows] = useState(
    formik.values?.caseLabTesting.toxLabResults ?? []
  );

  const [testOptions, setTestOptions] = useState(null);
  const [labOptions, setLabOptions] = useState(null);
  const [tagOptions, setTagOptions] = useState(null);

  const [codTagOptions, setCODTagOptions] = useState([]);
  const [specimenOptions, setSpecimenOptions] = useState([]);
  const [analyteOptions, setAnalyteOptions] = useState([]);

  const loadToxResultsData = async () => {
    let formData = new FormData();

    formData.append('JDXLIST', JSON.stringify(user.jdxAccessList));
    formData.append('USERSEQ', user.userSeq);
    formData.append('CASESEQ', formik.values?.caseSummary.caseSeq);

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'getToxResults', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setToxResultsRows(data);
          formik.setFieldValue('caseLabTesting.toxLabResults', data);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      console.log('user or token is null');
    }
  };

  useEffect(() => {
    // INITIALIZE TEST OPTIONS
    fetch(REACT_APP_API_URL + 'getlabtestoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setTestOptions(sortOptionsAlphabetically(data, 'optionName'));
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE LAB OPTIONS
    fetch(REACT_APP_API_URL + 'getlaboptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setLabOptions(sortOptionsAlphabetically(data, 'optionName'));
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE TAG OPTIONS
    fetch(REACT_APP_API_URL + 'getlabtesttrackingtagoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setTagOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    fetch(REACT_APP_API_URL + 'getcodtagoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCODTagOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    fetch(REACT_APP_API_URL + 'getspecimenoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setSpecimenOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    fetch(REACT_APP_API_URL + 'getanalyteoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAnalyteOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    loadToxResultsData();
  }, []);

  const createLabTestTracking = newRow => {
    // console.log('GOT HERE CREATE LAB TEST TRACKING');

    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    formData.append('userSeq', user.userSeq);
    console.log('lab new row', newRow);
    fetch(REACT_APP_API_URL + 'createlabtesttracking', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        // let values = labTestTrackingRows.map(row => (row.id === newRow.id ? newRow : row));
        // setcaseinfo({
        //   ...caseinfo,
        //   caseLabTesting: {
        //     ...caseinfo.caseLabTesting,
        //     labTestTracking: values,
        //   },
        // });
      })
      .catch(e => {
        console.log(e);
      });
  };

  const updateLabTestTracking = updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));
    formData.append('userSeq', user.userSeq);

    fetch(REACT_APP_API_URL + 'updatelabtesttracking', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        // let values = labTestTrackingRows.map(row => (row.id === updatedRow.id ? updatedRow : row));
        // setcaseinfo({
        //   ...caseinfo,
        //   caseLabTesting: {
        //     ...caseinfo.caseLabTesting,
        //     labTestTracking: values,
        //   },
        // });
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteLabTestTracking = id => {
    fetch(REACT_APP_API_URL + `deletelabtesttracking/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        // let values = labTestTrackingRows.filter(row => row.id !== id);
        // setcaseinfo({
        //   ...caseinfo,
        //   caseLabTesting: {
        //     ...caseinfo.caseLabTesting,
        //     labTestTracking: values,
        //   },
        // });
      })
      .catch(e => {
        console.log(e);
      });
  };

  const createToxResult = newRow => {
    console.log(newRow);

    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    formData.append('userSeq', user.userSeq);

    fetch(REACT_APP_API_URL + 'createToxResult', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        // let values = toxResultsRows.map(row => (row.id === newRow.id ? newRow : row));
        // setcaseinfo({
        //   ...caseinfo,
        //   caseLabTesting: {
        //     ...caseinfo.caseLabTesting,
        //     toxLabResults: values,
        //   },
        // });
      })
      .catch(e => {
        console.log(e);
      });
  };

  const updateToxResult = updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));
    formData.append('userSeq', user.userSeq);

    fetch(REACT_APP_API_URL + 'updatelabtestresult', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        // let values = toxResultsRows.map(row => (row.id === updatedRow.id ? updatedRow : row));
        // setcaseinfo({
        //   ...caseinfo,
        //   caseLabTesting: {
        //     ...caseinfo.caseLabTesting,
        //     toxLabResults: values,
        //   },
        // });
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteToxResult = id => {
    fetch(REACT_APP_API_URL + `deletelabtestresult/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        // let values = toxResultsRows.filter(row => row.id !== id);
        // setcaseinfo({
        //   ...caseinfo,
        //   caseLabTesting: {
        //     ...caseinfo.caseLabTesting,
        //     toxLabResults: values,
        //   },
        // });
      })
      .catch(e => {
        console.log(e);
      });
  };

  const informantcntinfocolumns = [
    {
      field: 'test',
      headerName: 'Test Type',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: params => {
        return params.value ? params.value.optionName : '';
      },
    },
    {
      field: 'details',
      headerName: 'Value',
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      renderCell: params => {
        return (
          <div>
            <Button
              onClick={e => onContactInfoEditClick(e, params.row)}
              size='small'
              color='primary'
              style={{ justifyContent: 'left' }}
              startIcon={<EditIcon />}
            ></Button>
            <Button
              onClick={e => onContactInfoDeleteClick(e, params.row)}
              size='small'
              color='error'
              style={{ justifyContent: 'left' }}
              startIcon={<DeleteIcon />}
            ></Button>
          </div>
        );
      },
    },
  ];

  const labTestTrackingColumns = [
    {
      flex: 1,
      minWidth: 150,
      field: 'test',
      headerName: 'Type of Test',
      editable: true,
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: testOptions,
      renderEditCell: params => renderAutocompleteEditCell(params, 'Test'),
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'retained',
      headerName: 'Retained (Not Tested)',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.65,
      minWidth: 97,
      field: 'notTaken',
      headerName: 'Not Taken',
      type: 'boolean',
      editable: true,
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 1,
      field: 'dateRequested',
      headerName: 'Date Requested',
      headerAlign: 'left',
      align: 'left',
      ...dateColumnType,
      editable: true,
      valueGetter: params => {
        if (!params?.value) return undefined;
        if (params?.value === 'Invalid Date') {
          return undefined;
        }
        return new Date(params.value);
      },
    },
    {
      flex: 1,
      field: 'dateReceived',
      headerName: 'Date Received',
      headerAlign: 'left',
      align: 'left',
      ...dateColumnType,
      editable: true,
      valueGetter: params => {
        if (!params?.value) return undefined;
        if (params?.value === 'Invalid Date') {
          return undefined;
        }
        return new Date(params.value);
      },
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'lab',
      headerName: 'Lab Name',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      type: 'singleSelect',
      editable: true,
      valueOptions: labOptions,
      renderEditCell: params => renderAutocompleteEditCell(params, 'Lab'),
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'tags',
      headerName: 'Tags',
      headerAlign: 'left',
      align: 'left',
      type: 'singleSelect',
      editable: true,
      valueOptions: tagOptions,
      renderCell: params => {
        if (!params.value) return null;
        return (
          <Stack direction='row' spacing={1}>
            {params.value.map(tag => (
              <Chip label={tag.optionName} />
            ))}
          </Stack>
        );
      },
      renderEditCell: params => renderMultiAutocompleteEditCell(params, 'Tag'),
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'comments',
      headerName: 'Comments',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      editable: true,
    },
  ];

  const toxResultsColumns = [
    {
      flex: 1,
      minWidth: 100,
      field: 'labreferencenumber',
      headerName: 'Work Order#',
      editable: true,
      type: 'string',
      align: 'left',
      headerAlign: 'Left',
    },
    {
      flex: 1,
      minWidth: 200,
      field: 'specimen',
      headerName: 'Specimen',
      editable: true,
      type: 'singleSelect',
      align: 'left',
      headerAlign: 'Left',
      valueFormatter: params => {
        return params.value ? params.value.optionName : '';
      },
      valueOptions: specimenOptions,
      renderEditCell: renderAutocompleteEditCell,
    },
    {
      flex: 1,
      minWidth: 200,
      field: 'analyte',
      headerName: 'Analyte',
      editable: true,
      type: 'string',
      align: 'left',
      headerAlign: 'Left',
      valueFormatter: params => {
        return params.value ? params.value.optionName : '';
      },
      valueOptions: analyteOptions,
      renderEditCell: renderAutocompleteEditCell,
    },
    {
      flex: 1,
      minWidth: 75,
      field: 'quantitativeresult',
      headerName: 'Amount Detected',
      editable: true,
      type: 'number',
      align: 'left',
      headerAlign: 'Left',
      valueFormatter: params => {
        if (params.formattedValue !== undefined) {
          return parseFloat(params.formattedValue).toFixed(3);
        }
        return null;
      },
      renderCell: params => {
        if (typeof params.value === 'number') {
          const formattedValue = params.value.toFixed(3);
          return <div>{formattedValue}</div>;
        }
        return null;
      },
    },
    {
      flex: 1,
      minWidth: 75,
      field: 'unitofmeasurement',
      headerName: 'Unit',
      editable: true,
      type: 'string',
      align: 'left',
      headerAlign: 'Left',
    },
    {
      flex: 1,
      minWidth: 75,
      field: 'quantitativelimit',
      headerName: 'Limit of Quantitation',
      editable: true,
      type: 'number',
      align: 'left',
      headerAlign: 'Left',
      renderCell: params => {
        if (typeof params.value === 'number') {
          const formattedValue = params.value.toFixed(3);
          return <div>{formattedValue}</div>;
        }
        return null;
      },
    },
    {
      flex: 1,
      minWidth: 100,
      field: 'finalresult',
      headerName: 'Interpretation',
      editable: true,
      type: 'string',
      align: 'left',
      headerAlign: 'Left',
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'labnotes',
      headerName: 'Lab Notes',
      editable: true,
      type: 'string',
      align: 'left',
      headerAlign: 'Left',
    },
    {
      flex: 1,
      minWidth: 200,
      field: 'codTag',
      headerName: 'COD Tag',
      editable: true,
      type: 'singleSelect',
      align: 'left',
      headerAlign: 'Left',
      valueFormatter: params => {
        return params.value ? params.value.optionName : '';
      },
      valueOptions: codTagOptions,
      renderEditCell: renderAutocompleteEditCellWithDescription,
    },
  ];

  useEffect(() => {
    console.log(formik.values?.caseLabTesting.toxLabResults);
  }, [formik.values?.caseLabTesting.toxLabResults]);

  return (
    <form>
      <Stack spacing={2}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Typography variant='subtitle1' color='gray'>
          <strong>TEST REQUEST LOG/TRACKER</strong>
        </Typography>

        {/* <CondensedDataGrid
          loading={loading}
          listresults={results}
          columnsInput={columns}
          gridheight={150}
          idcolumn='UPDATE THIS'
          columnsVisibility={{
            actions: user.roleCheck('', ''),
          }}
          gridcolor='#e55762'
          tooltip=''
          title='Lab Testing'
        /> */}

        <CrudDataGrid
          rows={formik.values?.caseLabTesting?.labTestTracking ?? []}
          setRows={data => {
            formik.setFieldValue(`caseLabTesting.labTestTracking`, data);
          }}
          idcolumn='labTestOnCaseSeq'
          initialColumns={labTestTrackingColumns}
          createEntry={createLabTestTracking}
          deleteEntry={deleteLabTestTracking}
          updateEntry={updateLabTestTracking}
          title='Requested Test'
        />

        <Grid container sx={{ mt: 1 }}>
          <FormikTextField
            gridProps={{ xs: 12, md: 3 }}
            authorizedToEdit={r => [
              r.Investigator,
              r.Medical_Examiner,
              r.Administrative_Assistant,
              r.Historic_Dataentryonly,
              r.Systems_Admin,
              r.Morgue_Tech
            ]}
            formikField='caseCertification.labNumber'
            label='Lab Number'
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>

        <Typography variant='subtitle1' color='gray'>
          <strong>TEST DOCUMENTS </strong>
        </Typography>

        <CaseDocuments caseinfo={formik.values?.caseSummary} isLab={true} />

        <br />

        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Typography variant='subtitle1' color='gray'>
          <strong>LAB TEST TOX NOTES </strong>
        </Typography>

        <TextField
          disabled={!formik.status.editing}
          fullWidth
          id='caseLabTesting.finalToxicologyResult'
          name='caseLabTesting.finalToxicologyResult'
          label='Tox Notes'
          variant='outlined'
          multiline
          rows={4}
          value={formik.values?.caseLabTesting.finalToxicologyResult}
          onChange={formik.handleChange}
          error={
            (formik.touched?.caseLabTesting?.finalToxicologyResult ?? false) &&
            Boolean(formik.errors?.caseLabTesting?.finalToxicologyResult ?? false)
          }
          helperText={
            (formik.touched?.caseLabTesting?.finalToxicologyResult ?? false) &&
            (formik.errors?.caseLabTesting?.finalToxicologyResult ?? false)
          }
        />
        <br />

        <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Typography variant='subtitle1' color='gray'>
          <strong>TOXICOLOGY RESULTS </strong>
        </Typography>

        <CrudDataGrid
          rows={formik.values?.caseLabTesting.toxLabResults ?? []}
          setRows={data => {
            formik.setFieldValue(`caseLabTesting.toxLabResults`, data);
          }}
          initialColumns={toxResultsColumns}
          createEntry={createToxResult}
          deleteEntry={deleteToxResult}
          updateEntry={updateToxResult}
          title='Tox Results'
          importOption
          legend={
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell key='code'>Code</TableCell>
                    <TableCell key='short'>Short Description</TableCell>
                    <TableCell key='long'>Description of Code Substances</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {codTagOptions.map(row => (
                    <TableRow key={row.code}>
                      <TableCell>{row.optionName}</TableCell>
                      <TableCell>{row.optionShortDesc}</TableCell>
                      <TableCell>{row.optionLongDesc}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        />
      </Stack>
    </form>
  );
}
