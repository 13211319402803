import { Box, Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import DesktopCard from './DesktopCard';
import { getTimeBlock } from './getTimeBlock';

export default function DesktopHomeView() {
  const { user } = useAuth();
  const [cardContent, setCardContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const { REACT_APP_API_URL } = process.env;

  useEffect(() => {
    // GET CARD CONTENT
    fetch(REACT_APP_API_URL + 'home/getcardcontent', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCardContent(data);
      })
      .catch(e => {
        //alert(e);
      });

    setLoading(false);
  }, []);

  return (
    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
      <Typography variant='h3'>
        Good {getTimeBlock()}, {user?.userFirstName}
      </Typography>
      <Grid
        container
        spacing={5}
        sx={{ mt: 5, height: '500px' }}
        justifyContent='center'
        alignItems='center'
      >
        {loading ? (
          <Skeleton height={500} sx={{ width: '35%', borderRadius: 4 }} />
        ) : (
          cardContent.map(card => (
            <Grid xs={card.desktopSize}>
              <DesktopCard
                title={card.title}
                subtitle={card.subtitle}
                content={
                  <Box>
                    <Typography variant='body1' sx={{ fontSize: 13 }}>
                      {card.content1}
                    </Typography>
                    <Typography variant='body2' sx={{ fontSize: 11, pt: 1 }}>
                      {card.content2}
                    </Typography>
                    <Typography variant='body2' sx={{ fontSize: 11, pt: 1, opacity: 0.6 }}>
                      {card.content3}
                    </Typography>
                  </Box>
                }
              />
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
}
