import {
  Description,
  Fingerprint,
  List as ListIcon,
  LocalHospital,
  MonitorHeart as MonitorHeartIcon,
  Science,
  Summarize,
} from '@mui/icons-material';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import '../../App.css';
import CaseViewDrawer from '../../components/CaseView/CaseViewDrawer';
import CaseViewHeader from '../../components/CaseView/CaseViewHeader';
import TabPanel from '../../components/CaseView/CaseViewTabPanel';
import CommonAlert from '../../components/CommonAlert';
import { useAuth } from '../../utils/auth/AuthService';
import { MEAction } from '../../utils/constants/MEAction';
import { userHasAccess } from '../../utils/functions/userHasAccess';
import InvestigationsView from './CaseViewModules/InvestigationsView.jsx';

import dayjs from 'dayjs';
import LabTestingView from './CaseViewModules/LabTestingView';
import MedicalImagingView from './CaseViewModules/MedicalImagingView';
import MortuaryView from './CaseViewModules/MortuaryView';
import PathologyView from './CaseViewModules/PathologyView';
import RecordsView from './CaseViewModules/RecordsView';
import SummaryView from './CaseViewModules/SummaryView';

// HUB CONNECTION IMPORT
import { HubConnectionBuilder } from '@microsoft/signalr';
import { format } from 'date-fns';
import CaseViewAccessRestricted from '../../components/CaseView/CaseViewAccessRestricted';
import CaseViewLoading from '../../components/CaseView/CaseViewLoading';
import CaseViewNoData from '../../components/CaseView/CaseViewNoData';
import CaseViewVoid from '../../components/CaseView/CaseViewVoid';
import { validateDateTimes } from '../../utils/components/dateUtils';
import dimensions from '../../utils/dimensions';
import { useDebouncedValidate } from '../../utils/functions/debouncedFormikValidation';
import useStickyState from '../../utils/functions/useStickyState';
import { useCaseSearch } from '../../utils/hooks/CaseSearch/useCaseSearch.hook';
import messages from '../../utils/messages.json';

function generateFieldAccess(data) {
  let fieldAccessObject = {};

  for (let field in data) {
    if (data.hasOwnProperty(field)) {
      fieldAccessObject[field] = {};

      for (let nestedField in data[field]) {
        if (data[field].hasOwnProperty(nestedField)) {
          fieldAccessObject[field][nestedField] = {
            enabled: true,
            visible: true,
          };
        }
      }
    }
  }

  return fieldAccessObject;
}

export default function CaseView() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cmsCaseId, setCmsCaseID] = useState('');
  const [accessRestricted, setAccessRestricted] = useState(false);
  const [noData, setNoData] = useState(true);
  const [isVoid, setIsVoid] = useState(true);
  const [isEditing, setIsEditing] = useState(true);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = useStickyState(0, 'case.sidebar', state.cmscaseid);
  const { setCaseIDFromCaseView } = useCaseSearch();

  const useSearchParams = () => new URLSearchParams(useLocation().search);
  const searchParams = useSearchParams();
  const { REACT_APP_API_URL } = process.env;
  const navigate = useNavigate();
  const location = useLocation();
  const [cmsCase, setCmsCase] = useState(null);
  const [cmsCaseStored, setCmsCaseStored] = useState(null);
  const [showAlert, setShowAlert] = useState(null);
  const [connection, setConnection] = useState(null);

  const [headerHeight, setHeaderHeight] = useState(0);
  const [drawerWidth, setDrawerWidth] = useState(0);

  const { user } = useAuth();

  const systemsAdmin = user.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']);

  const formik = useFormik({
    initialValues: cmsCase,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: values => {
      return validationForm(values);
    },
    initialStatus: {
      editing: false,
      loadingSaving: false,
      clearingHoldDateTimeUpdated: Array(
        cmsCase?.caseRecords?.chargeableRequests?.length ?? 0
      ).fill(false),
      caseLocked: false,
      editingStatusMessage: null,
      connection: connection,
      RECORD_LOCKING_ENABLED: true, // SET THIS FLAG TO TRUE TO ENABLE RECORD LOCKING
      disabledClickCount: 0,
      // moduleVisibilityStatus: {
      //   overview: {
      //     enabled: true,
      //     visible: true,
      //   },
      //   // demographics: {
      //   //   enabled: true,
      //   //   visible: true,
      //   // },
      //   investigations: {
      //     enabled: true,
      //     visible: true,
      //   },
      //   mortuary: {
      //     enabled: true,
      //     visible: true,
      //   },
      //   medicalImaging: {
      //     enabled: true,
      //     visible: true,
      //   },
      //   labTesting: {
      //     enabled: true,
      //     visible: true,
      //   },
      //   pathology: {
      //     enabled: true,
      //     visible: true,
      //   },
      //   records: {
      //     enabled: true,
      //     visible: true,
      //   },
      // },
    },
  });

  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });

  const [drawerContents, setDrawerContents] = useState([
    {
      text: 'Overview',
      icon: <Summarize />,
      disabled: false,
    },
    {
      text: 'Investigations',
      icon: <Fingerprint />,
      disabled: false,
    },
    {
      text: 'Mortuary',
      icon: <LocalHospital />,
      disabled: false,
    },
    {
      text: 'Medical Images',
      icon: <MonitorHeartIcon />,
      disabled: false,
    },
    {
      text: 'Lab Testing',
      icon: <Science />,
      disabled: false,
    },
    {
      text: 'Pathology',
      icon: <Description />,
      disabled: false,
    },
    {
      text: 'Records',
      icon: <ListIcon />,
      disabled: false,
    },
  ]);

  // THIS USEEFFECT WILL DISABLE THE MORTUARY AND MEDICAL IMAGING
  // MODULES, BUT IS CURRENTLY COMMENTED OUT DUE TO LACK OF PROPER
  // TESTING
  // useEffect(() => {
  //   const updatedContents = drawerContents.map(item => {
  //     if (item.text === 'Mortuary' || item.text === 'Medical Images') {
  //       return {
  //         ...item,
  //         disabled:
  //           formik?.values?.caseInvestigations?.meAction?.optionSeq?.toUpperCase() !==
  //           ME_Action.Accepted,
  //       };
  //     }
  //     return item;
  //   });
  //   setDrawerContents(updatedContents);

  //   if (
  //     formik?.values?.caseInvestigations?.meAction?.optionSeq?.toUpperCase() !==
  //       ME_Action.Accepted &&
  //     (currentTab == 2 || currentTab == 3)
  //   ) {
  //     setCurrentTab(0);
  //   }
  // }, [formik?.values?.caseInvestigations?.meAction]);

  const establishHubConnection = (caseSeq, userSeq) => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(REACT_APP_API_URL + `lockingHub?caseSeq=${caseSeq}&userSeq=${userSeq}`)
      .withAutomaticReconnect()
      .build();

    hubConnection
      .start()
      .then(() => {})
      .catch(error => console.error(error));

    setConnection(hubConnection);
  };

  // Runs on dismount to clear the caseID from the useCaseSearch hook
  useEffect(() => {
    return () => {
      setCaseIDFromCaseView('');
    };
  }, [navigate, location]);

  useEffect(() => {
    if (formik.status?.RECORD_LOCKING_ENABLED === true && formik?.status?.connection !== null) {
      formik.setStatus({ ...formik.status, connection: connection });
    }
  }, [connection]);

  // useEffect(() => {
  //   if (searchParams.get('id') != null) {
  //     if (!userHasAccess(user, searchParams.get('id'))) {
  //       setcmscaseid(state.cmscaseid);
  //       setAccessRestricted(true);
  //       setLoading(false);
  //       return;
  //     } else {
  //       setAccessRestricted(false);
  //       GetCMSCaseData(searchParams.get('id'));
  //     }
  //   }
  // }, [searchParams]);

  useEffect(() => {
    if (state?.cmscaseid != cmsCaseId) {
      if (!userHasAccess(user, state.cmscaseid)) {
        setCmsCaseID(state.cmscaseid);
        setAccessRestricted(true);
        setLoading(false);
        return;
      } else {
        setAccessRestricted(false);
        setShowAlert(null);
        GetCMSCaseData(state.cmscaseid, state.caseSeq);
        InsertCaseVisitHistory();
      }
    }
  }, [state.cmscaseid]);

  useEffect(() => {
    if (formik.status?.RECORD_LOCKING_ENABLED === true && formik.status?.connection) {
      formik.status?.connection?.on('CaseLocked', (caseSeq, userName, lockedDateTime) => {
        if (caseSeq.toUpperCase() === formik.values?.caseSummary?.caseSeq.toUpperCase()) {
          formik.setStatus({
            ...formik.status,
            caseLocked: true,
            editingStatus: `This case is currently being edited by ${userName} (${new Date(
              lockedDateTime
            ).toLocaleString()}).`,
          });
        }
      });

      formik.status?.connection?.on('CaseUnlocked', caseSeq => {
        if (caseSeq.toUpperCase() === formik.values?.caseSummary?.caseSeq.toUpperCase()) {
          formik.setStatus({
            ...formik.status,
            caseLocked: false,
            editingStatus: null,
          });
        }
      });

      formik.status?.connection?.on('CaseEditsDiscarded', caseSeq => {
        if (caseSeq.toUpperCase() === formik.values?.caseSummary?.caseSeq.toUpperCase()) {
          formik.setStatus({
            ...formik.status,
            caseLocked: false,
            editingStatus: null,
          });
        }
      });

      formik.status?.connection?.on('CaseEditsSaved', caseSeq => {
        if (caseSeq.toUpperCase() === formik.values?.caseSummary?.caseSeq.toUpperCase()) {
          formik.setStatus({
            ...formik.status,
            caseLocked: false,
            editingStatus: null,
          });

          GetCMSCaseData(cmsCaseId);
        }
      });
    }

    return () => {
      if (formik.status?.RECORD_LOCKING_ENABLED === true && formik.status?.connection) {
        formik.status?.connection.off('CaseLocked');
        formik.status?.connection.off('CaseUnlocked');
        formik.status?.connection.stop();
      }
    };
  }, [formik.status?.connection]);

  const GetCMSCaseData = async caseID => {
    setLoading(true);
    setAccessRestricted(false);
    setIsVoid(false);
    setNoData(false);
    setCmsCase(null);
    setCmsCaseID(caseID);

    let formData = new FormData();
    formData.append('CASEID', caseID);

    // GET CASE DATA
    await fetch(REACT_APP_API_URL + 'getcmscase', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          setAccessRestricted(true);
        } else if (res.status == 410) {
          if (!systemsAdmin) {
            setIsVoid(true);
          }
        } else if (res.status >= 400) {
          setNoData(true);
        }
        if (res.status == 204) {
          setNoData(true);
        }
        return res.json();
      })
      .then(data => {
        setCmsCase(data);
        setCaseIDFromCaseView(data?.caseSummary?.caseNumber);
        formik.setValues(data);
        if (formik.status?.RECORD_LOCKING_ENABLED === true && formik.status?.connection == null) {
          establishHubConnection(data?.caseSummary?.caseSeq, user.userSeq);
        }
        setCmsCaseStored(JSON.parse(JSON.stringify(data)));
      })
      .catch(e => {
        setNoData(true);
      });

    setLoading(false);
  };

  const InsertCaseVisitHistory = async () => {
    let formData = new FormData();
    formData.append('UserSeq', JSON.stringify(user.userSeq));
    formData.append('CaseNumber', JSON.stringify(state.cmscaseid));

    await fetch(REACT_APP_API_URL + 'InsertCaseVisitHistory', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        if (data.message != null) {
          // alert(data.message);
        }
      })
      .catch(e => {
        // alert(e);
      });
  };

  function mapNullOrEmptyProperties(obj) {
    const result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (value === null || value === '') {
          result[key] = false;
        } else if (typeof value === 'object') {
          result[key] = mapNullOrEmptyProperties(value);
        } else {
          result[key] = true;
        }
      }
    }

    return result;
  }

  const isInvalidDate = date => {
    return !date || !dayjs(date).isValid();
  };

  const addError = (errors, field, message) => {
    if (!errors[field]) {
      errors[field] = [];
    }
    errors[field].push(message);
  };

  const validationForm = async values => {
    const errors = {};

    //some cases meApproval = 'Approved' with out cause and manner of death. it was old cases or from cms.
    //So to avoid blocking to save case when meaction changed, added condition do not validate meApproval(it was effecting with me action).
    const shouldValidate =
      values?.casePathology?.meApproval === '' || values?.casePathology?.meApproval === null
        ? JSON.stringify({ ...values?.casePathology, meApproval: undefined }) !==
          JSON.stringify({ ...cmsCaseStored?.casePathology, meApproval: undefined })
        : JSON.stringify(values?.casePathology) !== JSON.stringify(cmsCaseStored?.casePathology);

    if (shouldValidate) {
      if (!values?.casePathology?.mannerOfDeath) {
        addError(errors, 'PATHOLOGY', 'Manner Of Death is required');
      }

      if (!values?.casePathology?.causeOfDeath) {
        addError(errors, 'PATHOLOGY', 'Cause Of Death is required');
      }

      if (
        values?.casePathology?.drugToxicityConfirmed === null ||
        values?.casePathology?.drugToxicityConfirmed === undefined
      ) {
        addError(errors, 'PATHOLOGY', 'Drug Toxicity Confirmation is required');
      }

      if (
        values?.casePathology?.infectiousDiseaseConfirmed === null ||
        values?.casePathology?.infectiousDiseaseConfirmed === undefined
      ) {
        addError(errors, 'PATHOLOGY', 'Infectious Disease Confirmation is required');
      }

      if (
        values?.casePathology?.opioidConfirmed === null ||
        values?.casePathology?.opioidConfirmed === undefined
      ) {
        addError(errors, 'PATHOLOGY', 'Opioid Death Confirmation is required');
      }
    }

    if (
      values?.caseInvestigations?.meAction &&
      values?.caseInvestigations?.meAction?.optionSeq !== MEAction.Constants.Disposition.MAID
    ) {
      const caseReporter = values.caseDemographics?.informantList?.find(
        informant => informant.caseReporter === 1
      );

      if (caseReporter && (caseReporter?.relship === null || caseReporter?.relship === undefined)) {
        addError(errors, 'INVESTIGATIONS', 'Case reporter relationship is required');
      }

      if (
        values?.caseReporterSummary?.caseReporterType?.optionSeq?.toLowerCase() ===
        '25439ade-f997-451a-9122-2c904dad52d9'
      ) {
        if (!values?.caseReporterSummary?.agencyOther) {
          addError(errors, 'INVESTIGATIONS', 'Case reporter agency is required');
        }
      } else if (!values?.caseReporterSummary?.agency) {
        addError(errors, 'INVESTIGATIONS', 'Case reporter agency is required');
      }
    }

    if (
      values?.caseInvestigations?.meAction &&
      values?.caseInvestigations?.meAction?.optionSeq === MEAction.Constants.Disposition.Accepted
    ) {
      if (
        validateDateTimes(
          formik.values.caseMortuary?.checkInDate,
          formik.values.caseMortuary?.examStartDateTime
        )
      ) {
        addError(
          errors,
          'MORTUARY --> PRE-EXAM',
          messages.examStartDateBeforeCheckinDate.replace(
            '{checkInDate}',
            format(new Date(formik.values.caseMortuary?.checkInDate), 'MM/dd/yyyy, HH:mm')
          )
        );
      }

      if (
        validateDateTimes(
          formik.values.caseMortuary?.examStartDateTime,
          formik.values.caseMortuary?.examEndDateTime
        )
      ) {
        addError(
          errors,
          'MORTUARY --> POST-EXAM',
          messages.examEndDateBeforeStartDate.replace(
            '{examStartDate}',
            format(new Date(formik.values.caseMortuary?.examStartDateTime), 'MM/dd/yyyy, HH:mm')
          )
        );
      }
    }

    values = {
      ...values,
      caseSummary: {
        ...values?.caseSummary,
        caseInformation: {
          ...values?.caseSummary?.caseInformation,
          lastUpdatedOn: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        },
      },
    };

    console.log(values);

    setValidationErrors(errors);

    const hasErrors = Object.keys(errors).length > 0;
    setShowErrorAlert(hasErrors);

    if (!hasErrors) {
      try {
        let formData = new FormData();

        formData.append('cmsCase', JSON.stringify(values));
        formData.append('userSeq', user.userSeq);

        const response = await fetch(REACT_APP_API_URL + 'UpdateCase', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
          body: formData,
        });
        setIsEditing(false);
        const responseData = await response.json();
        if (response.ok) {
          if (formik.status?.RECORD_LOCKING_ENABLED === true) {
            formik.status?.connection?.invoke('SaveCaseEdits', formik.values?.caseSummary?.caseSeq);
          }
          GetCMSCaseData(cmsCaseId);
          setShowAlert({
            type: 'success',
            message: responseData.message,
          });
          formik.setStatus({ ...formik.status, editing: false, loadingSaving: false });
          return true;
        } else {
          formik.setStatus({ ...formik.status, loadingSaving: false });

          setShowAlert({
            type: 'error',
            message: responseData.message,
          });
          return false;
        }
      } catch (error) {
        formik.setStatus({ ...formik.status, loadingSaving: false });

        console.error(error);

        setShowAlert({
          type: 'error',
          message: 'An unexpected error occurred while updating the case.',
        });
        return false;
      }
    } else {
      formik.setStatus({ ...formik.status, loadingSaving: false });
      return false;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <FormikProvider value={formik}>
        <CaseViewDrawer
          setDrawerWidth={setDrawerWidth}
          drawerContents={drawerContents}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
      </FormikProvider>

      {loading ? (
        <CaseViewLoading />
      ) : accessRestricted ? (
        <CaseViewAccessRestricted caseID={cmsCaseId} />
      ) : isVoid ? (
        <CaseViewVoid caseID={cmsCaseId} />
      ) : noData ? (
        <CaseViewNoData caseID={cmsCaseId} />
      ) : (
        <FormikProvider value={formik}>
          <Form style={{ height: '100%', width: `calc(100% - ${drawerWidth}px` }}>
            <Box sx={{ height: '100%', width: '100%' }}>
              <CaseViewHeader
                setHeaderHeight={setHeaderHeight}
                setMenuOpen={setMenuOpen}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                getCMSCaseData={GetCMSCaseData}
                showErrorAlert={showErrorAlert}
                setShowErrorAlert={setShowErrorAlert}
                validationErrors={validationErrors}
              />

              {/* Case View Content */}
              <Box
                sx={{
                  // width: { xs: '100%', md: `calc(100% - ${drawerWidth}px)` },
                  height: `calc(100% - ${headerHeight}px)`,
                  position: 'relative',
                  overflow: 'scroll',
                }}
              >
                {/* {showErrorAlert && (
                  <div style={{ padding: '10px 20px 0 20px', position: 'sticky', top: 200 }}>
                    <Alert
                      style={{ backgroundColor: 'rgba(255, 165, 0, 0.3)' }}
                      isOpen={showErrorAlert}
                      onClose={() => setShowErrorAlert(false)}
                    >
                      {Object.keys(validationErrors)?.map(fieldName => (
                        <div key={fieldName} style={{ fontSize: '14px', marginBottom: '10px' }}>
                          <span>{fieldName}:</span>
                          <ul>
                            {validationErrors[fieldName]?.map((error, index) => (
                              <li key={index}>{error}</li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </Alert>
                  </div>
                )} */}

                {showAlert && (
                  <CommonAlert
                    severity={showAlert.type}
                    message={showAlert.message}
                    resetKey={showAlert}
                  />
                )}
                {/* 
                <TabPanel value={currentTab} index={0}>
                <DemographicsView />

                </TabPanel> */}
                <TabPanel value={currentTab} index={0}>
                  <SummaryView />
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                  <InvestigationsView />
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                  <MortuaryView />
                </TabPanel>
                <TabPanel value={currentTab} index={3}>
                  <MedicalImagingView />
                </TabPanel>
                <TabPanel value={currentTab} index={4}>
                  <LabTestingView />
                </TabPanel>

                <TabPanel value={currentTab} index={5}>
                  <PathologyView />
                </TabPanel>

                {/* <TabPanel value={currentTab} index={5}>
                  <CertificationView />
                </TabPanel> */}

                <TabPanel value={currentTab} index={6}>
                  <RecordsView />
                </TabPanel>
              </Box>
            </Box>
          </Form>
        </FormikProvider>
      )}
    </Box>
  );
}
