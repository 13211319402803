import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import ReactDOM from 'react-dom';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import SignatureCanvas from 'react-signature-canvas';
import { Alert } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { object } from 'yup';
import CaseNotes from '../../../components/CaseNotes';
import CondensedDataGrid from '../../../components/CondensedDataGrid';
import { useAuth } from '../../../utils/auth/AuthService';
import { PrintLabelSetButton } from '../../../utils/components/LabelSetPDF';
import BodyReleaseForm from '../../Reports/BodyReleaseForm';
// import PropertyInventoryForm from '../../Reports/PropertyInventoryForm';
import CaseDocuments from '../../../components/CaseRecords/CaseDocuments';
import CaseViewDateTimeField from '../../../components/CaseView/CaseViewDateTimeField';
import LabTestingView from '../CaseViewModules/LabTestingView';
import { RequestedProcedures } from './MortuaryModules/RequestedProcedures';
import { XrayRequestFormView } from './MortuaryModules/XrayRequestFormView';

// Property Inventory Grids:
import { useLocation } from 'react-router';
import { CaseViewOptionsAutocomplete } from '../../../utils/components/CaseViewOptionsAutocomplete';
import { FormikTextField } from '../../../utils/components/FormikTextField';
import {
  AssignmentTypes,
  authorizingRelease_Other,
  excludedLabelSetTypeOptions,
  labelNameAbbreviations,
  ME_Action,
} from '../../../utils/constants/constants';
import useStickyState from '../../../utils/functions/useStickyState';
import { PrintPropertyInventoryButton } from '../../Reports/PropertyInventoryForm';
import { ClothingDataGrid } from './MortuaryModules/PropertyInventory/ClothingDataGrid';
import { CurrencyDataGrid } from './MortuaryModules/PropertyInventory/CurrencyDataGrid';
import { DocumentsDataGrid } from './MortuaryModules/PropertyInventory/DocumentsDataGrid';
import { EvidenceDataGrid } from './MortuaryModules/PropertyInventory/EvidenceDataGrid';
import { JewelryDataGrid } from './MortuaryModules/PropertyInventory/JewelryDataGrid';
import { OtherPIDataGrid } from './MortuaryModules/PropertyInventory/OtherPIDataGrid';
import { format } from 'date-fns';
import { validateDateTimes } from '../../../utils/components/dateUtils';
import messages from '../../../utils/messages.json';

function getValueOrDefault(value) {
  if (value === null || value === undefined) {
    return undefined;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return undefined;
  }

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return undefined;
  }

  return value;
}

export default function MortuaryView() {
  const { user } = useAuth();
  const location = useLocation();
  const formik = useFormikContext();
  const theme = useTheme();
  const [examtypeOptions, setExamTypeOptions] = useState([]);
  const [labelSetOptions, setLabelSetOptions] = useState([]);
  const [filteredLabelSetOptions, setFilteredLabelSetOptions] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState([]);
  const [meOptions, setMEOptions] = useState([]);
  const [funeralHomeOptions, setFuneralHomeOptions] = useState([]);
  const [funeralHomeOptionsLoading, setFuneralHomeOptionsLoading] = useState(false);
  const [transporterOptions, setTransporterOptions] = useState([]);
  const [storageLocOptions, setStorageLocOptions] = useState([]);
  const [storageLocOptionsLoading, setStorageLocOptionsLoading] = useState(false);

  const { REACT_APP_API_URL } = process.env;
  const authorizedToEdit = user.roleCheck([
    'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
    'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
    'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
    '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
    '0b24365b-1159-4359-b302-9df8946bd62f', // Morgue Tech
    '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
  ]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editedRowData, setEditedRowData] = useState([]);
  const [isAckDialogOpen, setIsAckDialogOpen] = useState(false);
  const [isSignatureDialogOpen, setIsSignatureDialogOpen] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const [activeTab, setActiveTab] = useStickyState(
    0,
    'case.morturary.tab',
    location.state.cmscaseid
  );
  const [morgueTechOptions, setMorgueTechOptions] = useState([]);
  // Accordion Expansions:
  const [clothingExpanded, setClothingExpanded] = useState(false);
  const [currencyExpanded, setCurrencyExpanded] = useState(false);
  const [documentsExpanded, setDocumentsExpanded] = useState(false);
  const [evidenceExpanded, setEvidenceExpanded] = useState(false);
  const [jewelryExpanded, setJewelryExpanded] = useState(false);
  const [otherPIExpanded, setOtherPIExpanded] = useState(false);
  const [requestedProceduresExpanded, setRequestedProceduresExpanded] = useState(false);
  const [xrayRequestFormExpanded, setXrayRequestFormExpanded] = useState(false);
  const [meMTOptions, setMeMTOptions] = useState([]);
  const [authorizingReleaseRelship, setAuthorizingReleaseRelship] = useState(null);
  const [authorizingReleaseContact, setAuthorizingReleaseContact] = useState(null);

  // const [informantOptions, setInformantOptions] = useState([]);

  const validationSchema = object({
    // Add validation rules here
  });

  function sortOptions(options, selectedValues) {
    return options.sort((a, b) => {
      const aIsSelected = selectedValues.some(val => val.optionSeq === a.optionSeq);
      const bIsSelected = selectedValues.some(val => val.optionSeq === b.optionSeq);
      return aIsSelected === bIsSelected ? 0 : aIsSelected ? -1 : 1;
    });
  }

  const getFirstActiveContactNumber = contactInfoList => {
    const activeContacts = contactInfoList?.find(info => info.isActive) || {};
    return activeContacts?.contactItemDetails || '';
  };

  // useEffect(() => {
  const informantOptions = [
    ...(formik.values?.caseDemographics?.informantList?.map(option => ({
      informantSeq: option?.informantSeq,
      personSeq: option?.personSeq,
      personLastName: option?.personLastName,
      personFirstName: option?.personFirstName,
      relship: option?.relship?.optionName,
      PersonContactInfoSeq: option?.personContactInfoSeq,
      contactNumber: getFirstActiveContactNumber(option?.informantAddress?.contactItem || []),
    })) || []),
    { personSeq: authorizingRelease_Other, personLastName: 'Other', personFirstName: '' },
  ];
  //   setInformantOptions(informantOptions);
  // }, [formik.values?.caseDemographics?.informantList]);

  const sigCanvas = useRef({});

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const saveSignature = () => {
    const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    formik.setFieldValue('caseMortuary.signature', signature);
    formik.submitForm();
    setIsSignatureDialogOpen(false);
  };

  // Tracks the exam label to filter out unwanted results, and then rename them correctly
  useEffect(() => {
    if (labelSetOptions.length > 0) {
      const filteredOptions = labelSetOptions
        .filter(option => !excludedLabelSetTypeOptions.includes(option.optionSeq))
        .map(option => ({
          ...option,
          optionName: labelNameAbbreviations[option.optionName] || option.optionName,
        }));

      setFilteredLabelSetOptions(filteredOptions);

      const examLabel = formik.values?.caseMortuary?.examLabel;
      if (examLabel != null) {
        const foundOption = filteredOptions.find(option => option.optionSeq === examLabel);
        setSelectedLabel(foundOption ? foundOption.optionName : '');
      }
    }
  }, [labelSetOptions, formik.values?.caseMortuary?.examLabel]);

  // const formik = useFormik({
  //   initialValues: formik.values?.caseMortuary,
  //   enableReinitialize: true,
  //   validationSchema: validationSchema,

  //   onSubmit: values => {
  //     let newData;
  //     const caseOnStorageLocation = formik.values?.caseMortuary.caseOnStorageLocation ?? [];
  //     if (editedRowData.caseStorageLocationSeq === null) {
  //       // If caseStorageLocationSeq is null, it means it's a new row, generate a new GUID
  //       newData = [
  //         ...caseOnStorageLocation,
  //         {
  //           ...editedRowData,
  //           caseStorageLocationSeq: uuidv4(), // Generate a new GUID for the new row
  //         },
  //       ];
  //     } else {
  //       // Update the existing row with the edited data
  //       newData = caseOnStorageLocation.map(row =>
  //         row.caseStorageLocationSeq === editedRowData.caseStorageLocationSeq ? editedRowData : row
  //       );
  //     }

  //     formik.setFieldValue('caseOnStorageLocation', newData);
  //     setEditedRowData([]);
  //     setIsDialogOpen(false);
  //   },
  // });

  // Accordion Handlers
  const handleClothingExpansion = () => {
    setClothingExpanded(!clothingExpanded);
  };
  const handleCurrencyExpansion = () => {
    setCurrencyExpanded(!currencyExpanded);
  };
  const handleDocumentsExpansion = () => {
    setDocumentsExpanded(!documentsExpanded);
  };
  const handleEvidenceExpansion = () => {
    setEvidenceExpanded(!evidenceExpanded);
  };
  const handleJewelryExpansion = () => {
    setJewelryExpanded(!jewelryExpanded);
  };
  const handleOtherPIExpansion = () => {
    setOtherPIExpanded(!otherPIExpanded);
  };
  const handleRequestedProceduresExpansion = () => {
    setRequestedProceduresExpanded(!requestedProceduresExpanded);
  };

  const handleXrayRequestFormExpansion = () => {
    setXrayRequestFormExpanded(!xrayRequestFormExpanded);
  };
  // const handlePrintInventory = () => {
  //   // Add your logic for printing inventory here
  //   console.log('Printing inventory...');
  // };

  const handleStorageLocationSubmit = () => {
    let newData;
    const caseOnStorageLocation = formik.values?.caseMortuary.caseOnStorageLocation ?? [];
    if (editedRowData.caseStorageLocationSeq === null) {
      // If caseStorageLocationSeq is null, it means it's a new row, generate a new GUID
      newData = [
        ...caseOnStorageLocation,
        {
          ...editedRowData,
          caseStorageLocationSeq: uuidv4(), // Generate a new GUID for the new row
        },
      ];
    } else {
      // Update the existing row with the edited data
      newData = caseOnStorageLocation.map(row =>
        row.caseStorageLocationSeq === editedRowData.caseStorageLocationSeq ? editedRowData : row
      );
    }

    formik.setFieldValue('caseMortuary.caseOnStorageLocation', newData);
    setEditedRowData([]);
    setIsDialogOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const dismissAlert = () => {
    setShowErrorAlert(false);
  };

  const handlePrintBodyReleaseForm = async e => {
    const pdfGenerator = <BodyReleaseForm casedata={formik.values} />;
    ReactDOM.render(pdfGenerator, document.getElementById('pdf-container'));
  };

  const handleGetSignatureForReceiptOfBody = async e => {
    setIsSignatureDialogOpen(true);
  };

  const handlePrintPropertyInventory = async e => {
    const pdfGenerator = <PropertyInventoryForm casedata={formik.values} />;
    ReactDOM.render(pdfGenerator, document.getElementById('pdf-container'));
  };

  const formatToDatetimeLocal = dateTime => {
    if (!dateTime) return '';
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0');
    const day = String(dateTime.getDate()).padStart(2, '0');
    const hours = String(dateTime.getHours()).padStart(2, '0');
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');
    const seconds = String(dateTime.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const onAddClick = () => {
    if (!formik.values?.caseMortuary?.storageLocation) {
      setShowErrorAlert(true);
      return;
    }
    const newRow = {
      caseStorageLocationSeq: null,
      locationSeq: formik.values?.caseMortuary?.storageLocation,
      priorLocation: formik.values?.caseMortuary?.storageLocation
        ? storageLocOptions.find(
            option => option.optionSeq === formik.values?.caseMortuary.storageLocation
          )?.optionName || null
        : null,
      fromDate: formik.values?.caseMortuary.caseOnStorageLocation
        ? formik.values?.caseMortuary.caseOnStorageLocation[0].toDate
        : formatToDatetimeLocal(new Date()),
      toDate: formatToDatetimeLocal(new Date()),
      checkinUserSeq: user?.userSeq,
      checkinUser: `${user?.userLastName}, ${user?.userFirstName}`,
    };
    setEditedRowData(newRow);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsAckDialogOpen(false);
  };

  const handleSignatureDialogClose = () => {
    setIsSignatureDialogOpen(false);
  };

  const handleSignatureDialogOpen = () => {
    setIsSignatureDialogOpen(true);
  };

  const onEditClick = (e, row) => {
    const editRow = {
      caseStorageLocationSeq: row.caseStorageLocationSeq,
      locationSeq: row.locationSeq,
      priorLocation: row.priorLocation,
      fromDate: row.fromDate,
      toDate: row.toDate,
      checkinUserSeq: row.checkinUserSeq,
      checkinUser: row.checkinUser,
    };
    setEditedRowData(editRow);
    setIsDialogOpen(true);
  };

  const columns = [
    {
      field: 'priorLocation',
      headerName: 'Prior Location',
      flex: 1,
      editable: false,
    },
    {
      field: 'fromDate',
      headerName: 'From Date',
      flex: 1,
      editable: false,
    },

    {
      field: 'toDate',
      headerName: 'To Date',
      flex: 1,
      editable: false,
    },

    {
      field: 'checkinUser',
      headerName: 'Checked In By',
      flex: 1,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,

      renderCell: params => {
        return (
          <div>
            <Button
              onClick={e => onEditClick(e, params.row)}
              size='small'
              color='primary'
              style={{ justifyContent: 'left' }}
              startIcon={<EditIcon />}
            ></Button>
          </div>
        );
      },
    },
  ];

  const fetchExamTypeOptions = async () => {
    fetch(REACT_APP_API_URL + 'getexamtypeoptions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setExamTypeOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  const fetchLabelSetOptions = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}getlabelsettypes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('An error occurred');
      }

      const data = await response.json();
      setLabelSetOptions(data);
    } catch (error) {
      console.error('Error fetching label set options:', error);
    }
  };

  const fetchMEs = async () => {
    const queryParams = new URLSearchParams();
    if (formik.values?.caseSummary?.jdxSeq) {
      queryParams.append('jdxList', formik.values?.caseSummary?.jdxSeq);
    }

    await fetch(`${REACT_APP_API_URL}get-pathologist-options?${queryParams.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setMEOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  const fetchFuneralHomeOptions = async () => {
    setFuneralHomeOptionsLoading(true);
    await fetch(REACT_APP_API_URL + 'getfuneralhomeoptions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setFuneralHomeOptions(data);
      })
      .catch(e => {
        alert(e);
      })
      .finally(() => {
        setFuneralHomeOptionsLoading(false);
      });
  };

  const fetchTransporterOptions = async () => {
    const qryParams = {
      CaseSeq: formik.values?.caseSummary.caseSeq,
    };

    const url = `${REACT_APP_API_URL}gettransporteroptions?${new URLSearchParams(qryParams)}`;

    await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setTransporterOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  const fetchStorageLocOptions = async () => {
    setStorageLocOptionsLoading(true);
    const qryParams = {
      jdxSeq: formik.values?.caseSummary.jdxSeq,
      UserSeq: user.userSeq,
    };

    const url = `${REACT_APP_API_URL}getstoragelocoptions?${new URLSearchParams(qryParams)}`;

    await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured in fetching local storage options');
        }
        return res.json();
      })
      .then(data => {
        setStorageLocOptions(data);
      })
      .catch(e => {
        alert(e);
      })
      .finally(() => {
        setStorageLocOptionsLoading(false);
      });
  };

  const fetchMorgueTechOptions = async () => {
    const queryParams = new URLSearchParams();
    if (formik.values?.caseSummary?.jdxSeq) {
      queryParams.append('jdxList', formik.values?.caseSummary?.jdxSeq);
    }

    await fetch(`${REACT_APP_API_URL}get-mortuarytech-options?${queryParams.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setMorgueTechOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        fetchExamTypeOptions();
        fetchMEs();
        fetchTransporterOptions();
        fetchStorageLocOptions();
        fetchMorgueTechOptions();
        fetchLabelSetOptions();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (activeTab === 5) {
      fetchFuneralHomeOptions();
      const combinedOptions = [...meOptions, ...morgueTechOptions];
      setMeMTOptions(combinedOptions);
    }
  }, [activeTab]);

  useEffect(() => {
    if (formik.values.caseMortuary?.authorizingRelease) {
      const authorizingRelease = informantOptions?.find(
        option =>
          option.personSeq?.toLowerCase() ===
          formik.values?.caseMortuary?.authorizingRelease?.toLowerCase()
      );
      setAuthorizingReleaseRelship(authorizingRelease?.relship || null);
      setAuthorizingReleaseContact({
        contactItemDetails: authorizingRelease?.contactNumber || null,
      });
    } else {
      setAuthorizingReleaseRelship(null);
      setAuthorizingReleaseContact(null);
    }
  }, [formik.values.caseMortuary?.authorizingRelease]);

  // Create a case_summary obj
  const case_summary = {
    case_information: {
      assigned_investigator: getValueOrDefault(
        formik.values?.caseSummary.caseInformation?.assignedInvestigator
      ),
      cause_of_death: getValueOrDefault(formik.values?.caseSummary.caseInformation?.causeOfDeath),
      check_out_authorized_by: getValueOrDefault(
        formik.values?.caseSummary.caseInformation?.checkoutAuthorizedBy
      ),
      check_out_status: undefined,
      check_out_to: getValueOrDefault(formik.values?.caseSummary.caseInformation?.checkoutTo),
      edrs_case_number: getValueOrDefault(
        formik.values?.caseSummary.caseInformation?.edrsCaseNumber
      ),
      exam_date_and_time: getValueOrDefault(
        formik.values?.caseSummary.caseInformation?.examEndDateTime
      ),
      identification_status: getValueOrDefault(
        formik.values?.caseSummary.caseInformation?.idStatus
      ),
      lab_number: getValueOrDefault(formik.values?.caseSummary.caseInformation?.labNumber),
      manner_of_death: getValueOrDefault(formik.values?.caseSummary.caseInformation?.mannerOfDeath),
      ME_action: getValueOrDefault(formik.values?.caseSummary.caseInformation?.meAction),
      physician: getValueOrDefault(formik.values?.caseMortuary?.assignedUser?.optionInitials),
      physician_to_whom_case_was_released: getValueOrDefault(
        formik.values?.caseSummary?.caseInformation.caseReleasePhysician
      ),
      pronouncing_date_and_time: getValueOrDefault(
        formik.values?.caseSummary.caseInformation?.pronouncedDateTime
      ),
      pronouncing_physician: getValueOrDefault(
        formik.values?.caseSummary.caseInformation?.pronouncingPhysician
      ),
      rime_status: getValueOrDefault(formik.values?.caseSummary.caseInformation?.pathologyStatus),
      tissue_number: getValueOrDefault(formik.values?.caseMortuary?.examLabel),
      type_of_exam: getValueOrDefault(formik.values?.caseSummary.caseInformation?.typeOfExam),
      physician_on_call_at_time_of_case_intake: undefined,
    },
    case_number: getValueOrDefault(formik.values?.caseSummary?.caseNumber),
    case_synopsis: getValueOrDefault(formik.values?.caseSummary?.caseSynopsis),
    decedent_information: {
      decedent_name: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.decedentName),
      aka: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.aka),
    },
    demographic_information: {
      age: formik.values?.caseSummary.decedentInfo?.age
        ? formik.values?.caseSummary.decedentInfo.age +
          ' ' +
          formik.values?.caseSummary.decedentInfo?.ageUnit?.toLowerCase()
        : undefined,
      birth_date: formik.values?.caseSummary.decedentInfo?.birthTime
        ? new Date(formik.values?.caseSummary.decedentInfo?.birthTime).toLocaleDateString()
        : undefined,
      birth_time: formik.values?.caseSummary.decedentInfo?.birthTime
        ? new Date(formik.values?.caseSummary.decedentInfo.birthTime).toLocaleDateString()
        : undefined,
      ethnicity_or_race: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.ethnicityRace),
      gender: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.gender),
      marital_status: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.maritalStatus),
      occupation: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.occupation),
      private_physician: getValueOrDefault(
        formik.values?.caseSummary.decedentInfo?.privatePhysician
      ),
      ssn: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.ssn),
    },
    jurisdiction: getValueOrDefault(formik.values?.caseSummary?.caseJdx),
    name: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.decedentName),
    residence: {
      address: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.residence?.addressLine1),
      apt_suite: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.residence?.aptSuite),
      city_or_town: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.residence?.cityTown),
      country: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.residence?.country),
      floor: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.residence?.floor),
      state: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.residence?.state),
      zip: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.residence?.zipCode),
    },
    requesting_user: `${user?.userLastName} ${user?.userFirstName}`,
    morgue_techs: Array.isArray(formik.values?.caseMortuary?.morgueTech)
      ? formik.values?.caseMortuary?.morgueTech.map(m => m.optionInitials)
      : [],
  };

  const propCurrency = formik.values?.caseMortuary?.propertyInventory?.propertyCurrency;
  const propClothing = formik.values?.caseMortuary?.propertyInventory?.propertyClothing;
  const propDocument = formik.values?.caseMortuary?.propertyInventory?.propertyDocument;
  const propEvidence = formik.values?.caseMortuary?.propertyInventory?.propertyEvidence;
  const propJewelry = formik.values?.caseMortuary?.propertyInventory?.propertyJewelry;
  const propOther = formik.values?.caseMortuary?.propertyInventory?.otherPI;

  const currencyList = propCurrency?.map(currency => ({
    country_of_origin: currency.countryOfOrigin?.optionName,
    denomination: currency.denomination?.optionName,
    funeral_home: currency.funeralHome,
    notes: currency.note,
    number: currency.number,
    released_to_LE: currency.releasedtoLE,
    retained: currency.retained,
  }));

  const clothingList = propClothing?.map(clothing => ({
    clothing_item: clothing.clothingItem?.optionName,
    color: clothing.color?.optionName,
    number: clothing.number,
    style: clothing.styleType,
    size: clothing.size,
    markings: clothing.markings,
    funeral_home: clothing.funeralHome,
    notes: clothing.note,
    released_to_LE: clothing.releasedtoLE,
    retained: clothing.retained,
  }));

  const documentList = propDocument?.map(document => ({
    document_item: document.documentItem?.optionName,
    number: document.number,
    description: document.description,
    funeral_home: document.funeralHome,
    notes: document.note,
    released_to_LE: document.releasedtoLE,
    retained: document.retained,
  }));

  const evidenceList = propEvidence?.map(evidence => ({
    evidence_item: evidence.evidenceItem?.optionName,
    number: evidence.number,
    description: evidence.description,
    funeral_home: evidence.funeralHome,
    notes: evidence.note,
    released_to_LE: evidence.releasedtoLE,
    retained: evidence.retained,
  }));

  const jewelryList = propJewelry?.map(jewelry => ({
    jewelry_item: jewelry.jewelryItem?.optionName,
    number: jewelry.number,
    description: jewelry.description,
    location: jewelry.location,
    funeral_home: jewelry.funeralHome,
    notes: jewelry.note,
    released_to_LE: jewelry.releasedtoLE,
    retained: jewelry.retained,
  }));

  const otherPIList = propOther?.map(other => ({
    personal_item: other.personalItem?.optionName,
    other_personal_item: other.otherPropInventory,
    number: other.number,
    description: other.description,
    funeral_home: other.funeralHome,
    notes: other.note,
    released_to_LE: other.releasedtoLE,
    retained: other.retained,
  }));

  const PI_Summary = {
    case_number: getValueOrDefault(formik.values?.caseSummary?.caseNumber),
    name: getValueOrDefault(formik.values?.caseSummary.decedentInfo?.decedentName),
    jurisdiction: getValueOrDefault(formik.values?.caseSummary?.caseJdx),
    currency: currencyList,
    clothing: clothingList,
    document: documentList,
    evidence: evidenceList,
    jewelry: jewelryList,
    other: otherPIList,
  };

  const [checkInDateError, setcheckInDateError] = useState(null);
  const [examStartDateError, setExamStartDateError] = useState(null);
  const [examEndDateError, setExamEndDateError] = useState(null);

  const checkoutfailedConditions = [];

  if (
    !formik.values?.caseDemographics?.identifications?.some(
      identification => identification.isAccept === true
    )
  ) {
    checkoutfailedConditions.push(messages.checkoutConditions.idNotVerified);
  }

  if (formik.values?.caseMortuary?.isHold === true) {
    checkoutfailedConditions.push(messages.checkoutConditions.onHoldStatus);
  }

  if (
    !formik.values.caseMortuary?.examEndDateTime &&
    formik.values.caseInvestigations?.meAction?.optionSeq?.toUpperCase() === ME_Action.Accepted
  ) {
    checkoutfailedConditions.push(messages.checkoutConditions.examEndDateRequired);
  }

  if (
    !formik.values.caseMortuary?.checkInDate &&
    formik.values.caseInvestigations?.meAction?.optionSeq?.toUpperCase() === ME_Action.StorageOnly
  ) {
    checkoutfailedConditions.push(messages.checkoutConditions.checkinDateRequired);
  }

  const isCheckOutDisabled = checkoutfailedConditions.length > 0;

  const currentLiveryUserSeq = formik.values?.caseInvestigations?.liveryUser?.optionSeq;

  const targetAuthorizingReleaseSeq =
    formik.values?.caseMortuary?.authorizingReleaseType === 3
      ? authorizingRelease_Other
      : formik.values?.caseMortuary?.authorizingRelease;

  return (
    <form>
      {/* <ThemeProvider theme={theme}> */}
      <Alert color='danger' isOpen={showErrorAlert} toggle={dismissAlert}>
        Please select storage location.
      </Alert>
      {/* <Typography
        variant='subtitle1'
        color='gray'
        sx={{ fontSize: '.775rem' }} // Adjust the font size as needed
      >
        {' '}
        <strong>MORTUARY</strong>
      </Typography> */}

      {/* <MortuaryHUD /> */}

      <Grid container spacing={0}>
        <Grid item xs={12} lg={2}>
          <FormControlLabel
            disabled={!formik.status.editing || !authorizedToEdit}
            control={
              <Checkbox
                id='caseMortuary.isHold'
                name='caseMortuary.isHold'
                checked={formik.values?.caseMortuary?.isHold}
                value={formik.values?.caseMortuary?.isHold}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched?.caseMortuary?.isHold &&
                  Boolean(formik.errors?.caseMortuary?.isHold)
                }
                helperText={
                  formik.touched?.caseMortuary?.isHold && formik.errors?.caseMortuary?.isHold
                }
              />
            }
            label={
              <Typography variant='body1' style={{ fontSize: '0.775rem' }}>
                Hold body
              </Typography>
            }
          />
        </Grid>

        {formik.values?.caseMortuary?.isHold && (
          <>
            <FormikTextField
              gridProps={{ xs: 12, lg: 10 }}
              label={
                <Typography variant='body1' style={{ fontSize: '0.775rem' }}>
                  Reason for the Hold
                </Typography>
              }
              formikField='caseMortuary.holdReason'
              authorizedToEdit={r => [
                r.Medical_Examiner,
                r.Systems_Admin,
                r.Investigator,
                r.Historicdataentryonly,
                r.Historic_Dataentryonly,
                r.Administrative_Assistant,
              ]}
            />
          </>
        )}

        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, pb: 0 }} />
        </Grid>
      </Grid>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{
          marginBottom: 2,
          position: 'sticky',
          top: 0,
          zIndex: 1001,
          backgroundColor: theme.palette.background.default,
        }}
        variant='scrollable'
        scrollButtons='auto'
        allowScrollButtonsMobile
      >
        <Tab label='CHECK-IN' sx={{ minWidth: 'auto', padding: '0 8px' }} />
        <Tab label='PRE-EXAM' sx={{ minWidth: 'auto', padding: '0 8px' }} />
        <Tab label='X-RAY' sx={{ minWidth: 'auto', padding: '0 8px' }} />
        <Tab label='LAB TESTING' sx={{ minWidth: 'auto', padding: '0 8px' }} />
        <Tab label='POST-EXAM' sx={{ minWidth: 'auto', padding: '0 8px' }} />
        <Tab label='CHECKOUT' sx={{ minWidth: 'auto', padding: '0 8px' }} />
        <Tab label='DOCUMENTS' sx={{ minWidth: 'auto', padding: '0 8px' }} />
      </Tabs>

      <Box hidden={activeTab !== 0} paddingTop={3}>
        <Grid container spacing={2} item xs={12} md={12}>
          <CaseViewDateTimeField
            xs={12}
            md={4}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseMortuary.checkInDate'
            label='Check-In Date'
          />
          <Grid item xs={12} md={4}>
            <CaseViewOptionsAutocomplete
              formikField='caseMortuary.transporter1'
              optionsEndpoint={`gettransporteroptionsforliveryserviceuser?liveryUserSeq=${currentLiveryUserSeq}`}
              label='Transporter'
              onlyUseOptionSeq={true}
              mode='single'
            />
            {/* <Autocomplete
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.transported1_by_id'
              name='caseMortuary.transported1_by'
              options={transporterOptions}
              value={
                formik.values?.caseMortuary != null
                  ? transporterOptions?.find(
                      option => option.optionSeq === formik.values?.caseMortuary.transporter1
                    ) || null
                  : null
              }
              onChange={(e, value) =>
                formik.setFieldValue('caseMortuary.transporter1', value?.optionSeq || null)
              }
              error={
                formik.touched?.caseMortuary?.transporter1 &&
                Boolean(formik.errors?.caseMortuary?.transporter1)
              }
              helperText={
                formik.touched?.caseMortuary?.transporter1 &&
                formik.errors?.caseMortuary?.transporter1
              }
              getOptionLabel={option => option.optionName}
              renderInput={params => <TextField {...params} label='Transporter' />}
            /> */}
          </Grid>

          {/* <Grid item xs={12} md={4}>
            <Autocomplete
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.transported2_by_id'
              name='caseMortuary.transported2_by'
              options={transporterOptions}
              value={
                formik.values?.caseMortuary != null
                  ? transporterOptions?.find(
                    option => option.optionSeq === formik.values?.caseMortuary.transporter2
                  ) || null
                  : null
              }
              onChange={(e, value) =>
                formik.setFieldValue('caseMortuary.transporter2', value?.optionSeq || null)
              }
              error={formik.touched?.caseMortuary?.transporter2 && Boolean(formik.errors?.caseMortuary?.transporter2)}
              helperText={formik.touched?.caseMortuary?.transporter2 && formik.errors?.caseMortuary?.transporter2}
              getOptionLabel={option => option.optionName}
              renderInput={params => <TextField {...params} label='Transporter #2' />}
            />
          </Grid> */}
          <Grid item xs={12} md={4}>
            <TextField
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.recoverySealNo'
              name='caseMortuary.recoverySealNo'
              placeholder='Recovery Seal No.'
              label='Recovery Seal No.'
              type='text'
              fullWidth
              variant='outlined'
              value={
                formik.values?.caseMortuary != null
                  ? formik.values?.caseMortuary?.recoverySealNo
                  : null
              }
              onChange={formik.handleChange}
              error={
                formik.touched?.caseMortuary?.recoverySealNo &&
                Boolean(formik.errors?.caseMortuary?.recoverySealNo)
              }
              helperText={
                formik.touched?.caseMortuary?.recoverySealNo &&
                formik.errors?.caseMortuary?.recoverySealNo
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.storage_location_id'
              name='caseMortuary.storage_location'
              options={storageLocOptions}
              value={
                formik.values?.caseMortuary != null
                  ? storageLocOptions?.find(
                      option => option.optionSeq === formik.values?.caseMortuary.storageLocation
                    ) || null
                  : null
              }
              onChange={(e, value) =>
                formik.setFieldValue('caseMortuary.storageLocation', value?.optionSeq || null)
              }
              error={
                formik.touched?.caseMortuary?.storageLocation &&
                Boolean(formik.errors?.caseMortuary?.storageLocation)
              }
              helperText={
                formik.touched?.caseMortuary?.storageLocation &&
                formik.errors?.caseMortuary?.storageLocation
              }
              getOptionLabel={option => option.optionName}
              renderInput={params => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {storageLocOptionsLoading ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  label='Storage Location'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.origRecoverySealNo'
              name='caseMortuary.origRecoverySealNo'
              placeholder='Original Recovery Seal'
              label='Original Recovery Seal'
              type='text'
              fullWidth
              value={
                formik.values?.caseMortuary != null
                  ? formik.values?.caseMortuary?.origRecoverySealNo
                  : null
              }
              onChange={formik.handleChange}
              error={
                formik.touched?.caseMortuary?.origRecoverySealNo &&
                Boolean(formik.errors?.caseMortuary?.origRecoverySealNo)
              }
              helperText={
                formik.touched?.caseMortuary?.origRecoverySealNo &&
                formik.errors?.caseMortuary?.origRecoverySealNo
              }
            />
          </Grid>

          {/* <Grid item xs={12} md={3}>
            <TextField
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.rfid_tag_id'
              name='caseMortuary.rfid_tag'
              placeholder='RFID Tag'
              label='RFID Tag'
              type='text'
              fullWidth
            />
          </Grid> */}

          <Grid item xs={12} md={3}>
            <FormControl component='fieldset'>
              <FormLabel>Is The Recovery Seal Intact? </FormLabel>
              <RadioGroup
                name='caseMortuary.recoverySealIntact'
                value={formik.values?.caseMortuary?.recoverySealIntact ?? null}
                onChange={formik.handleChange}
                row
              >
                <FormControlLabel
                  disabled={!formik.status.editing || !authorizedToEdit}
                  value={true}
                  control={<Radio />}
                  label='Yes'
                />
                <FormControlLabel
                  disabled={!formik.status.editing || !authorizedToEdit}
                  value={false}
                  control={<Radio />}
                  label='No'
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} item xs={12} md={12}>
          <Grid item xs={12} md={4}>
            <IconButton
              onClick={onAddClick}
              disabled={!formik.status.editing || !authorizedToEdit}
              style={{ color: '#0288d1' }}
              disableRipple
            >
              <Grid container alignItems='center' spacing={1}>
                <Grid item>
                  <AddIcon />
                </Grid>
                <Grid item>
                  <Typography variant='body2'>Add New Storage Location</Typography>
                </Grid>
              </Grid>
            </IconButton>
          </Grid>
        </Grid>
        {formik.values?.caseMortuary.caseOnStorageLocation ? (
          <>
            <Box
              sx={{
                position: 'relative',
                paddingTop: '1rem',
                width: '100%',
                height: '100%',
              }}
            >
              <Grid item xs={12} md={12}>
                <CondensedDataGrid
                  listresults={
                    Array.isArray(formik.values?.caseMortuary.caseOnStorageLocation)
                      ? formik.values?.caseMortuary.caseOnStorageLocation
                      : []
                  }
                  columnsInput={columns}
                  idcolumn='caseStorageLocationSeq'
                  tooltip='Storage Location History'
                  gridheight='auto'
                  gridcolor='#4b21a6'
                  rowsperpage={5}
                />
              </Grid>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>

      <Box hidden={activeTab !== 1} paddingTop={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Autocomplete
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.assigned_me_id'
              name='caseMortuary.assigned_me'
              options={meOptions}
              value={
                formik.values?.caseMortuary !== null
                  ? formik.values?.caseMortuary.assignedUser || null
                  : null
              }
              onChange={(e, value) =>
                formik.setFieldValue('caseMortuary.assignedUser', value || null)
              }
              error={
                formik.touched?.caseMortuary?.assignedUser &&
                Boolean(formik.errors?.caseMortuary?.assignedUser)
              }
              helperText={
                formik.touched?.caseMortuary?.assignedUser &&
                formik.errors?.caseMortuary?.assignedUser
              }
              getOptionLabel={option => option.optionName}
              renderInput={params => <TextField {...params} label='Pathologist' />}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Autocomplete
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.exam_type_id'
              name='caseMortuary.exam_type'
              options={examtypeOptions}
              value={
                formik.values?.caseMortuary !== null
                  ? // examtypeOptions?.find(
                    //     option => option.optionSeq === formik.values?.caseMortuary.examtype
                    //   ) || null
                    formik.values?.caseMortuary?.examtype
                  : null
              }
              onChange={(e, value) => formik.setFieldValue('caseMortuary.examtype', value)}
              error={
                formik.touched?.caseMortuary?.examtype &&
                Boolean(formik.errors?.caseMortuary?.examtype)
              }
              helperText={
                formik.touched?.caseMortuary?.examtype && formik.errors?.caseMortuary?.examtype
              }
              getOptionLabel={option => option.optionName}
              renderInput={params => <TextField {...params} label='Exam type' />}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Autocomplete
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.morgueTech'
              name='caseMortuary.morgueTech'
              renderTags={(value, getTagProps) => {
                // Check if there are any selected values
                if (value.length > 0) {
                  // Display the first value and "...", if there are more than one
                  return [`${value[0].optionName}${value.length > 1 ? ', ...' : ''}`];
                }
                return [];
              }}
              multiple
              options={sortOptions(
                morgueTechOptions || [],
                formik.values?.caseMortuary?.morgueTech || []
              )}
              value={
                formik.values?.caseMortuary?.morgueTech?.filter(option => option.isActive) || []
              }
              getOptionSelected={(option, value) => option.optionSeq === value.optionSeq}
              onChange={(event, newValue) => {
                formik.setFieldValue('caseMortuary.morgueTech', newValue);
              }}
              renderOption={(props, option) => {
                const handleToggle = option => {
                  // Determine if the clicked option is currently active
                  const isExist = formik.values?.caseMortuary?.morgueTech?.some(
                    selectedOption => selectedOption.optionSeq === option.optionSeq
                  );

                  // If the option is currently active, we want to deactivate it
                  if (isExist) {
                    formik.setFieldValue(
                      'caseMortuary.morgueTech',
                      formik.values?.caseMortuary?.morgueTech?.map(selectedOption =>
                        selectedOption.optionSeq === option.optionSeq
                          ? { ...selectedOption, isActive: !selectedOption.isActive }
                          : selectedOption
                      )
                    );
                  } else {
                    // If the option is not active, we want to activate it and add it to the list
                    formik.setFieldValue('caseMortuary.morgueTech', [
                      ...(formik.values?.caseMortuary?.morgueTech || []),
                      { ...option, isActive: true },
                    ]);
                  }
                };

                // Check if the option is active
                const isSelected = formik.values?.caseMortuary?.morgueTech?.some(
                  selectedOption =>
                    selectedOption.optionSeq === option.optionSeq &&
                    selectedOption.isActive === true
                );

                return (
                  <li {...props} onClick={() => handleToggle(option)}>
                    <Checkbox
                      icon={<MdCheckBoxOutlineBlank />}
                      checkedIcon={<MdOutlineCheckBox />}
                      style={{ marginRight: 8 }}
                      checked={isSelected}
                      onChange={event => event.stopPropagation()}
                    />
                    {option.optionName}
                  </li>
                );
              }}
              error={
                formik.touched?.caseMortuary?.morgueTech &&
                Boolean(formik.errors?.caseMortuary?.morgueTech)
              }
              helperText={
                formik.touched?.caseMortuary?.morgueTech && formik.errors?.caseMortuary?.morgueTech
              }
              getOptionLabel={option => option.optionName}
              renderInput={params => <TextField {...params} label='Forensic Techs' />}
              ListboxComponent='ul'
            />
          </Grid>
          <CaseViewDateTimeField
            xs={12}
            md={3}
            authorizedToEdit={authorizedToEdit && formik.values.caseMortuary?.checkInDate}
            fieldName='caseMortuary.examStartDateTime'
            label='Exam Started'
            error={
              !formik.values.caseMortuary?.checkInDate ||
              validateDateTimes(
                formik.values.caseMortuary?.checkInDate,
                formik.values.caseMortuary?.examStartDateTime
              )
            }
            helperText={
              !formik.values.caseMortuary?.checkInDate
                ? messages.checkinDateRequired
                : validateDateTimes(
                    formik.values.caseMortuary?.checkInDate,
                    formik.values.caseMortuary?.examStartDateTime
                  )
                ? messages.examStartDateBeforeCheckinDate.replace(
                    '{checkInDate}',
                    format(new Date(formik.values.caseMortuary?.checkInDate), 'MM/dd/yyyy, HH:mm')
                  )
                : ''
            }
          />
          {/* Toggle Option for Exam Labels */}
          <Grid item xs={12} md={2}>
            <Autocomplete
              disabled={!authorizedToEdit}
              id='caseMortuary.examLabel_id'
              name='caseMortuary.examLabel'
              options={filteredLabelSetOptions}
              value={
                formik.values?.caseMortuary?.examLabel !== undefined &&
                formik.values?.caseMortuary?.examLabel !== null
                  ? filteredLabelSetOptions.find(
                      option => option.optionSeq === formik.values.caseMortuary.examLabel
                    ) || null
                  : null
              }
              onChange={(e, value) => {
                setSelectedLabel(value?.optionName ?? '');
                formik.setFieldValue('caseMortuary.examLabel', value?.optionSeq || null);
              }}
              error={
                formik.touched?.caseMortuary?.examLabel &&
                Boolean(formik.errors?.caseMortuary?.examLabel)
              }
              helperText={
                formik.touched?.caseMortuary?.examLabel && formik.errors?.caseMortuary?.examLabel
              }
              getOptionLabel={option => option.optionName}
              renderInput={params => <TextField {...params} label='Type of Label' />}
            />
          </Grid>
          {/* Print label Button */}
          <Grid item xs={12} md={1}>
            <PrintLabelSetButton
              caseSummary={case_summary}
              selectedLabel={selectedLabel}
            ></PrintLabelSetButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        </Grid>
      </Box>

      <Box hidden={activeTab !== 2} paddingTop={3}>
        <XrayRequestFormView />
      </Box>

      <Box hidden={activeTab !== 3} paddingTop={3}>
        {/* <RequestedProcedures /> */}

        <Accordion
          expanded={requestedProceduresExpanded}
          onChange={handleRequestedProceduresExpansion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>REQUESTED PROCEDURES</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RequestedProcedures />
          </AccordionDetails>
        </Accordion>
        <br />
        <LabTestingView />
      </Box>

      <Box hidden={activeTab !== 4} paddingTop={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <FormControl component='fieldset'>
              <RadioGroup
                name='caseMortuary.isExamCompleted'
                value={
                  formik.values?.caseMortuary != null
                    ? formik.values?.caseMortuary?.isExamCompleted
                    : null
                }
                onChange={e => {
                  const newValue = e.target.value === 'true';
                  formik.setFieldValue('caseMortuary.isExamCompleted', newValue);

                  if (!newValue) {
                    formik.setFieldValue('caseMortuary.examEndDateTime', null);
                  }
                }}
                row
              >
                <FormControlLabel
                  disabled={!formik.status.editing || !authorizedToEdit}
                  value={false}
                  control={<Radio />}
                  label={
                    <Typography variant='body1' style={{ fontSize: '0.775rem' }}>
                      Exam Not Completed
                    </Typography>
                  }
                />
                <FormControlLabel
                  disabled={!formik.status.editing || !authorizedToEdit}
                  value={true}
                  control={<Radio />}
                  label={
                    <Typography variant='body1' style={{ fontSize: '0.775rem' }}>
                      Exam Completed
                    </Typography>
                  }
                />
              </RadioGroup>
              <br />
              {(formik.values?.caseMortuary.isExamCompleted === true ||
                formik.values?.caseMortuary.isExamCompleted === 'true') && (
                <CaseViewDateTimeField
                  xs={12}
                  authorizedToEdit={
                    authorizedToEdit && formik.values.caseMortuary?.examStartDateTime
                  }
                  fieldName='caseMortuary.examEndDateTime'
                  label='Exam Completed On'
                  error={
                    !formik.values.caseMortuary?.examStartDateTime ||
                    validateDateTimes(
                      formik.values.caseMortuary?.examStartDateTime,
                      formik.values.caseMortuary?.examEndDateTime
                    )
                  }
                  helperText={
                    !formik.values.caseMortuary?.examStartDateTime
                      ? messages.examStartDateRequired
                      : validateDateTimes(
                          formik.values.caseMortuary?.examStartDateTime,
                          formik.values.caseMortuary?.examEndDateTime
                        )
                      ? messages.examEndDateBeforeStartDate.replace(
                          '{examStartDate}',
                          format(
                            new Date(formik.values.caseMortuary?.examStartDateTime),
                            'MM/dd/yyyy, HH:mm'
                          )
                        )
                      : ''
                  }
                />
              )}
              <br />
            </FormControl>
            <br />
            <Grid item xs={12} md={8}>
              <Autocomplete
                disabled={!formik.status.editing || !authorizedToEdit}
                id='caseMortuary.storage_location_id'
                name='caseMortuary.storage_location'
                options={storageLocOptions}
                value={
                  formik.values?.caseMortuary != null
                    ? storageLocOptions?.find(
                        option => option.optionSeq === formik.values?.caseMortuary?.storageLocation
                      ) || null
                    : null
                }
                onChange={(e, value) =>
                  formik.setFieldValue('caseMortuary.storageLocation', value?.optionSeq || null)
                }
                error={
                  formik.touched?.caseMortuary?.storageLocation &&
                  Boolean(formik.errors?.caseMortuary?.storageLocation)
                }
                helperText={
                  formik.touched?.caseMortuary?.storageLocation &&
                  formik.errors?.caseMortuary?.storageLocation
                }
                getOptionLabel={option => option.optionName}
                renderInput={params => <TextField {...params} label='Body Returned To' />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        hidden={activeTab !== 5}
        paddingTop={3}
        sx={{
          pointerEvents: isCheckOutDisabled ? 'none' : 'auto',
        }}
      >
        <Grid container spacing={2}>
          {isCheckOutDisabled && (
            <Grid item xs={12}>
              <Alert
                severity='error'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  backgroundColor: '#f44336',
                  color: '#ffffff',
                }}
              >
                <Typography variant='body1' style={{ fontSize: '0.875rem', color: 'darkred' }}>
                  <b>Checkout is disabled:</b> {checkoutfailedConditions.join(', and ')}
                </Typography>
              </Alert>
            </Grid>
          )}

          <Grid item xs={12} md={12}>
            <Typography
              variant='body1'
              style={{
                fontWeight: 'bold',
                fontSize: '.775rem',
                color: 'darkred',
              }}
            >
              {formik.values?.caseMortuary?.funeralHome &&
              formik.values?.caseMortuary?.funeralHome?.optionSeq !== null
                ? `Checked Out by: ${formik.values?.caseMortuary?.releasedBy}`
                : 'Body has not been checked out'}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.funeralHome'
              name='caseMortuary.funeralHome'
              options={funeralHomeOptions}
              value={formik.values?.caseMortuary.funeralHome || null}
              onChange={(e, value) => {
                formik.setFieldValue('caseMortuary.funeralHome', value || null);
                formik.setFieldValue(
                  'caseMortuary.releasedBy',
                  formik.values?.caseMortuary?.releasedBy ||
                    `${user?.userLastName || ''}${
                      user?.userLastName && user?.userFirstName ? ', ' : ''
                    }${user?.userFirstName || ''}`
                );
              }}
              getOptionLabel={option => option.optionName}
              isOptionEqualToValue={(o, v) =>
                o.optionSeq.toLowerCase() === v.optionSeq.toLowerCase()
              }
              renderInput={params => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {funeralHomeOptionsLoading ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  label='Funeral Home Name:'
                />
              )}
            />
          </Grid>

          <FormikTextField
            gridProps={{ xs: 12, md: 2 }}
            authorizedToEdit={authorizedToEdit}
            formikField='caseMortuary.signedBy'
            label='Funeral Home Representative'
          />
          {/* <Grid item xs={12} md={2}>
            <TextField
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.signedBy'
              name='caseMortuary.signedBy'
              placeholder='Signed'
              label='Funeral Home Representative'
              type='text'
              fullWidth
              value={
                formik.values?.caseMortuary != null ? formik.values?.caseMortuary.signedBy : null
              }
              onChange={formik.handleChange}
              error={
                formik.touched?.caseMortuary?.signedBy &&
                Boolean(formik.errors?.caseMortuary?.signedBy)
              }
              helperText={
                formik.touched?.caseMortuary?.signedBy && formik.errors?.caseMortuary?.signedBy
              }
            />
          </Grid> */}
          {/* <Grid item xs={12} md={4}>
            <Autocomplete
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.valuables_releasedby_id'
              name='caseMortuary.valuabled_released by'
              options={meMTOptions}
              value={
                formik.values?.caseMortuary != null
                  ? meMTOptions?.find(
                      option =>
                        option.optionSeq === formik.values?.caseMortuary.valuablesReleasedUserSeq
                    ) || null
                  : null
              }
              onChange={(e, value) =>
                formik.setFieldValue(
                  'caseMortuary.valuablesReleasedUserSeq',
                  value?.optionSeq || null
                )
              }
              error={
                formik.touched?.caseMortuary?.valuablesReleasedUserSeq &&
                Boolean(formik.errors?.caseMortuary?.valuablesReleasedUserSeq)
              }
              helperText={
                formik.touched?.caseMortuary?.valuablesReleasedUserSeq &&
                formik.errors?.caseMortuary?.valuablesReleasedUserSeq
              }
              getOptionLabel={option => option.optionName}
              renderInput={params => <TextField {...params} label='Valuables Released By' />}
            />
          </Grid> */}

          <FormikTextField
            gridProps={{ xs: 12, md: 2 }}
            authorizedToEdit={authorizedToEdit}
            formikField='caseMortuary.witnessedBy'
            label='Witnessed By'
          />

          <FormikTextField
            gridProps={{ xs: 12, md: 2 }}
            authorizedToEdit={authorizedToEdit}
            formikField='caseMortuary.confirmedBy'
            label='Funeral Home Confirmed By'
          />
          <CaseViewDateTimeField
            xs={12}
            md={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseMortuary.bodyReleaseDateTime'
            label='Date/Time Released '
          />
          <Grid item xs={12} md={2}>
            <Autocomplete
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseMortuary.authorizingRelease'
              name='caseMortuary.authorizingRelease'
              options={informantOptions || []}
              value={
                formik.values?.caseMortuary != null
                  ? informantOptions?.find(
                      option =>
                        option.personSeq?.toLowerCase() ===
                        targetAuthorizingReleaseSeq?.toLowerCase()
                    ) || null
                  : null
              }
              onChange={(e, value) => {
                if (value?.personSeq === authorizingRelease_Other) {
                  formik.setFieldValue('caseMortuary.authorizingReleaseType', 3);
                  formik.setFieldValue('caseMortuary.authorizingRelease', null);
                } else {
                  formik.setFieldValue('caseMortuary.authorizingReleaseType', 1);
                  formik.setFieldValue('caseMortuary.authorizingReleaseOther', '');
                  formik.setFieldValue('caseMortuary.authorizingRelease', value?.personSeq || null);
                }
              }}
              getOptionLabel={option =>
                `${option.personLastName || ''}${
                  option.personLastName && option.personFirstName ? ', ' : ''
                }${option.personFirstName || ''}`
              }
              renderOption={(props, option) => (
                <li {...props}>
                  {`${option.personLastName || ''}${
                    option.personLastName && option.personFirstName ? ', ' : ''
                  }${option.personFirstName || ''}${option.relship ? ` (${option.relship})` : ''}`}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Authorizing the Release'
                  helperText={
                    (authorizingReleaseRelship ||
                      authorizingReleaseContact?.contactItemDetails) && (
                      <Stack color='text.secondary'>
                        {authorizingReleaseRelship && (
                          <div>{`Relationship to Decedent: ${authorizingReleaseRelship}`}</div>
                        )}
                        {authorizingReleaseContact?.contactItemDetails && (
                          <div>{`Contact: ${authorizingReleaseContact.contactItemDetails}`}</div>
                        )}
                      </Stack>
                    )
                  }
                />
              )}
            />
          </Grid>
          {formik.values?.caseMortuary?.authorizingReleaseType === 3 && (
            <>
              <FormikTextField
                gridProps={{ xs: 12, md: 2 }}
                label='Other Person'
                formikField='caseMortuary.authorizingReleaseOther'
                authorizedToEdit={r => [
                  r.Medical_Examiner,
                  r.Systems_Admin,
                  r.Investigator,
                  r.Historicdataentryonly,
                  r.Historic_Dataentryonly,
                  r.Administrative_Assistant,
                ]}
              />
            </>
          )}
          <br />
        </Grid>
      </Box>

      <Box hidden={activeTab !== 5} paddingTop={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Button
              variant='contained'
              color='info'
              style={{ width: '100%' }}
              onClick={() => {
                handlePrintBodyReleaseForm();
              }}
              startIcon={<LaunchIcon />}
              disabled={isCheckOutDisabled}
            >
              BODY RELEASE FORM
            </Button>
          </Grid>

          <div id='pdf-container'></div>
          <Grid item xs={12} md={2}>
            <Button
              variant='contained'
              color='info'
              style={{ width: '100%' }}
              onClick={() => {
                handleGetSignatureForReceiptOfBody();
              }}
              startIcon={<LaunchIcon />}
              disabled={isCheckOutDisabled} // Disable the button if isCheckoutDisabled is true
            >
              {isCheckOutDisabled ? 'CHECKOUT DISABLED' : 'ACKNOWLEDGE RECEIPT OF BODY'}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box hidden={activeTab !== 1 && activeTab !== 4 && activeTab !== 5} paddingTop={3}>
        <Grid item xs={12} marginTop={'1rem'}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 2, pb: 0 }} />
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>
              <Typography variant='subtitle1' color='gray'>
                <strong>PROPERTY INVENTORY</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              {/* <PrintPropertyInventoryButton PISummary={PI_Summary}>
              Print Inventory
            </PrintPropertyInventoryButton> */}
              <PrintPropertyInventoryButton casedata={formik.values} />
            </Grid>
          </Grid>
          <br />
          <Accordion expanded={clothingExpanded} onChange={handleClothingExpansion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {propClothing?.length > 0 && (
                <Chip
                  label={propClothing.length}
                  size='small'
                  color='primary'
                  style={{ marginRight: 20 }}
                />
              )}

              <Typography>CLOTHING</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ClothingDataGrid />
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={currencyExpanded} onChange={handleCurrencyExpansion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {propCurrency?.length > 0 && (
                <Chip
                  label={propCurrency.length}
                  size='small'
                  color='primary'
                  style={{ marginRight: 20 }}
                />
              )}

              <Typography>CURRENCY </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CurrencyDataGrid />
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={documentsExpanded} onChange={handleDocumentsExpansion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {propDocument?.length > 0 && (
                <Chip
                  label={propDocument.length}
                  size='small'
                  color='primary'
                  style={{ marginRight: 20 }}
                />
              )}

              <Typography>DOCUMENTS </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DocumentsDataGrid />
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={evidenceExpanded} onChange={handleEvidenceExpansion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {propEvidence?.length > 0 && (
                <Chip
                  label={propEvidence.length}
                  size='small'
                  color='primary'
                  style={{ marginRight: 20 }}
                />
              )}

              <Typography>EVIDENCE </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <EvidenceDataGrid />
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={jewelryExpanded} onChange={handleJewelryExpansion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {propJewelry?.length > 0 && (
                <Chip
                  label={propJewelry.length}
                  size='small'
                  color='primary'
                  style={{ marginRight: 20 }}
                />
              )}

              <Typography>JEWELRY </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <JewelryDataGrid />
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={otherPIExpanded} onChange={handleOtherPIExpansion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {propOther?.length > 0 && (
                <Chip
                  label={propOther.length}
                  size='small'
                  color='primary'
                  style={{ marginRight: 20 }}
                />
              )}
              <Typography>OTHER PI </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OtherPIDataGrid />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Box>

      <Box hidden={activeTab !== 0 && activeTab !== 6} paddingTop={3}>
        <Typography variant='subtitle1' color='gray'>
          <strong>DOCUMENTS</strong>
        </Typography>
        <br />
        <CaseDocuments caseinfo={formik.values?.caseSummary} />
      </Box>

      <Divider sx={{ borderColor: '#555', m: 0, mt: 2, pb: 0 }} />
      <Typography variant='subtitle1' color='gray'>
        <strong>CASE NOTES</strong>
      </Typography>
      <CaseNotes department={AssignmentTypes.Morgue} />

      <br />

      {isDialogOpen ? (
        <Dialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          PaperProps={{
            style: {
              width: '50%',
              margin: '0 auto',
            },
          }}
        >
          <DialogTitle>Add/Edit Storage Location</DialogTitle>

          <DialogContent>
            <div
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
                width: '80%',
              }}
            >
              <TextField
                label='Location Name'
                name='caseMortuary.priorLocation'
                type='text'
                size='small'
                disabled='true'
                value={editedRowData ? editedRowData.priorLocation : null}
                onChange={event => {
                  const { value } = event.target;
                  setEditedRowData(prevData => ({
                    ...prevData,
                    priorLocation: value,
                  }));
                }}
                error={
                  formik.touched?.caseMortuary?.priorLocation &&
                  Boolean(formik.errors?.caseMortuary?.priorLocation)
                }
                helperText={
                  formik.touched?.caseMortuary?.priorLocation &&
                  formik.errors?.caseMortuary?.priorLocation
                }
                fullWidth
              />
            </div>

            <div style={{ marginBottom: '1rem', width: '80%' }}>
              <TextField
                id='caseMortuary.from_datetime_id'
                name='caseMortuary.fromDate'
                placeholder='From Date'
                label='From Date'
                size='small'
                type='datetime-local'
                ampm={false}
                value={editedRowData ? editedRowData.fromDate : null}
                onChange={event => {
                  const { value } = event.target;
                  const parsedDate = new Date(value);
                  setEditedRowData(prevData => ({
                    ...prevData,
                    fromDate: formatToDatetimeLocal(parsedDate),
                  }));
                }}
                error={
                  formik.touched?.caseMortuary?.fromDate &&
                  Boolean(formik.errors?.caseMortuary?.fromDate)
                }
                helperText={
                  formik.touched?.caseMortuary?.fromDate && formik.errors?.caseMortuary?.fromDate
                }
                style={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            </div>
            <div style={{ marginBottom: '1rem', width: '80%' }}>
              <TextField
                id='caseMortuary.to_datetime_id'
                name='caseMortuary.toDate'
                placeholder='To Date'
                size='small'
                label='To Date'
                type='datetime-local'
                value={editedRowData ? editedRowData.toDate : null}
                onChange={event => {
                  const { value } = event.target;
                  const parsedDate = new Date(value);
                  setEditedRowData(prevData => ({
                    ...prevData,
                    toDate: formatToDatetimeLocal(parsedDate),
                  }));
                }}
                error={
                  formik.touched?.caseMortuary?.toDate &&
                  Boolean(formik.errors?.caseMortuary?.toDate)
                }
                helperText={
                  formik.touched?.caseMortuary?.toDate && formik.errors?.caseMortuary?.toDate
                }
                style={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            </div>

            <div style={{ marginBottom: '1rem', width: '80%' }}>
              <Autocomplete
                id='caseMortuary.checkin_UserSeq'
                name='caseMortuary.checkin_UserSeq'
                size='small'
                options={meOptions}
                value={
                  meOptions
                    ? meOptions?.find(option => option.optionName === editedRowData.checkinUser) ||
                      null
                    : null
                }
                onChange={(e, value) => {
                  setEditedRowData(prevData => ({
                    ...prevData,
                    checkinUserSeq: value.optionSeq,
                    checkinUser: value.optionName,
                  }));
                }}
                error={
                  formik.touched?.caseMortuary?.checkinUserSeq &&
                  Boolean(formik.errors?.caseMortuary?.checkinUserSeq)
                }
                helperText={
                  formik.touched?.caseMortuary?.checkinUserSeq &&
                  formik.errors?.caseMortuary?.checkinUserSeq
                }
                getOptionLabel={option => option.optionName}
                renderInput={params => <TextField {...params} label='Checked In By' />}
              />
            </div>

            <DialogActions>
              <Button
                type='submit'
                onClick={handleStorageLocationSubmit}
                style={{ color: '#fff', backgroundColor: '#1b6ec2' }}
              >
                Submit
              </Button>
              <Button onClick={handleDialogClose}>Cancel</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : (
        <></>
      )}

      {isSignatureDialogOpen && (
        <Dialog open={isSignatureDialogOpen} onClose={handleSignatureDialogClose}>
          <DialogTitle>Release Authorization</DialogTitle>

          <DialogContent>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <span>
                  I have verified the identification details and confirm that the body being
                  released is correctly identified. The recipient has provided the required
                  authorization for the removal and care of the body.
                </span>
              </div>

              <div
                style={{
                  marginTop: '2rem',
                  marginBottom: '1rem',
                  width: '80%',
                }}
              >
                <TextField
                  label='Checked Out/Released By'
                  name='caseMortuary.userName' /// this should be motified to use the logged in users name
                  type='text'
                  size='small'
                  disabled={true}
                  value={
                    formik.values?.caseMortuary != null
                      ? formik.values?.caseMortuary.releasedBy
                      : ''
                  }
                  // onChange={formik.handleChange}
                  error={
                    formik.touched?.caseMortuary?.userName &&
                    Boolean(formik.errors?.caseMortuary?.userName)
                  }
                  helperText={
                    formik.touched?.caseMortuary?.userName && formik.errors?.caseMortuary?.userName
                  }
                  fullWidth
                />
              </div>

              {/* Signature Area */}
              <div style={{ marginBottom: '1rem', width: '80%' }}>
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor='black'
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: 'sigCanvas',
                    style: { border: '1px solid #000' },
                  }}
                />
                <Button onClick={clearSignature} style={{ marginTop: '1rem' }}>
                  Clear Signature
                </Button>
              </div>

              <DialogActions>
                <Button
                  type='submit'
                  onClick={saveSignature}
                  style={{ color: '#fff', backgroundColor: '#1b6ec2' }}
                >
                  Ok
                </Button>
                <Button onClick={handleSignatureDialogClose}>Cancel</Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      )}
      {/* </ThemeProvider> */}
    </form>
  );
}
