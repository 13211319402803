import { Box, Drawer } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem, treeItemClasses, TreeItemProps } from '@mui/x-tree-view/TreeItem';
import { useState } from 'react';
import dimensions from '../../utils/dimensions';

// Define the CustomTreeItem with correct types
const CustomTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(({ theme }) => ({
  color: theme.palette.grey[200],
  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: '0.8rem',
      fontWeight: 500,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 10,
    paddingLeft: 13,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  ...theme.applyStyles('light', {
    color: theme.palette.grey[800],
  }),
}));

export default function TreeDrawer({
  drawerContents,
  setCurrentTab,
  currentTab,
  menuOpen,
  setMenuOpen,
  setTabContent = string => {},
}) {
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState(currentTab);

  const handleTabChange = (event, id) => {
    let newTab = '';

    if ((drawerContents?.findIndex(item => item?.id === id) ?? 0) !== -1) {
      if (drawerContents?.find(item => item?.id === id)?.children === undefined) {
        newTab = id;
      } else {
        newTab = drawerContents?.find(item => item?.id === id)?.children?.[0]?.id;
      }
    } else {
      newTab = id;
      setMenuOpen(false);
    }

    if (newTab === 'User Admin') {
      setTabContent('Users');
    } else if (newTab === 'Roles Admin') {
      setTabContent('Roles');
    } else {
      setTabContent(newTab);
    }

    setCurrentTab(newTab);
    setSelectedItem(newTab);
  };

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: theme.palette.background.default,
        borderRight: '1px solid rgba(120, 120, 120, 0.2)',
        pr: 1,
      }}
    >
      <Box
        sx={{
          width: 'max-content',
          height: '100%',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <RichTreeView
          sx={{ mt: 2 }}
          items={drawerContents}
          onItemClick={handleTabChange}
          selectedItems={selectedItem}
          slots={{ item: CustomTreeItem }}
        />
      </Box>

      <Drawer
        variant='temporary'
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 'max-content',
            position: 'relative',
            top: dimensions.app_bar_height,
            height: `calc(100% - ${dimensions.app_bar_height})`,
            borderRadius: 1.5,
            backgroundColor: theme.palette.background.default,
          },
          zIndex: theme.zIndex.drawer + 500,
        }}
      >
        <RichTreeView
          sx={{ mt: 2 }}
          items={drawerContents}
          onItemClick={handleTabChange}
          selectedItems={selectedItem}
          slots={{ item: CustomTreeItem }}
        />
      </Drawer>
    </Box>
  );
}
