import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  Box,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  IconButton,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../../services/utility-api';
import InputLabel from '@mui/material/InputLabel';
import AddressData from '../../../../../components/AdministrationModules/AddressData/AddressView';
import ContactData from '../../../../../components/AdministrationModules/ContactData/ContactView';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { referenceTypeFieldLengthChecks } from '../referenceTypeFieldLengthChecks';
import ApiNotification from '../../../../../components/DialogBox';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddEdit = ({
  rows,
  editObj,
  isOrder,
  isCounty,
  referenceMasterTableName,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countyNames,
  masterTableName,
  referencePermissions,
  titleName,
}) => {
  console.log('editObj', editObj);
  const id = '00000000-0000-0000-0000-000000000000';
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [showAddressErrorMessage, setAddressErrorMessage] = useState(false);
  const [contactsError, setContactsError] = useState({});
  const [contactsErrorMessage, setContactsErrorMessage] = useState('');
  const [contactsErrorModal, setContactsErrorModal] = useState(false);

  useEffect(() => {}, [editObj]);

  const fetchNameField = () => {
    const referenceTable = ['securityquestion'];
    if (referenceTable.includes(referenceMasterTableName.toLowerCase())) {
      return (
        referenceMasterTableName[0].toLowerCase() +
        referenceMasterTableName.slice(1) +
        'Details'
      );
    } else {
      return (
        referenceMasterTableName[0].toLowerCase() +
        referenceMasterTableName.slice(1) +
        'Name'
      );
    }
  };

  const updateStatusOfArrayFields = values => {
    const valueContactSeq = values?.contactItem?.map(item => item.contactItemSeq);

    editObj?.contactItem?.filter(item => {
      if (!valueContactSeq?.includes(item.contactItemSeq)) {
        values?.contactItem?.push({ ...item, status: 'InActive' });
        return { ...item, status: 'InActive' };
      }
    });

    return values?.contactItem;
  };

  const updateStatusOfAddressFields = values => {
    const valuesAddressSeq = values?.address?.map(item => item.addressSeq);

    editObj?.address?.filter(item => {
      if (!valuesAddressSeq?.includes(item.addressSeq)) {
        values?.address?.push({ ...item, isActive: false,  status: 'InActive' });
        return { ...item, isActive: false,  status: 'InActive' };
      }
    });
    return values?.address;
  };

  const sendContactsError = (values) => {
    if (values?.contactItem?.length > 0) {
      if (
        (contactsError.name && !contactsError?.value) ||
        (!contactsError.name && contactsError?.value)
      ) {
        setContactsErrorModal(true);
        setContactsErrorMessage('Contact Field is required');
        return true;
      } else if (
        contactsError?.name?.toLowerCase() === 'email' &&
        !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          contactsError?.value
        )
        // !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(contactsError?.value)
      ) {
        setContactsErrorModal(true);
        setContactsErrorMessage('Please enter valid email address');
        return true;
      }
      return false;
    }
    return false;
  };

  const onSave = async (values) => {
    const checkContactValidation = sendContactsError(values);
    if (!checkContactValidation) {

    const updateArrayFields = updateStatusOfArrayFields(values);
    const updateAdrressFields = updateStatusOfAddressFields(values);
    const isNameAlreadyExist = rows.filter(
      (row) =>
        row[
          referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Name'
        ] ==
        values[
          referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Name'
        ]
    ).length;
    if (isNameAlreadyExist && !Object.keys(editObj).length) {
      setDialogContents(
        `Invalid data: there is another record with the same name in ${masterTableName
          .split('')
          .map((alpha) => {
            if (alpha <= 'Z') return ' ' + alpha;
            return alpha;
          })
          .join('')
          .trim()} table`
      );
      setOpenApiDialogBox(true);
      return;
    }
    // let contacts = [];
    // if (values?.contactItem)
    //   values?.contactItem.forEach((item, index) => {
    //     if (item.contactItemDetails) {
    //       contacts.push(item);
    //     }
    //   });
    // if (editObj?.contactItem)
    //   editObj.contactItem.map((contact) => {
    //     if (
    //       contact.contactItemSeq != id &&
    //       contacts.filter(
    //         (item) => item.contactItemSeq == contact.contactItemSeq
    //       ).length == 0
    //     ) {
    //       contacts.push({
    //         ...contact,
    //         isActive: false,
    //         status: 'InActive',
    //       });
    //     }
    //   });
    let postValues = {};
    postValues = {
      ...postValues,
      ...values,
    };
    if (referenceMasterTableName.toLowerCase() == 'funeralhome') {
      postValues.location = {
        locationSeq: postValues?.location?.locationSeq || id,
        morgueDetailSeq: postValues?.location?.morgueDetailSeq || id,
        locationTypeSeq: postValues?.location?.locationTypeSeq || id,
        locationSubTypeSeq: postValues?.location?.locationSubTypeSeq || id,
        locationName:
          values?.funeralHomeName || postValues?.location?.locationName || '',
        locationDesc: postValues?.location?.locationDesc || '',
        contactInfoSeq: postValues?.location?.contactInfoSeq || id,
        parentLocationSeq: postValues?.location?.parentLocationSeq || id,
        isUsed: postValues?.location?.isUsed || false,
        status: postValues?.location?.status || '',
        daveFacilityCode: postValues?.location?.daveFacilityCode || '',
        countySeq: values?.countySeq || postValues?.location?.countySeq || id,
        locationOnCounty: postValues?.location?.locationOnCounty || null,
        address: postValues?.location?.address || [],
        contactItem: postValues?.location?.contactItem || null,
      };
    }
    try {
      const response = await saveAdminData({
        values: {
          ...postValues,
          [referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Seq']:
            values[
              referenceMasterTableName[0].toLowerCase() +
                referenceMasterTableName.slice(1) +
                'Seq'
            ] || id,
          // contactItem: contacts,
          address: updateAdrressFields,
          contactItem: updateArrayFields,
        },
        tableName: referenceMasterTableName,
      });
      if (response.message.includes('UNIQUE KEY')) {
        setDialogContents('Duplicate values are not allowed!');
      } else {
        setDialogContents(response.message);
      }
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10} mb={3} mt={3}>
          <Typography variant='h5'>
            <strong>
              {Object.keys(editObj).length ? 'Modify ' : 'Enter New '}{' '}
              {referenceMasterTableName == 'PublicAdministratorOffice' ? 'Surrogate court': titleName()}
            </strong>
          </Typography>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          ...editObj,
          [referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Name']:
            editObj[
              referenceMasterTableName[0].toLowerCase() +
                referenceMasterTableName.slice(1) +
                'Name'
            ],
          [referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Order']: editObj[
            referenceMasterTableName[0].toLowerCase() +
              referenceMasterTableName.slice(1) +
              'Order'
          ]
            ? Number(
                editObj[
                  referenceMasterTableName[0].toLowerCase() +
                    referenceMasterTableName.slice(1) +
                    'Order'
                ]
              )
            : maxOrder + 10,
          address: editObj?.address ?? [],
          contactItem: editObj?.contactItem ?? [],
          countySeq: editObj?.countySeq || '',
        }}
        validationSchema={Yup.object({
          [referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Name']: Yup.string()
            .required(
              `${masterTableName
                .split('')
                .map((alpha) => {
                  if (alpha <= 'Z') return ' ' + alpha;
                  return alpha;
                })
                .join('')
                .trim()} is required`
            )
            .max(
              referenceTypeFieldLengthChecks[
                `${
                  referenceMasterTableName[0].toLowerCase() +
                  referenceMasterTableName.slice(1)
                }Name`
              ],
              `${masterTableName
                .split('')
                .map((alpha) => {
                  if (alpha <= 'Z') return ' ' + alpha;
                  return alpha;
                })
                .join('')
                .trim()} can not be more than ${
                referenceTypeFieldLengthChecks[
                  `${
                    referenceMasterTableName[0].toLowerCase() +
                    referenceMasterTableName.slice(1)
                  }Name`
                ]
              }`
            )
            .nullable(),
          countySeq: isCounty
            ? Yup.string().required('Jurisdiction is Required').nullable()
            : Yup.string().nullable(),
          [referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Order']: isOrder
            ? Yup.string()
                .required(
                  `${masterTableName
                    .split('')
                    .map((alpha) => {
                      if (alpha <= 'Z') return ' ' + alpha;
                      return alpha;
                    })
                    .join('')
                    .trim()} order is Required`
                )
                .max(99999, 'List Order can not be more than 99999')
                .nullable()
            : Yup.string().nullable(),
          address: Yup.array()
            .of(
              Yup.object().shape({
                addressTypeSeq: Yup.string().nullable(),
                addressLine1: Yup.string().when(
                  ['addressTypeSeq'],
                  (addressTypeSeq) => {
                    if (
                      addressTypeSeq ==
                        'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
                      addressTypeSeq == 'eea37e74-0a50-419e-9de9-372d21d1f890'
                    ) {
                    } else {
                      return Yup.string().required(
                        'Address line 1 is required'
                      );
                    }
                  }
                ),
                apartmentNo: Yup.string().when(
                  ['addressTypeSeq'],
                  (addressTypeSeq) => {
                    if (
                      addressTypeSeq ==
                        'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
                      addressTypeSeq == 'eea37e74-0a50-419e-9de9-372d21d1f890'
                    ) {
                    } else {
                      return Yup.string().max(10, '').nullable();
                    }
                  }
                ),
                floor: Yup.string().when(
                  ['addressTypeSeq'],
                  (addressTypeSeq) => {
                    if (
                      addressTypeSeq ==
                        'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
                      addressTypeSeq == 'eea37e74-0a50-419e-9de9-372d21d1f890'
                    ) {
                    } else {
                      return Yup.string().max(10, '').nullable();
                    }
                  }
                ),
                zip: Yup.string().when(['addressTypeSeq'], (addressTypeSeq) => {
                  if (
                    addressTypeSeq == 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
                    addressTypeSeq == 'eea37e74-0a50-419e-9de9-372d21d1f890'
                  ) {
                  } else {
                    return Yup.string().max(10, '').nullable();
                  }
                }),
                city: Yup.string().when(
                  ['addressTypeSeq'],
                  (addressTypeSeq) => {
                    if (
                      addressTypeSeq ==
                        'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
                      addressTypeSeq == 'eea37e74-0a50-419e-9de9-372d21d1f890'
                    ) {
                    } else {
                      return Yup.string().required('City is required');
                    }
                  }
                ),
              })
            )
            .min(1, 'Address is a required field')
            .nullable(),
          contactItem: Yup.array().of(
            Yup.object().shape({
              contactItemTypeSeq: Yup.string().required(
                'Contact type is required'
              ),
              contactItemDetails: Yup.string().required(
                'Contact Details is required'
              ),
            })
          ),
          // .min(1, 'Contact is a required field')
        })}
        onSubmit={onSave}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          handleChange,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {isCounty && (
                  <Grid item sm={12} xs={12} md={6} lg={6}>
                    <FormControl fullWidth focused required>
                      <InputLabel htmlFor='countySeq' id='countySeq'>
                        Jurisdiction
                      </InputLabel>
                      <Select
                        required
                        onChange={handleChange}
                        variant='outlined'
                        label='Jurisdiction'
                        focused
                        displayEmpty
                        size='large'
                        name={`countySeq`}
                        value={values?.countySeq || ''}
                      >
                        {Object.keys(countyNames).map((item, z) => {
                          return (
                            <MenuItem
                              key={countyNames[item].countySeq}
                              value={countyNames[item].countySeq}
                            >
                              {countyNames[item].countyName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      name='countySeq'
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    />
                  </Grid>
                )}

                <Grid item sm={12} xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    error
                    required
                    component='fieldset'
                    variant='standard'
                  >
                    <TextField
                      required
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label={masterTableName
                        .split('')
                        .map((alpha) => {
                          if (alpha <= 'Z') return ' ' + alpha;
                          return alpha;
                        })
                        .join('')
                        .trim()}
                      variant='outlined'
                      name={
                        referenceMasterTableName[0].toLowerCase() +
                        referenceMasterTableName.slice(1) +
                        'Name'
                      }
                      value={
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ]
                      }
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(
                          0,
                          referenceTypeFieldLengthChecks[
                            `${
                              referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1)
                            }Name`
                          ]
                        );
                      }}
                      disabled={
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Other' ||
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Unknown'
                      }
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name={
                        referenceMasterTableName[0].toLowerCase() +
                        referenceMasterTableName.slice(1) +
                        'Name'
                      }
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    />
                  </FormControl>
                </Grid>
                {isOrder && (
                  <Grid item sm={12} xs={12} md={6} lg={6}>
                    <FormControl
                      fullWidth
                      error
                      required
                      component='fieldset'
                      variant='standard'
                    >
                      <TextField
                        required
                        focused
                        id='outlined-basic'
                        defaultValue={''}
                        label={
                          masterTableName
                            .split('')
                            .map((alpha) => {
                              if (alpha <= 'Z') return ' ' + alpha;
                              return alpha;
                            })
                            .join('')
                            .trim() + ' Order'
                        }
                        variant='outlined'
                        name={
                          referenceMasterTableName[0].toLowerCase() +
                          referenceMasterTableName.slice(1) +
                          'Order'
                        }
                        value={
                          values[
                            referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Order'
                          ]
                        }
                        disabled={
                          values[
                            referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Name'
                          ] == 'Other' ||
                          values[
                            referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Name'
                          ] == 'Unknown'
                        }
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name={
                          referenceMasterTableName[0].toLowerCase() +
                          referenceMasterTableName.slice(1) +
                          'Order'
                        }
                        render={(msg) => (
                          <FormHelperText error>{msg}</FormHelperText>
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <FormControl
                    error
                    required
                    component='fieldset'
                    variant='standard'
                    sx={{ marginTop: '10px' }}
                  >
                    <AddressData
                      addresses={values.address}
                      nursingHomeSeq={values.nursingHomeSeq || id}
                      funeralHomeSeq={values.funeralHomeSeq || id}
                      lawEnforcementSeq={values.lawEnforcementSeq || id}
                      contactInfoSeq={values.contactInfoSeq || id}
                      handleChange={(data) =>
                        handleChange({
                          target: {
                            value: data,
                            name: 'address',
                          },
                        })
                      }
                    />
                    {typeof errors.address == 'string' && (
                      <ErrorMessage
                        name='address'
                        render={(msg) => (
                          <FormHelperText error>{msg}</FormHelperText>
                        )}
                      />
                    )}
                  </FormControl>
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <FormControl
                    fullWidth
                    error
                    required
                    component='fieldset'
                    variant='standard'
                    sx={{ paddingTop: '0px' }}
                  >
                    <ContactData
                      contactItem={values.contactItem}
                      handleChange={(data) =>
                        handleChange({
                          target: {
                            value: data,
                            name: 'contactItem',
                          },
                        })
                      }
                    />
                    {typeof errors.contactItem == 'string' && (
                      <ErrorMessage
                        name='contactItem'
                        render={(msg) => (
                          <FormHelperText error>{msg}</FormHelperText>
                        )}
                      />
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <Button
                      variant='contained'
                      sx={{ marginTop: '7px' }}
                      onClick={handleSubmit}
                      disabled={
                        (!referencePermissions?.edit &&
                          !referencePermissions?.add) ||
                        values[fetchNameField()] == 'Other' ||
                        values[fetchNameField()] == 'Unknown'
                      }
                    >
                      {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>

      <div className='modal'>
        <Dialog
          open={openApiDialogBox}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenApiDialogBox(false)}
          // aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogContents ==
              `Invalid data: there is another record with the same name in ${masterTableName
                .split('')
                .map((alpha) => {
                  if (alpha <= 'Z') return ' ' + alpha;
                  return alpha;
                })
                .join('')
                .trim()} table` ? (
                <FormHelperText error>{dialogContents}</FormHelperText>
              ) : (
                dialogContents
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton onClick={() => setOpenApiDialogBox(false)}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
      {contactsError ? (
        <ApiNotification
          openApiDialogBox={contactsErrorModal}
          closeDialogBox={() => setContactsErrorModal(false)}
          dialogContents={contactsErrorMessage}
        />
      ) : null}
    </>
  );
};

export default AddEdit;
