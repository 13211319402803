import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';

export default function AppTitle() {
  const navigate = useNavigate();

  return (
    <Typography
      variant='h5'
      noWrap
      onClick={() => navigate('/home')}
      sx={{
        minWidth: 'max-content',
        fontFamily: 'monospace',
        fontWeight: 700,
        letterSpacing: '.3rem',
        color: 'inherit',
        textDecoration: 'none',
      }}
    >
      MedexLAB
    </Typography>
  );
}
