import { Box, Autocomplete, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';

export default function UnnaturalDeathCategoryManagement() {
  const formik = useFormikContext();
  const auth = useAuth();
  const [categories, setCategories] = useState([]);
  const { REACT_APP_API_URL } = process.env;

  const fetchUnnaturalDeathCategoriesAndOptions = async () => {
    await fetch(`${REACT_APP_API_URL}getunnaturaldeathoptionswithparentcategories`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${auth.user?.accessToken}` },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCategories(data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    console.log(formik.values?.casePathology);
  }, [formik.values?.casePathology]);

  useEffect(() => {
    fetchUnnaturalDeathCategoriesAndOptions();
  }, []);

  return (
    <Box>
      <Autocomplete
        multiple
        options={categories ?? []}
        groupBy={option => option.parentCategoryName}
        getOptionLabel={option => option.optionName}
        isOptionEqualToValue={(option, value) =>
          option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
        }
        fullWidth
        renderInput={params => <TextField {...params} label='Unnatural Death Categories' />}
        disabled={!formik.status.editing}
        disableCloseOnSelect
        id='casePathology.unnaturalDeathSelectedOptions'
        name='casePathology.unnaturalDeathSelectedOptions'
        value={formik.values?.casePathology?.unnaturalDeathSelectedOptions ?? []}
        onChange={(e, value) =>
          formik.setFieldValue('casePathology.unnaturalDeathSelectedOptions', value)
        }
        error={
          (formik.touched?.casePathology?.unnaturalDeathSelectedOptions ?? false) &&
          Boolean(formik.errors?.casePathology?.unnaturalDeathSelectedOptions ?? false)
        }
        helperText={
          (formik.touched?.casePathology?.unnaturalDeathSelectedOptions ?? false) &&
          (formik.errors?.casePathology?.unnaturalDeathSelectedOptions ?? false)
        }
      />
    </Box>
  );
}
