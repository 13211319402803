// getAppTheme.js
import { createTheme } from '@mui/material';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';

function getHtmlFontSize() {
  const width = window.innerWidth;
  return (7000 - width) / 250;
}

export default function getAppTheme(mode) {
  return createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === 'dark' ? '#5C72FF' : '#4f51FF',
      },
      secondary: {
        main: mode === 'dark' ? '#f4a638' : '#C2750A',
      },
      background: {
        default: mode === 'dark' ? '#181818' : '#ffffff',
        paper: mode === 'dark' ? '#1f1f1f' : '#f0f0f0',
      },
      text: {
        disabled: mode === 'dark' ? '#FFF' : '#000',
        primary: mode === 'dark' ? '#FFF' : '#000',
      },
    },
    typography: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      htmlFontSize: getHtmlFontSize(), // Base font size in pixels
      fontWeightMedium: 500,
      allVariants: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '3px 6px',
            // fontSize: '0.875rem',
          },
        },
        defaultProps: {
          size: 'small',
        },
      },
      MuiTextField: {
        // styleOverrides: {
        //   root: {
        //     fontSize: '0.875rem',
        //   },
        // },
        defaultProps: {
          size: 'small',
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          // root: {
          //   fontSize: '0.875rem',
          // },
          input: {
            padding: '6px 12px',
          },
        },
      },
      // MuiTypography: {
      //   styleOverrides: {
      //     root: {
      //       fontSize: '0.875rem',
      //     },
      //   },
      // },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: '4px 0',
          },
          label: {
            fontSize: '0.875rem',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '6px',
          },
        },
      },
      MuiRadio: {
        defaultProps: {
          size: 'small',
        },
      },
    },
  });
}
