import { useFormik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import { renderAutocompleteEditCell } from '../../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import CrudDataGrid from '../../../../utils/components/CrudDataGrid/CrudDataGrid';
import { dateTimeColumnType } from '../../../../utils/components/CrudDataGrid/DateTimeColumn';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { TimeZone } from '../../../../utils/constants/constants.js';

dayjs.extend(utc);
dayjs.extend(timezone);

export function RequestedProcedures() {
  const { user } = useAuth();
  const [reqProcedureOptions, setreqProcedureOptions] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [allConsultantOptions, setallConsultantOptions] = useState([]);
  // const [filteredConsultantOptions, setFilteredConsultantOptions] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext();
  const procedureFormik = useFormik({
    initialValues: [],
    enableReinitialize: true,
  });

  useEffect(() => {
    // Obtain requested procedure options
    fetch(REACT_APP_API_URL + 'requestedProcedureOptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setreqProcedureOptions(data);
      })
      .catch(e => {
        alert('Failed to get from requested Procedure Options\nError: ', e);
      });

    fetchConsultantOptions();
    fetchRequestedProcedures();
  }, []);
  // console.log(reqProcedureOptions);

  const fetchRequestedProcedures = async () => {
    try {
      const caseSeq = formik.values?.caseSummary?.caseSeq;
      const response = await fetch(`${REACT_APP_API_URL}getrequestedproceduresoncase/${caseSeq}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      const updatedData = data.map(item => {
        return {
          ...item,
          isNew: false, // Set isNew to false initially for each item
        };
      });
      // console.log('myproc:', updatedData);
      setProcedures(updatedData); // Sets the data with isNew and consultant
      return data; // array of requested procedures
    } catch (error) {
      console.error('There was a problem with the fetch operation: ', error);
    }
  };

  // Currently fetches all w/o regard to procedures
  const fetchConsultantOptions = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}consultantonprocedureoptions`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const all_consultant_db_options = await response.json();
      // console.log('Consultant options: ', all_consultant_db_options);

      const formattedConsultantOptions = all_consultant_db_options.map(option => ({
        ...option,
        optionName: option.optionName.replace(/,\s*$/, ''),
      }));

      // console.log('Consultant options after formatting: ', formattedConsultantOptions);
      setallConsultantOptions(formattedConsultantOptions);
    } catch (error) {
      console.error('There was a problem with the fetch operation: ', error);
    }
  };

  useEffect(() => {
    console.log(formik.values);
    console.log(formik.dirty);
  }, [formik]);

  const requestedProceduresColumns = [
    {
      flex: 1,
      minWidth: 175,
      field: 'reqProcedure',
      headerName: 'Requested Procedure',
      editable: true,
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: reqProcedureOptions,
      renderEditCell: params => renderAutocompleteEditCell(params, 'Requested Procedure'),
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'consultant',
      headerName: 'Consultant',
      editable: true,
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: allConsultantOptions,
      renderEditCell: params => renderAutocompleteEditCell(params, 'Consultant'),
    },
    {
      flex: 1,
      field: 'requestedDate',
      headerName: 'Date Requested',
      headerAlign: 'left',
      align: 'left',
      ...dateTimeColumnType,
      valueSetter: params => {
        if (params?.value == null || params?.value == 'Invalid Date') {
          return { ...params.row, requestedDate: null };
        } else {
          const localDate = params?.value
            ? dayjs(params?.value).format('YYYY-MM-DDTHH:mm:ss')
            : null;

          return { ...params.row, requestedDate: localDate };
        }
      },
      editable: true,
    },
    {
      flex: 1,
      field: 'receivedDate',
      headerName: 'Date Received',
      headerAlign: 'left',
      align: 'left',
      ...dateTimeColumnType,
      valueSetter: params => {
        if (params?.value == null || params?.value == 'Invalid Date') {
          return { ...params.row, receivedDate: null };
        } else {
          const localDate = params?.value
            ? dayjs(params?.value).format('YYYY-MM-DDTHH:mm:ss')
            : null;

          return { ...params.row, receivedDate: localDate };
        }
      },
      editable: true,
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'comments',
      headerName: 'Comments',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      type: 'string',
      editable: true,
    },
  ];

  const createRequestedProcedure = newRow => {
    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    formData.append('userSeq', user.userSeq);
    fetch(REACT_APP_API_URL + 'createRequestedProcedure', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        // console.log('output', data);
        const newId = JSON.parse(data); // Parse the text to remove quotes
        newRow.requestedProcedureSeq = newId;
        // console.log('new ROW', newRow);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteRequestedProcedure = id => {
    fetch(REACT_APP_API_URL + `deleterequestedprocedure/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {})
      .catch(e => {
        console.log(e);
      });
  };

  const updateRequestedProcedure = updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));
    formData.append('userSeq', user.userSeq);
    // formData.append('RequestedProcedureSeq, ')

    fetch(REACT_APP_API_URL + 'updaterequestedprocedure', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        console.log('updated data', data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <CrudDataGrid
      rows={procedures}
      setRows={updatedData => {
        // console.log('Updated Data: ', updatedData);
        setProcedures(updatedData);
        // console.log('PLEASEEEEE', procedures);
        // formik.setFieldValue(`caseMortuary.reqProcedures`, data);
      }}
      idcolumn='requestedProcedureSeq'
      initialColumns={requestedProceduresColumns}
      createEntry={createRequestedProcedure}
      deleteEntry={deleteRequestedProcedure}
      updateEntry={updateRequestedProcedure}
      title='Requested Procedure'
    />
  );
}
