import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import CaseViewDateTimeField from '../../../components/CaseView/CaseViewDateTimeField';
import { useOptionsAPI } from '../../../utils/api/useOptions.hook';
import { useAuth } from '../../../utils/auth/AuthService';
import { CaseViewOptionsAutocomplete } from '../../../utils/components/CaseViewOptionsAutocomplete';
import { FormikTextField } from '../../../utils/components/FormikTextField';
import { Option } from './MortuaryModules/XrayRequestForm/xrayrequestform.constants';

export function OrganDonationView() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const formik = useFormikContext<any>();
  const organDonationCaseDetails: OrganDonation =
    formik.values?.caseInvestigations.organDonation ?? {};

  const {
    options: organOptions,
    loading: organOptionsLoading,
    error: organOptionsError,
  } = useOptionsAPI('options/organdonation/organoptions');

  const {
    options: approvalOptions,
    loading: approvalOptionsLoading,
    error: approvalOptionsError,
  } = useOptionsAPI('options/organdonation/approvaloptions');

  const auth = useAuth();
  const authorizedToEdit =
    auth?.user.roleCheck([
      'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
      'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
      'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
      '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
      '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
    ]) || false;

  function checkOrganSelectionStatus(organs: OrganDonationOrgan[]) {
    const selectedOrgans = organs.filter(o => o.isApprovedForHarvest);
    if (selectedOrgans.length === 0) {
      return ApprovalStatus.None_Approved;
    } else if (selectedOrgans.length === organs.length) {
      return ApprovalStatus.All_Approved;
    } else {
      return ApprovalStatus.Select_Any;
    }
  }

  const ApprovalStatus = {
    Select_Any: 'f8e2ca1a-3a09-4aad-a891-a3d7387c56e5',
    None_Approved: '96d15eaf-afe7-47ed-a8f8-eb4b8472b4ed',
    All_Approved: '5c005f1b-6f2d-4ce1-b556-ef7491d05d39',
  };

  function onHarvestApprovalStatusClick(currentApprovalStatus: Option) {
    if (currentApprovalStatus.optionSeq?.toLowerCase() === ApprovalStatus.Select_Any) {
      const newOrgans: OrganDonationOrgan[] = organDonationCaseDetails.organs.map(o => {
        if (o.organ.optionSeq.toLowerCase() === '296b9a79-467d-4b81-ae3f-1e43ec48837b') {
          return {
            ...o,
            isApprovedForHarvest: false,
          };
        } else {
          return {
            ...o,
            isApprovedForHarvest: true,
          };
        }
      });
      formik.setFieldValue('caseInvestigations.organDonation.organs', newOrgans);

      const newApprovalStatus = approvalOptions.find(
        o => o.optionSeq.toLowerCase() === ApprovalStatus.All_Approved
      );
      formik.setFieldValue('caseInvestigations.organDonation.organs', newOrgans);
      formik.setFieldValue(
        'caseInvestigations.organDonation.organHarvestApproval',
        newApprovalStatus
      );
    } else if (currentApprovalStatus.optionSeq?.toLowerCase() === ApprovalStatus.None_Approved) {
      const newOrgans: OrganDonationOrgan[] = organDonationCaseDetails.organs.map(o => {
        if (o.organ.optionSeq.toLowerCase() === '296b9a79-467d-4b81-ae3f-1e43ec48837b') {
          return {
            ...o,
            isApprovedForHarvest: false,
          };
        } else {
          return {
            ...o,
            isApprovedForHarvest: true,
          };
        }
      });
      formik.setFieldValue('caseInvestigations.organDonation.organs', newOrgans);

      const newApprovalStatus = approvalOptions.find(
        o => o.optionSeq.toLowerCase() === ApprovalStatus.All_Approved
      );
      formik.setFieldValue('caseInvestigations.organDonation.organs', newOrgans);
      formik.setFieldValue(
        'caseInvestigations.organDonation.organHarvestApproval',
        newApprovalStatus
      );
    } else if (currentApprovalStatus.optionSeq?.toLowerCase() === ApprovalStatus.All_Approved) {
      const newOrgans: OrganDonationOrgan[] = organDonationCaseDetails.organs.map(o => {
        if (o.organ.optionSeq.toLowerCase() === '296b9a79-467d-4b81-ae3f-1e43ec48837b') {
          return {
            ...o,
            isApprovedForHarvest: false,
          };
        } else {
          return {
            ...o,
            isApprovedForHarvest: false,
          };
        }
      });
      const newApprovalStatus = approvalOptions.find(
        o => o.optionSeq.toLowerCase() === ApprovalStatus.None_Approved
      );
      formik.setFieldValue('caseInvestigations.organDonation.organs', newOrgans);
      formik.setFieldValue(
        'caseInvestigations.organDonation.organHarvestApproval',
        newApprovalStatus
      );
    }
  }

  const renderHarvestApprovalStatus = useMemo(() => {
    const selectedApprovalStatus =
      approvalOptions.find(
        o =>
          o.optionSeq?.toUpperCase() ===
          organDonationCaseDetails?.organHarvestApproval?.optionSeq?.toUpperCase()
      ) ?? null;
    const text = Boolean(selectedApprovalStatus?.optionName)
      ? `Approval Status (${selectedApprovalStatus?.optionName})`
      : 'Approval Status';
    return (
      <Button
        disabled={!formik.status.editing || !authorizedToEdit}
        variant='text'
        sx={{
          textTransform: 'none',
          '&.Mui-disabled': {
            color: 'text.primary',
          },
        }}
        onClick={() => onHarvestApprovalStatusClick(selectedApprovalStatus)}
      >
        {text}
      </Button>
    );
  }, [approvalOptions, organDonationCaseDetails]);

  function onResearchApprovalStatusClick(currentApprovalStatus: Option) {
    if (currentApprovalStatus.optionSeq?.toLowerCase() === ApprovalStatus.Select_Any) {
      const newOrgans: OrganDonationOrgan[] = organDonationCaseDetails.organs.map(o => {
        if (o.organ.optionSeq.toLowerCase() === '296b9a79-467d-4b81-ae3f-1e43ec48837b') {
          return {
            ...o,
            isApprovedForResearch: false,
          };
        } else {
          return {
            ...o,
            isApprovedForResearch: true,
          };
        }
      });
      formik.setFieldValue('caseInvestigations.organDonation.organs', newOrgans);

      const newApprovalStatus = approvalOptions.find(
        o => o.optionSeq.toLowerCase() === ApprovalStatus.All_Approved
      );
      formik.setFieldValue('caseInvestigations.organDonation.organs', newOrgans);
      formik.setFieldValue(
        'caseInvestigations.organDonation.organResearchApproval',
        newApprovalStatus
      );
    } else if (currentApprovalStatus.optionSeq?.toLowerCase() === ApprovalStatus.None_Approved) {
      const newOrgans: OrganDonationOrgan[] = organDonationCaseDetails.organs.map(o => {
        if (o.organ.optionSeq.toLowerCase() === '296b9a79-467d-4b81-ae3f-1e43ec48837b') {
          return {
            ...o,
            isApprovedForResearch: false,
          };
        } else {
          return {
            ...o,
            isApprovedForResearch: true,
          };
        }
      });
      formik.setFieldValue('caseInvestigations.organDonation.organs', newOrgans);

      const newApprovalStatus = approvalOptions.find(
        o => o.optionSeq.toLowerCase() === ApprovalStatus.All_Approved
      );
      formik.setFieldValue('caseInvestigations.organDonation.organs', newOrgans);
      formik.setFieldValue(
        'caseInvestigations.organDonation.organResearchApproval',
        newApprovalStatus
      );
    } else if (currentApprovalStatus.optionSeq?.toLowerCase() === ApprovalStatus.All_Approved) {
      const newOrgans: OrganDonationOrgan[] = organDonationCaseDetails.organs.map(o => {
        if (o.organ.optionSeq.toLowerCase() === '296b9a79-467d-4b81-ae3f-1e43ec48837b') {
          return {
            ...o,
            isApprovedForResearch: false,
          };
        } else {
          return {
            ...o,
            isApprovedForResearch: false,
          };
        }
      });
      const newApprovalStatus = approvalOptions.find(
        o => o.optionSeq.toLowerCase() === ApprovalStatus.None_Approved
      );
      formik.setFieldValue('caseInvestigations.organDonation.organs', newOrgans);
      formik.setFieldValue(
        'caseInvestigations.organDonation.organResearchApproval',
        newApprovalStatus
      );
    }
  }

  const renderResearchApprovalStatus = useMemo(() => {
    const selectedApprovalStatus =
      approvalOptions.find(
        o =>
          o.optionSeq?.toUpperCase() ===
          organDonationCaseDetails?.organResearchApproval?.optionSeq?.toUpperCase()
      ) ?? null;
    const text = Boolean(selectedApprovalStatus?.optionName)
      ? `Approval Status (${selectedApprovalStatus?.optionName})`
      : 'Approval Status';
    return (
      <Button
        disabled={!formik.status.editing || !authorizedToEdit}
        variant='text'
        sx={{
          textTransform: 'none',
          '&.Mui-disabled': {
            color: 'text.primary',
          },
        }}
        onClick={() => onResearchApprovalStatusClick(selectedApprovalStatus)}
      >
        {text}
      </Button>
    );
  }, [approvalOptions, organDonationCaseDetails]);

  return (
    <Stack gap={2} mb={2}>
      <Typography variant='subtitle1' color='gray'>
        <strong>ORGAN DONATION INFORMATION</strong>
      </Typography>

      <Grid container spacing={2}>
        <Grid xs={12} sm={4}>
          <CaseViewOptionsAutocomplete
            label='Organ/Tissue Donor Organization'
            optionsEndpoint='options/organdonation/organizations'
            formikField='caseInvestigations.organDonation.organDonorOrganization'
            authorizedToEdit={r => [
              r.Medical_Examiner,
              r.Investigator,
              r.Historic_Dataentryonly,
              r.Historicdataentryonly,
              r.Systems_Admin,
            ]}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <FormikTextField
            label='Transplant Coordinator'
            formikField='caseInvestigations.organDonation.transplantCoordinator'
            authorizedToEdit={r => [
              r.Medical_Examiner,
              r.Investigator,
              r.Historic_Dataentryonly,
              r.Historicdataentryonly,
              r.Systems_Admin,
            ]}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <FormikTextField
            label='Transplant Coordinator Phone #'
            formikField='caseInvestigations.organDonation.transplantCoordinatorPhoneNumber'
            authorizedToEdit={r => [
              r.Medical_Examiner,
              r.Investigator,
              r.Historic_Dataentryonly,
              r.Historicdataentryonly,
              r.Systems_Admin,
            ]}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <CaseViewOptionsAutocomplete
            label='Medical Facility'
            optionsEndpoint={`gethcfoptions?jdxSeq=undefined`}
            formikField='caseInvestigations.organDonation.medicalFacility'
            authorizedToEdit={r => [
              r.Medical_Examiner,
              r.Investigator,
              r.Historic_Dataentryonly,
              r.Historicdataentryonly,
              r.Systems_Admin,
            ]}
          />
        </Grid>

        <Grid xs={12} sm={4}>
          <CaseViewDateTimeField
            xs={12}
            sm={12}
            label='Request Date/Time'
            authorizedToEdit={authorizedToEdit}
            fieldName='caseInvestigations.organDonation.requestDateTime'
            md={undefined}
            lg={undefined}
            xl={undefined}
            sx={undefined}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <FormikTextField
            label='Medical Record No'
            formikField='caseInvestigations.medicalRecordNo'
            authorizedToEdit={r => [
              r.Medical_Examiner,
              r.Investigator,
              r.Historic_Dataentryonly,
              r.Historicdataentryonly,
              r.Systems_Admin,
            ]}
          />
        </Grid>
        <Grid xs={12} sm={2}>
          <FormLabel>First Person Donor?</FormLabel>
          <RadioGroup
            row
            value={organDonationCaseDetails?.isFirstPersonDonor}
            onChange={e => {
              const newValue = e.target.value === 'true';
              formik.setFieldValue('caseInvestigations.organDonation.isFirstPersonDonor', newValue);
            }}
          >
            <FormControlLabel
              value='true'
              control={<Radio disabled={!formik.status.editing || !authorizedToEdit} />}
              label='Yes'
            />
            <FormControlLabel
              value='false'
              control={<Radio disabled={!formik.status.editing || !authorizedToEdit} />}
              label='No'
            />
          </RadioGroup>
        </Grid>

        <Grid xs={12} sm={2}>
          <FormLabel>Family Authorization Obtained?</FormLabel>
          <RadioGroup
            row
            value={organDonationCaseDetails.isFamilyAuthorizationObtained}
            onChange={e => {
              const newValue = e.target.value === 'true';
              formik.setFieldValue(
                'caseInvestigations.organDonation.isFamilyAuthorizationObtained',
                newValue
              );
            }}
          >
            <FormControlLabel
              value='true'
              control={<Radio disabled={!formik.status.editing || !authorizedToEdit} />}
              label='Yes'
            />
            <FormControlLabel
              value='false'
              control={<Radio disabled={!formik.status.editing || !authorizedToEdit} />}
              label='No'
            />
          </RadioGroup>
        </Grid>

        <Grid xs={12} sm={4}>
          <FormLabel>When can organs be harvested?</FormLabel>
          <RadioGroup row>
            <Tooltip title='Coming soon' arrow>
              <FormControlLabel value='yes' control={<Radio disabled />} label='Before autopsy' />
            </Tooltip>
            <Tooltip title='Coming soon' arrow>
              <FormControlLabel value='no' control={<Radio disabled />} label='After autopsy' />
            </Tooltip>
          </RadioGroup>
        </Grid>

        <Grid xs={12} sm={12}>
          <Divider component='hr' variant='fullWidth' />
        </Grid>

        <Grid xs={12} sm={12} md={12} lg={8}>
          <TableContainer component={Paper} variant='outlined'>
            <Typography sx={{ flex: '1 1 100%', p: 1 }} variant='h6' component='div'>
              For Procurement
            </Typography>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align='justify'>Organ Requested</TableCell>
                  <TableCell align='left'>Family Objection (coming soon)</TableCell>
                  <TableCell align='left'>{renderHarvestApprovalStatus}</TableCell>
                  <TableCell align='left'>Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organOptions.map(organ => {
                  const organOnCase = organDonationCaseDetails?.organs?.find(
                    o => o.organ?.optionSeq?.toUpperCase() === organ?.optionSeq?.toUpperCase()
                  );

                  const organToUpdateIndex = organDonationCaseDetails?.organs?.findIndex(
                    o => o.organ?.optionSeq?.toUpperCase() === organ?.optionSeq?.toUpperCase()
                  );

                  return (
                    <TableRow
                      key={organ.optionSeq}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{organ.optionName}</TableCell>
                      <TableCell align='left'>
                        <Checkbox
                          disabled={!formik.status.editing || !authorizedToEdit}
                          checked={organOnCase?.isRequestedForHarvest}
                          onChange={e => {
                            let updatedOrganOnCase: OrganDonationOrgan;
                            let updatedOrgans: OrganDonationOrgan[];

                            if (organOnCase) {
                              updatedOrganOnCase = {
                                ...organOnCase,
                                organ: organ,
                                isRequestedForHarvest: e.target.checked,
                              };
                              updatedOrgans = [...organDonationCaseDetails.organs];
                              updatedOrgans[organToUpdateIndex] = updatedOrganOnCase;
                            } else {
                              updatedOrganOnCase = {
                                caseSeq: formik.values?.caseSummary?.caseSeq || '',
                                organ: organ,
                                isRequestedForHarvest: e.target.checked,
                                isApprovedForHarvest: false,
                                isRestrictedFromHarvest: false,
                                isApprovedForResearch: false,
                                isRestrictedFromResearch: false,
                              };
                              updatedOrgans = [
                                ...(organDonationCaseDetails.organs || []),
                                updatedOrganOnCase,
                              ];
                            }

                            formik.setFieldValue(
                              `caseInvestigations.organDonation.organs`,
                              updatedOrgans
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell align='left'>
                        <Tooltip title='Coming soon' arrow>
                          <Checkbox
                            disabled
                            checked={organOnCase?.isRestrictedFromHarvest}
                            onChange={e => {
                              const updatedOrganOnCase: OrganDonationOrgan = {
                                ...organOnCase,
                                organ: organ,
                                isRestrictedFromHarvest: e.target.checked,
                              };
                              if (organToUpdateIndex > -1) {
                                formik.setFieldValue(
                                  `caseInvestigations.organDonation.organs[${organToUpdateIndex}]`,
                                  updatedOrganOnCase
                                );
                              }
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell align='left'>
                        <Checkbox
                          disabled={!formik.status.editing || !authorizedToEdit}
                          checked={organOnCase?.isApprovedForHarvest}
                          onChange={e => {
                            let updatedOrganOnCase: OrganDonationOrgan;
                            let updatedOrgans: OrganDonationOrgan[];

                            if (organOnCase) {
                              updatedOrganOnCase = {
                                ...organOnCase,
                                organ: organ,
                                isApprovedForHarvest: e.target.checked,
                              };
                              updatedOrgans = [...organDonationCaseDetails.organs];
                              updatedOrgans[organToUpdateIndex] = updatedOrganOnCase;
                            } else {
                              // If the organ is not found, create a new one with known details
                              updatedOrganOnCase = {
                                caseSeq: formik.values?.caseSummary?.caseSeq || '',
                                organ: organ,
                                isApprovedForHarvest: e.target.checked,
                                isRequestedForHarvest: false,
                                isRestrictedFromHarvest: false,
                                isRestrictedFromResearch: false,
                                isApprovedForResearch: false,
                              };
                              updatedOrgans = [
                                ...(organDonationCaseDetails.organs || []),
                                updatedOrganOnCase,
                              ];
                            }

                            formik.setFieldValue(
                              `caseInvestigations.organDonation.organs`,
                              updatedOrgans
                            );

                            // Check the new organ selection status
                            const newStatus = checkOrganSelectionStatus(updatedOrgans);
                            const newApprovalStatus = approvalOptions.find(
                              o => o.optionSeq.toLowerCase() === newStatus
                            );

                            // Update the organHarvestApproval
                            formik.setFieldValue(
                              'caseInvestigations.organDonation.organHarvestApproval',
                              newApprovalStatus
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell align='left'>
                        <TextField
                          disabled={!formik.status.editing || !authorizedToEdit}
                          value={organOnCase?.harvestNotes}
                          onChange={e => {
                            let updatedOrganOnCase: OrganDonationOrgan;
                            let updatedOrgans: OrganDonationOrgan[];

                            if (organOnCase) {
                              updatedOrganOnCase = {
                                ...organOnCase,
                                organ: organ,
                                harvestNotes: e.target.value,
                              };
                              updatedOrgans = [...organDonationCaseDetails.organs];
                              updatedOrgans[organToUpdateIndex] = updatedOrganOnCase;
                            } else {
                              updatedOrganOnCase = {
                                caseSeq: formik.values?.caseSummary?.caseSeq || '',
                                organ: organ,
                                harvestNotes: e.target.value,
                                isApprovedForHarvest: false,
                                isRequestedForHarvest: false,
                                isRestrictedFromHarvest: false,
                                isApprovedForResearch: false,
                                isRestrictedFromResearch: false,
                              };
                              updatedOrgans = [
                                ...(organDonationCaseDetails.organs || []),
                                updatedOrganOnCase,
                              ];
                            }

                            formik.setFieldValue(
                              `caseInvestigations.organDonation.organs`,
                              updatedOrgans
                            );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid xs={12} sm={12} md={12} lg={4}>
          <TableContainer component={Paper} variant='outlined'>
            <Typography sx={{ flex: '1 1 100%', p: 1 }} variant='h6' component='div'>
              For Research
            </Typography>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  {isSmallScreen && <TableCell></TableCell>}
                  <TableCell align='left'>{renderResearchApprovalStatus}</TableCell>
                  <TableCell align='left'>Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organOptions.map(organ => {
                  const organOnCase = organDonationCaseDetails?.organs?.find(
                    o => o.organ?.optionSeq?.toUpperCase() === organ?.optionSeq?.toUpperCase()
                  );

                  const organToUpdateIndex = organDonationCaseDetails?.organs?.findIndex(
                    o => o.organ?.optionSeq?.toUpperCase() === organ?.optionSeq?.toUpperCase()
                  );

                  return (
                    <TableRow
                      key={organ.optionSeq}
                      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {isSmallScreen && <TableCell>{organ.optionName}</TableCell>}

                      <TableCell align='left'>
                        <Checkbox
                          disabled={!formik.status.editing || !authorizedToEdit}
                          checked={organOnCase?.isApprovedForResearch}
                          // onChange={e => {
                          //   const updatedOrganOnCase: OrganDonationOrgan = {
                          //     ...organOnCase,
                          //     organ: organ,
                          //     isApprovedForResearch: e.target.checked,
                          //   };
                          //   if (organToUpdateIndex > -1) {
                          //     const updatedOrgans = [...organDonationCaseDetails.organs];
                          //     updatedOrgans[organToUpdateIndex] = updatedOrganOnCase;

                          //     formik.setFieldValue(
                          //       `caseInvestigations.organDonation.organs`,
                          //       updatedOrgans
                          //     );

                          //     // Check the new organ selection status
                          //     const newStatus = checkOrganSelectionStatus(updatedOrgans);
                          //     const newApprovalStatus = approvalOptions.find(
                          //       o => o.optionSeq.toLowerCase() === newStatus
                          //     );

                          //     // Update the organHarvestApproval
                          //     formik.setFieldValue(
                          //       'caseInvestigations.organDonation.organResearchApproval',
                          //       newApprovalStatus
                          //     );
                          //   }
                          // }}
                          onChange={e => {
                            let updatedOrganOnCase: OrganDonationOrgan;
                            let updatedOrgans: OrganDonationOrgan[];

                            if (organOnCase) {
                              updatedOrganOnCase = {
                                ...organOnCase,
                                organ: organ,
                                isApprovedForResearch: e.target.checked,
                              };
                              updatedOrgans = [...organDonationCaseDetails.organs];
                              updatedOrgans[organToUpdateIndex] = updatedOrganOnCase;
                            } else {
                              updatedOrganOnCase = {
                                caseSeq: formik.values?.caseSummary?.caseSeq || '',
                                organ: organ,
                                harvestNotes: '',
                                isApprovedForHarvest: false,
                                isRequestedForHarvest: false,
                                isRestrictedFromHarvest: false,
                                isApprovedForResearch: e.target.checked,
                                isRestrictedFromResearch: false,
                              };
                              updatedOrgans = [
                                ...(organDonationCaseDetails.organs || []),
                                updatedOrganOnCase,
                              ];
                            }

                            formik.setFieldValue(
                              `caseInvestigations.organDonation.organs`,
                              updatedOrgans
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell align='left'>
                        <TextField
                          disabled={!formik.status.editing || !authorizedToEdit}
                          value={organOnCase?.researchNotes}
                          onChange={e => {
                            let updatedOrganOnCase: OrganDonationOrgan;
                            let updatedOrgans: OrganDonationOrgan[];

                            if (organOnCase) {
                              updatedOrganOnCase = {
                                ...organOnCase,
                                organ: organ,
                                researchNotes: e.target.value,
                              };
                              updatedOrgans = [...organDonationCaseDetails.organs];
                              updatedOrgans[organToUpdateIndex] = updatedOrganOnCase;
                            } else {
                              updatedOrganOnCase = {
                                caseSeq: formik.values?.caseSummary?.caseSeq || '',
                                organ: organ,
                                researchNotes: e.target.value,
                                isApprovedForHarvest: false,
                                isRequestedForHarvest: false,
                                isRestrictedFromHarvest: false,
                                isApprovedForResearch: false,
                                isRestrictedFromResearch: false,
                              };
                              updatedOrgans = [
                                ...(organDonationCaseDetails.organs || []),
                                updatedOrganOnCase,
                              ];
                            }

                            formik.setFieldValue(
                              `caseInvestigations.organDonation.organs`,
                              updatedOrgans
                            );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid xs={12}></Grid>

        <Grid xs={12} sm={4}>
          <CaseViewOptionsAutocomplete
            label='Approved By'
            optionsEndpoint= {`get-pathologist-options?jdxList=${encodeURIComponent(formik.values?.caseSummary?.jdxSeq)}`}
            formikField='caseInvestigations.organDonation.approvalGrantedByUser'
            authorizedToEdit={r => [
              r.Medical_Examiner,
              r.Investigator,
              r.Historic_Dataentryonly,
              r.Historicdataentryonly,
              r.Systems_Admin,
            ]}
          />
        </Grid>

        <Grid xs={12} sm={4}>
          <CaseViewDateTimeField
            xs={12}
            sm={12}
            label='Approved On'
            authorizedToEdit={authorizedToEdit}
            fieldName='caseInvestigations.organDonation.approvedOn'
            md={undefined}
            lg={undefined}
            xl={undefined}
            sx={undefined}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

export interface OrganDonation {
  organDonationSeq?: string;
  caseSeq?: string;
  caseId?: string;
  organHarvestApproval?: Option;
  organResearchApproval?: Option;
  approvalGrantedByUser?: Option;
  organDonorOrganization?: Option;
  medicalFacility?: Option;
  organs?: OrganDonationOrgan[];
  transplantCoordinator?: string;
  transplantCoordinatorPhoneNumber?: string;
  notes?: string;
  medicalRecordNo?: string;
  requestDateTime?: Date;
  isFamilyAuthorizationObtained?: boolean;
  isFirstPersonDonor?: boolean;
  isActive: boolean;
}

export interface OrganDonationOrgan {
  caseSeq: string;
  organ?: Option;
  organOrTissueType?: Option;
  isRequestedForHarvest?: boolean;
  isRestrictedFromHarvest?: boolean;
  isRestrictedFromResearch?: boolean;
  harvestNotes?: string;
  researchNotes?: string;
  isApprovedForHarvest?: boolean;
  isApprovedForResearch?: boolean;
}

// export interface OrganDonation {
//   organDonationSeq: string;
//   caseSeq: string;
//   notes: string;
//   isWrittenPermission: boolean;
//   transplantNotes: string;
//   organDonorOrganizationSeq: string;
//   organNotes: string;
//   isActive: boolean;
//   creationDateTime: string;
//   isReferredToME: boolean;
//   transCoordinatorTelNo: string;
//   transPlantCoordinatorName: string;
//   isPlaceOfDeathChecked: boolean;
//   isFirstDonor: boolean;
//   isFamilyAuthorized: boolean;
//   otherOrganDonorOrganization: string;
// }

// export interface Organ {
//   organSeq: string;
//   organName: string;
//   organOrder: number;
//   isActive: boolean;
// }

// export interface OrganDonationApprovalDetail {
//   organDonationApprovalDetailSeq: string;
//   organDonationSeq: string;
//   organHarvestApprovalStatusSeq: string;
//   organResearchApprovalStatusSeq: string;
//   isActive: boolean;
// }

// export interface OrganDonorOrganization {
//   organDonorOrganizationSeq: string;
//   organDonorOrganizationName: string;
//   organDonorOrganizationOrder: number;
//   isActive: boolean;
//   countySeq: string;
//   jdxSeq: string;
// }

// export interface OrganTissueApprovalRestrictionDetail {
//   organTissueApprovalRestrictionDetailSeq: string;
//   organDonationSeq: string;
//   organOrTissueSeq: string;
//   otherOrganOrTissueName: string;
//   donationTypeSeq: string;
//   isApprovedForHarvest: boolean;
//   isApprovedForResearch: boolean;
//   hasOrganRequestedForHarvest: boolean;
//   hasRestrictionForHarvest: boolean;
//   hasRestrictionForResearch: boolean;
//   harvestNotes: string;
//   researchNotes: string;
//   isActive: boolean;
// }

// export interface ApprovalStatus {
//   approvalStatusSeq: string;
//   approvalStatusName: string;
//   approvalStatusOrder: number;
//   isActive: boolean;
// }
