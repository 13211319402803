import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import AddCardIcon from '@mui/icons-material/AddCard';

import { Dashboard, FeedbackRounded } from '@mui/icons-material';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LockResetIcon from '@mui/icons-material/LockReset';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';
import MessageIcon from '@mui/icons-material/Message';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import '../../App.css';
import { getDynamicMenu } from '../../services/utility-api';
import { useAuth } from '../../utils/auth/AuthService';
import CustomDrawer from '../../utils/components/CustomDrawer';
import CustomTabPanel from '../../utils/components/CustomTabPanel';
import { ADMIN_MENU_ICON, SYSTEMS_ADMIN, CASE_ADMIN } from '../CMSAdmin/Menu/constants';
import TreeDrawer from '../../utils/components/TreeDrawer';
import TreeTabPanel from '../../utils/components/TreeTabPanel';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function SysAdminHome() {
  const [redirect, setredirect] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menu2Open, setMenu2Open] = useState(false);
  const [sysAdminMenu, setSysAdminMenu] = useState([]);
  const [cmsAdminMenu, setCmsAdminMenu] = useState([]);
  const [allMenu, setAllMenu] = useState([]);
  const [tabContent, setTabContent] = useState('Users');
  const { user } = useAuth();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenu2Click = () => {
    setMenu2Open(!menu2Open);
  };

  const handleTabChange = (event, newTab) => {
    console.log();

    if (event.target.textContent === 'User Admin') {
      setTabContent('User Admin');
    } else if (event.target.textContent === 'Roles Admin') {
      setTabContent('Roles Admin');
    } else {
      setTabContent(event.target.textContent);
    }
    setCurrentTab(newTab);
  };

  const handleMenuClick = () => {
    setMenuOpen(true);
  };

  useEffect(() => {
    getDynamicMenu(user?.userSeq).then(response => {
      let formatSysAdminMenu = [];
      let formatCmsAdminMenu = [];

      response?.data?.data?.dynamicMenu?.[0]?.children?.filter(menuItems => {
        if (SYSTEMS_ADMIN?.[menuItems?.treeName]) {
          formatSysAdminMenu.push({
            text: menuItems?.treeName,
            id: menuItems?.treeName,
            label: menuItems?.treeName,
            icon: ADMIN_MENU_ICON?.[menuItems?.treeName]?.icon,
          });
        } else if (CASE_ADMIN?.[menuItems?.treeName]) {
          formatCmsAdminMenu.push({
            text: menuItems?.treeName,
            id: menuItems?.treeName,
            label: menuItems?.treeName,
            icon: ADMIN_MENU_ICON?.[menuItems?.treeName]?.icon,
          });
        }
      });
      setAllMenu(response?.data?.data?.dynamicMenu?.[0]?.children);
      setSysAdminMenu(formatSysAdminMenu);
      setCmsAdminMenu(formatCmsAdminMenu);
    });
  }, []);

  const drawerContents = [
    {
      id: 'Users and Roles',
      text: 'Users and Roles',
      label: 'Users and Roles',
      children: [
        { id: 'User Admin', text: 'User Admin', label: 'User Admin', icon: <ManageAccountsIcon /> },
        { id: 'Roles Admin', text: 'Roles Admin', label: 'Roles Admin', icon: <SettingsIcon /> },
      ],
    },
    {
      id: 'Monitoring',
      text: 'Monitoring',
      label: 'Monitoring',
      children: [
        {
          id: 'Locked Cases',
          text: 'Locked Cases',
          label: 'Locked Cases',
          icon: <LockResetIcon />,
        },
        {
          id: 'Server Dashboard',
          text: 'Server Dashboard',
          label: 'Server Dashboard',
          icon: <Dashboard />,
        },
        {
          id: 'User Feedback',
          text: 'User Feedback',
          label: 'User Feedback',
          icon: <FeedbackRounded />,
        },
      ],
    },
    {
      id: 'Admin Resources',
      text: 'Admin Resources',
      label: 'Admin Resources',
      children: [
        {
          id: 'Business Logic',
          text: 'Business Logic',
          label: 'Business Logic',
          icon: <AutoStoriesIcon />,
        },
        {
          id: 'Sites/Locations',
          text: 'Sites/Locations',
          label: 'Sites/Locations',
          icon: <AddBusinessIcon />,
        },
      ],
    },
    {
      id: 'Admin Actions',
      text: 'Admin Actions',
      label: 'Admin Actions',
      children: [
        {
          id: 'Send Bulk Msg',
          text: 'Send Bulk Msg',
          label: 'Send Bulk Msg',
          icon: <MessageIcon />,
        },
        {
          id: 'Home Cards Admin',
          text: 'Home Cards Admin',
          label: 'Home Cards Admin',
          icon: <AddCardIcon />,
        },
        {
          id: 'Global Settings',
          text: 'Global Settings',
          label: 'Global Settings',
          icon: <SettingsIcon />,
        },
      ],
    },

    // { text: 'Upload Resources', icon: <CloudUploadIcon /> },
    ...sysAdminMenu,
    {
      id: 'CMS Admin',
      text: 'CMS Admin',
      label: 'CMS Admin',
      children: [...cmsAdminMenu],
    },
  ];

  const fetchComponent = () => {
    const menuName = allMenu?.filter(content => content?.treeName === tabContent);
    return ADMIN_MENU_ICON?.[tabContent]?.component;
  };

  const drawerWidth = 240;

  return redirect ? (
    <Navigate to={redirect} />
  ) : (
    <Box
      component='section'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <TreeDrawer
        drawerContents={drawerContents}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        setTabContent={setTabContent}
      />
      <Box component='section' sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <TreeTabPanel value={currentTab} index={currentTab} sx={{ margin: 0 }}>
          <Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ ml: 'auto', mr: 2, mt: 1 }}>
                <Hidden mdUp>
                  <Tooltip title='Show Case Menu'>
                    <IconButton
                      size={isMediumScreen ? 'medium' : 'large'}
                      sx={{ width: 'max-content', height: 'max-content' }}
                      onClick={handleMenuClick}
                    >
                      <MenuIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
                    </IconButton>
                  </Tooltip>
                </Hidden>
              </Box>
            </Box>
          </Box>
          {fetchComponent()}
        </TreeTabPanel>
      </Box>
    </Box>
  );
}
