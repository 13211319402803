import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

// material-ui
import { Grid, Box, Button, IconButton, Tooltip, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';

// project imports
import MainCard from '../../../../../components/AdministrationModules/MainCard';
import ReferenceMasterTableFilter from './ReferenceMasterTableFilter';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import AddEdit from './AddEdit';
import {
  activeData,
  deleteData,
  getAdminData,
  getAllCounty,
} from '../../../../../services/utility-api';
import { nameMappingReferenceMaterTable } from '../referenceTypes';
import AccessDenied from '../../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../../components/AdministrationModules/Breadcrumb';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ReferenceMasterTableType2 = () => {
  const { referenceMasterTableName } = useParams();
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const referencePermissions = permissions?.['Reference Data'];
  const masterTableName = Object.keys(nameMappingReferenceMaterTable).includes(
    referenceMasterTableName
  )
    ? nameMappingReferenceMaterTable[referenceMasterTableName]
    : referenceMasterTableName;
  const [isAddEditOn, setIsAddEditOn] = useState();
  const [editObj, setEditObj] = useState({});
  const [maxOrder, setMaxOrder] = useState(0);
  const [isOrder, setIsOrder] = useState(false);
  const [isCounty, setIsCounty] = useState(false);
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] =
    useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState({});
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');

  const [columns, setColumns] = useState([
    {
      field:
        referenceMasterTableName[0].toLowerCase() +
        referenceMasterTableName.slice(1) +
        'Name',
      headerName: masterTableName
        .split('')
        .map((alpha) => {
          if (alpha <= 'Z') return ' ' + alpha;
          return alpha;
        })
        .join('')
        .trim(),
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
  ]);
  const [rows, setRows] = useState([]);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [countyNames, setCountyNames] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const fetchHeaderName = () => {
      const masterTableListName = masterTableName
        .split('')
        .map((alpha) => {
          if (alpha <= 'Z') return ' ' + alpha;
          return alpha;
        })
        .join('')
        .trim();
        return masterTableListName.toLowerCase().includes('county') ? masterTableListName.replace(/County/g, 'Jurisdiction') : masterTableListName 
  }

  const MENU_OBJECT = {
    Home: '/home',
    'Reference Data': '/cmsadmin',
    [fetchHeaderName()]: '',
  };
  const getAllData = async () => {
    setIsLoading(true);
    const response = await getAdminData(referenceMasterTableName);

    const countyResponse = await getAllCounty();
    setCountyNames(countyResponse);

    const data = response?.data?.data?.map((row) => {
      const newObj = {};
      Object.keys(row).map((key) => {
        newObj[key] = row[key];
      });
      newObj.id =
        newObj[
        referenceMasterTableName[0].toLowerCase() +
        referenceMasterTableName.slice(1) +
        'Seq'
        ];
      if (
        newObj[
        referenceMasterTableName[0].toLowerCase() +
        referenceMasterTableName.slice(1) +
        'Order'
        ]
      )
        newObj[
          referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Order'
        ] = Number(
          newObj[
          referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Order'
          ]
        );
      return newObj;
    });

    let isCountyColumnAdded = false;
    columns.forEach((column) => {
      if (column.field == 'countySeq') {
        isCountyColumnAdded = true;
      }
    });
    if (data[0].hasOwnProperty('countySeq') && !isCountyColumnAdded) {
      setColumns((prev) => [
        ...prev,
        {
          field: 'countySeq',
          headerName: 'Jurisdiction',
          // headerClassName: 'super-app-theme--header',
          minWidth: 150,
          align: 'left',
          flex: 5,
          renderCell: (cellValues) => {
            return <>{countyResponse[cellValues.row.countySeq]?.countyName}</>;
          },
        },
      ]);
      setIsCounty(true);
    }

    let isOrderColumnAdded = false;
    columns.forEach((column) => {
      if (
        column.field ==
        referenceMasterTableName[0].toLowerCase() +
        referenceMasterTableName.slice(1) +
        'Order'
      ) {
        isOrderColumnAdded = true;
      }
    });
    if (
      data[0].hasOwnProperty(
        referenceMasterTableName[0].toLowerCase() +
        referenceMasterTableName.slice(1) +
        'Order'
      ) &&
      !isOrderColumnAdded
    ) {
      setColumns((prev) => [
        ...prev,
        {
          field:
            referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Order',
          headerName:
            referenceMasterTableName
              .split('')
              .map((alpha) => {
                if (alpha <= 'Z') return ' ' + alpha;
                return alpha;
              })
              .join('')
              .trim() + ' Order',
          // headerClassName: 'super-app-theme--header',
          minWidth: 200,
          align: 'left',
          flex: 5,
        },
      ]);
      setIsOrder(true);
    }

    let isStatusColumnAdded = false;
    columns.forEach((column) => {
      if (column.field == 'status') {
        isStatusColumnAdded = true;
      }
    });
    if (!isStatusColumnAdded) {
      setColumns((prev) => [
        ...prev,
        {
          field: 'status',
          headerName: 'Status',
          // headerClassName: 'super-app-theme--header',
          renderCell: (cellValues) => {
            const handleClick = async (row) => {
              // if (!permissions?.['Reference Data']?.edit) return;
              setOpenActiveInActiveDialogBox(true);
              const newIconData = row.status === 'Active' ? 'locked' : 'done';
              setActiveInactiveClickData({
                icon: newIconData,
                seq: row[
                  referenceMasterTableName[0].toLowerCase() +
                  referenceMasterTableName.slice(1) +
                  'Seq'
                ],
                status: row.status,
              });
            };

            return Number(
              cellValues.row[
              referenceMasterTableName[0].toLowerCase() +
              referenceMasterTableName.slice(1) +
              'Order'
              ]
            ) > 90000 ? (
              <></>
            ) : (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(cellValues.row);
                }}
              // disabled={!permissions?.['Reference Data']?.edit}
              >
                {cellValues.row.status === 'Active' ? (
                  <Tooltip title='Active'>
                    <LockIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title='InActive'>
                    <DoneIcon />
                  </Tooltip>
                )}
              </Button>
            );
          },
        },
      ]);
    }

    let maxOrder = 0;
    for (let i = 0; i < data.length; i++) {
      if (
        Number(
          data[i][
          referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Order'
          ]
        ) > maxOrder &&
        Number(
          data[i][
          referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Order'
          ]
        ) < 90000
      )
        maxOrder = Number(
          data[i][
          referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Order'
          ]
        );
    }
    setMaxOrder(maxOrder);
    setRows(data);
    setRowsToShow(
      data.filter((row) => row['status'].toLowerCase() == 'active')
    );
    setIsLoading(false);
  };

  const editRow = (params) => {
    if (referencePermissions?.edit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };
  if (!referencePermissions?.view) {
    return (<>
      <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
      <AccessDenied />
    </>)
  }
  else {
    return (
      <>
          <Grid container spacing={2} sx={{mt: '10px', padding:'16px'}}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
                  <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0, mb: 3 }} />
                  {/* <MainCard> */}
                  <Box
                    // sx={{
                    //   width: '100%',
                    //   '& .super-app-theme--header': {
                    //     backgroundColor: 'rgb(2, 136, 209)',
                    //     color: '#fff',
                    //   },
                    // }}
                    style={{ tr: 'hover', cursor: 'pointer' }}
                  >
                  <ReferenceMasterTableFilter
                    rows={rows}
                    isCounty={isCounty}
                    countyNames={countyNames}
                    setRowsToShow={setRowsToShow}
                    activeInActiveFilter={activeInActiveFilter}
                    setActiveInActiveFilter={setActiveInActiveFilter}
                  />

                    <DataGrid
                      initialState={{
                        sorting: {
                          sortModel: [
                            {
                              field:
                                referenceMasterTableName[0].toLowerCase() +
                                referenceMasterTableName.slice(1) +
                                'Name',
                              sort: 'asc',
                            },
                          ],
                        },
                      }}
                      rowHeight={35}
                      rows={rowsToShow}
                      columns={columns} //[...columns, { field: 'status', sortable: false }]
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      disableSelectionOnClick={false}
                      onRowClick={editRow}
                      // sx={{
                      //   m: 2,
                      //   marginLeft: '0px',
                      //   fontSize: '14px',
                      //   fontFamily:
                      //     'Roboto , Helvetica , Arial , sans-serif !important',
                      //   height: 500,

                      //   width: '100%',
                      //   direction: 'row',
                      //   overflow: 'hidden',
                      //   borderRadius: '6px',
                      //   backgroundColor: 'white',
                      //   boxShadow: '0px 0px 10px #ddd',
                      // }}
                      loading={isLoading}
                    />
                  </Box>

                  <Box>
                    <Button
                      variant='contained'
                      sx={{ marginTop: '7px' }}
                      onClick={() => {
                        setIsAddEditOn(false);
                        setTimeout(() => {
                          setEditObj({});
                          setIsAddEditOn(true);
                        }, 200);
                      }}
                      disabled={!referencePermissions?.add}
                    >
                      Add{' '}
                      {fetchHeaderName()}
                    </Button>
                  </Box>
                  <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />      
                  {isAddEditOn && editObj && (
                    <Box sx={{ marginTop: '10px' }}>
                      {/* <MainCard> */}
                      <AddEdit
                        titleName={fetchHeaderName}
                        rows={rows}
                        editObj={editObj}
                        isCounty={isCounty}
                        isOrder={isOrder}
                        referenceMasterTableName={referenceMasterTableName}
                        masterTableName={masterTableName}
                        maxOrder={maxOrder}
                        setIsAddEditOn={setIsAddEditOn}
                        getAllData={getAllData}
                        countyNames={countyNames}
                        referencePermissions={referencePermissions}
                      />
                    </Box>
                  )}

                  <div className='modal'>
                    <Dialog
                      open={openActiveInActiveDialogBox}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => setOpenActiveInActiveDialogBox(false)}
                      aria-describedby='alert-dialog-slide-description'
                    >
                      <DialogTitle></DialogTitle>
                      <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                          Are you sure you want to{' '}
                          {activeInactiveClickData?.status === 'Active'
                            ? 'InActive '
                            : 'Active'}{' '}
                          this{' '}
                          {masterTableName
                            .split('')
                            .map((alpha) => {
                              if (alpha <= 'Z') return ' ' + alpha;
                              return alpha;
                            })
                            .join('')
                            .trim()}
                          ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setOpenActiveInActiveDialogBox(false)}>
                          No
                        </Button>
                        <Button onClick={changeActiveInActiveClick}>Yes</Button>
                      </DialogActions>
                    </Dialog>
                  </div>

                  <div className='modal'>
                    <Dialog
                      open={openApiDialogBox}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => setOpenApiDialogBox(false)}
                      aria-describedby='alert-dialog-slide-description'
                    >
                      <DialogTitle></DialogTitle>
                      <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                          {dialogContents}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <IconButton onClick={() => setOpenApiDialogBox(false)}>
                          <CloseIcon />
                        </IconButton>
                      </DialogActions>
                    </Dialog>
                  </div>
                  {/* </MainCard> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </>
    );
  }
};

export default ReferenceMasterTableType2;
