import React, { useState, useEffect } from 'react';
import Gallery from 'react-image-gallery';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import HomeIcon from '@mui/icons-material/Home';
import { useMediaQuery, useTheme } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import GetAppIcon from '@mui/icons-material/GetApp';
import 'react-image-gallery/styles/css/image-gallery.css';

const CustomGallery = ({ images, selectedImageIndex }) => {
  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(appTheme.breakpoints.up('md'));
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [galleryImages, setGalleryImages] = useState(images);
  const [selectedIndex, setSelectedIndex] = useState(selectedImageIndex);
  const [scrollAmount, setScrollAmount] = useState(0);

  const renderCustomControls = () => {
    const iconStyle = { color: '#fff', cursor: 'pointer', margin: '0 2px' }; // since background is dark, used white color for icons
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <GetAppIcon style={iconStyle} onClick={handleDownloadClick} />
        <ZoomInIcon style={iconStyle} onClick={handleZoomInClick} />
        <ZoomOutIcon style={iconStyle} onClick={handleZoomOutClick} />
        <RotateLeftIcon style={iconStyle} onClick={handleRotateLeftClick} />
        <RotateRightIcon style={iconStyle} onClick={handleRotateRightClick} />
        <HomeIcon style={iconStyle} onClick={handleHomeClick} />
      </div>
    );
  };

  const handleMouseDown = event => {
    event.preventDefault();
    setDragging(true);
    setDragStart({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = event => {
    if (dragging) {
      const offsetX = event.clientX - dragStart.x;
      const offsetY = event.clientY - dragStart.y;
      setDragOffset({ x: offsetX, y: offsetY });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  const handleTouchStart = event => {
    event.preventDefault();
    const touch = event.touches[0];
    setDragging(true);
    setDragStart({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchMove = event => {
    event.preventDefault();
    if (!dragging) return;
    const touch = event.touches[0];
    const offsetX = touch.clientX - dragStart.x;
    const offsetY = touch.clientY - dragStart.y;
    setDragOffset({ x: offsetX, y: offsetY });
  };

  const handleTouchEnd = () => {
    setDragging(false);
  };

  const handleDoubleClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = (newImages[selectedIndex].zoomLevel || 1) === 1 ? 2 : 1;
      return newImages;
    });
  };

  const handleZoomInClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = Math.max(1, (newImages[selectedIndex].zoomLevel || 1) + 0.1);;
      return newImages;
    });
  };

  const handleZoomOutClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = Math.max(0.1, (newImages[selectedIndex].zoomLevel || 1) - 0.1);
      return newImages;
    });
  };

  const handleHomeClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = 1;
      newImages[selectedIndex].rotationAngle = 0;
      return newImages;
    });
    setDragOffset({ x: 0, y: 0 });
  };

  const handleDownloadClick = () => {
    const anchor = document.createElement('a');
    anchor.href = galleryImages[selectedIndex].midsize;
    anchor.download = galleryImages[selectedIndex].originalFileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleRotateLeftClick = () => {
    const currentImage = galleryImages[selectedIndex];

    if (currentImage) {
      currentImage.rotationAngle = (currentImage.rotationAngle || 0) - 90;
      setGalleryImages([...galleryImages]);
    }
  };

  const handleRotateRightClick = () => {
    const currentImage = galleryImages[selectedIndex];

    if (currentImage) {
      currentImage.rotationAngle = (currentImage.rotationAngle || 0) + 90;
      setGalleryImages([...galleryImages]);
    }
  };

  const handleOnChange = index => {
    setSelectedIndex(index);
  };


  const handleScroll = (event) => {
    event.preventDefault();
    setScrollAmount((prev) => prev + event.deltaY);
  };

  useEffect(() => {
    // Check if the accumulated scroll amount crosses the threshold
    if (Math.abs(scrollAmount) > 100) { // Adjust this value for sensitivity
      if (scrollAmount > 0) {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % galleryImages.length);
      } else {
        setSelectedIndex((prevIndex) => (prevIndex - 1 + galleryImages.length) % galleryImages.length);
      }
      // Reset scroll amount after updating index
      setScrollAmount(0);
    }
  }, [scrollAmount, galleryImages.length]);

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <>
      {galleryImages.length > 0 ? (
        <div style={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%' }}>
          <Gallery
            items={galleryImages}
            startIndex={selectedIndex}
            showIndex={true}
            renderCustomControls={renderCustomControls}
            lazyLoad={true}
            useTranslate3D={true}
            onSlide={handleOnChange}
            renderItem={item => (
              <div
                style={{
                  paddingTop: isMobile ? '100%' : isLaptop ? '45%' : '50%',
                  position: 'relative',
                  overflow: 'hidden',
                  cursor: dragging ? 'grabbing' : 'grab',
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onDoubleClick={handleDoubleClick}
                onMouseLeave={handleMouseLeave}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                <img
                  key={item.photoSeq}
                  src={item.midsize}
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    transform: `translate(${dragOffset.x}px, ${dragOffset.y}px) rotate(${
                      item.rotationAngle || 0
                    }deg) scale(${item.zoomLevel || 1})`,
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                  loading='lazy'
                />
                {/* <div
                    style={{
                      position: 'absolute',
                      bottom: '30px',
                      left: '110px',
                      color: 'white',
                      padding: '5px',
                    }}
                  >
                    {item.originalFileName}
                  </div> */}
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '110px',
                    color: 'white',
                    padding: '5px',
                  }}
                >
                  {item.photoLabel}
                </div>
              </div>
            )}
          />
        </div>
      ) : (
        <div>No images to display.</div>
      )}
    </>
  );
};

export default CustomGallery;
