import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService.tsx';
import { renderAutocompleteEditCell } from '../../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import CrudDataGrid from '../../../../utils/components/CrudDataGrid/CrudDataGrid';
import { dateTimeColumnType } from '../../../../utils/components/CrudDataGrid/DateTimeColumn';
import { TimeZone } from '../../../../utils/constants/constants.js';

dayjs.extend(utc);
dayjs.extend(timezone);

const { REACT_APP_API_URL } = process.env;

const SceneExaminedBy = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const [investigatorAndMEOptions, setInvestigatorAndMEOptions] = useState({});
  const formik = useFormikContext();
  const { user } = useAuth();
  const timeZone = TimeZone;

  const sceneExaminedByColumns = [
    {
      flex: 1,
      minWidth: 150,
      field: 'userSeq',
      headerName: 'Scene Examined By',
      editable: true,
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: investigatorAndMEOptions,
      renderEditCell: params => renderAutocompleteEditCell(params, 'userSeq'),
    },
    {
      flex: 1,
      field: 'dateTime',
      headerName: 'Date Time',
      headerAlign: 'left',
      align: 'left',
      ...dateTimeColumnType,
      editable: true,
      valueSetter: params => {
        if (params?.value == null || params?.value == 'Invalid Date') {
          return { ...params.row, dateTime: null };
        } else {
          const localDate = params?.value
            ? dayjs(params?.value).format('YYYY-MM-DDTHH:mm:ss')
            : null;

          return { ...params.row, dateTime: localDate };
        }
      },
    },
  ];

  const handleSceneExaminedBySave = async sceneExaminedBy => {
    let formData = new FormData();
    formData.append('sceneExaminedBy', JSON.stringify(sceneExaminedBy));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);

    if (user && user.token) {
      fetch(REACT_APP_API_URL + 'updateSceneExaminedBy', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {})
        .catch(e => {
          alert(e);
        })
        .finally(() => {
          fetchSceneExaminedBy();
        });
    } else {
      console.log('user or token is null');
    }
    return false;
  };

  const handleSceneExaminedByDelete = async id => {
    if (user && user.token) {
      fetch(REACT_APP_API_URL + 'deleteSceneExaminedBy?id=' + id, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {})
        .catch(e => {
          alert(e);
        })
        .finally(() => {
          fetchSceneExaminedBy();
        });
    } else {
      console.log('user or token is null');
    }
    return false;
  };

  const fetchInvestigatorAndMEOptions = () => {
    fetch(REACT_APP_API_URL + 'getinvestigatorandmeoptions?jdxSeq=' + formik.values?.caseSummary?.jdxSeq, {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setInvestigatorAndMEOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchSceneExaminedBy = () => {
    fetch(REACT_APP_API_URL + 'GetSceneExaminedBy?caseSeq=' + formik.values?.caseSummary?.caseSeq, {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        formik.setFieldValue('caseInvestigations.discovery.sceneExaminedBy', data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  useEffect(() => {
    fetchInvestigatorAndMEOptions();
  }, []);

  return (
    <Grid item xs={12}>
      <CrudDataGrid
        rows={formik.values?.caseInvestigations?.discovery?.sceneExaminedBy || []}
        setRows={data =>
          formik?.handleChange({
            target: {
              value: data,
              name: 'caseInvestigations.discovery.sceneExaminedBy',
            },
          })
        }
        initialColumns={sceneExaminedByColumns}
        createEntry={handleSceneExaminedBySave}
        updateEntry={handleSceneExaminedBySave}
        deleteEntry={handleSceneExaminedByDelete}
        title='Scene Examined By'
        idcolumn='id'
      />
    </Grid>
  );
};

export default SceneExaminedBy;
