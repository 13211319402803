import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  Tabs,
  Tab,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import JSZip from 'jszip';
import React, { useEffect, useState } from 'react';

import DicomViewer from '../../../components/DicomViewer';
import CasePhotos from '../../../components/CaseRecords/CasePhotos';
import { useFormik, useFormikContext } from 'formik';
import { Module_Name } from '../../../utils/constants/constants';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1.5),
  textAlign: 'center',
  width: '100%',
  color: theme.palette.text.primary,
}));

String.prototype.insert = function (index, string) {
  if (index > 0) return this.substring(0, index) + string + this.substring(index, this.length);

  return string + this;
};

export default function MedicalImagingView() {
  const [patientId, setPatientId] = useState('');
  const [allStudies, setAllStudies] = useState([]);
  const [allSeries, setAllSeries] = useState([]);
  const [allInstances, setAllInstances] = useState([]);
  const [study, setStudy] = useState('');
  const [series, setSeries] = useState('');
  const [seriesData, setSeriesData] = useState([]);
  const [instanceData, setInstanceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [studiesExpanded, setStudiesExpanded] = useState(true);
  const [seriesExpanded, setSeriesExpanded] = useState(false);
  const { REACT_APP_API_URL } = process.env;
  const [expanded, setExpanded] = React.useState(false);

  const formik = useFormikContext();

  async function convertZippedBlobToArrayOfBlobs(zippedBlob) {
    try {
      // Create a new JSZip instance
      const zip = new JSZip();

      // Load the zipped blob
      const zipData = await zip.loadAsync(zippedBlob);

      // Create an array to hold the file blobs
      const fileBlobs = [];

      // Get the keys (filenames) of the files in the zipped blob
      const filenames = Object.keys(zipData.files);

      // Loop through the filenames and extract each file
      for (const filename of filenames) {
        const file = zipData.files[filename];

        // Get the file content as an ArrayBuffer
        const fileArrayBuffer = await file.async('arraybuffer');

        // Convert the ArrayBuffer to a Blob
        const fileBlob = new Blob([fileArrayBuffer], { type: 'application/octet-stream' });

        // Add the file blob to the array
        fileBlobs.push(fileBlob);
      }

      // Return the array of file blobs
      return fileBlobs;
    } catch (error) {
      console.error('Error converting zipped blob:', error);
      return [];
    }
  }

  useEffect(() => {
    const fetchPatientId = async () => {
      try {
        const response = await axios.get(
          REACT_APP_API_URL +
            `GetPatientIDFromCaseIDAsync?caseId=${formik.values?.caseSummary?.caseNumber}`
        );
        setPatientId(response.data[0]);

        if (response.data.length == 0) {
          try {
            const updatedCaseString = formik.values?.caseSummary?.caseNumber.replace(/[-]/g, '');
            const response = await axios.get(
              REACT_APP_API_URL + `GetPatientIDFromCaseIDAsync?caseId=${updatedCaseString}`
            );
            setPatientId(response.data[0]);
          } catch (error) {
            console.error('Error fetching patient id:', error);
          }
        }
      } catch (error) {
        console.error('Error fetching patient id:', error);
      }
    };

    fetchPatientId();
  }, []);

  useEffect(() => {
    const fetchStudies = async () => {
      try {
        const response = await axios.get(
          REACT_APP_API_URL + `GetDicomStudiesForPatientAsync?patientId=${patientId}`
        );
        setAllStudies(response.data);
      } catch (error) {
        console.error('Error fetching studies id:', error);
      }
    };

    if (!patientId || patientId == '') {
      return;
    }

    fetchStudies();
  }, [patientId]);

  useEffect(() => {
    const fetchSeries = async () => {
      try {
        const response = await axios.get(
          REACT_APP_API_URL + `GetDicomSeriesForStudyAsync?studyId=${study.id}`
        );
        setAllSeries(response.data);
      } catch (error) {
        console.error('Error fetching series id:', error);
      }
    };

    if (!study || study == '') {
      return;
    }

    fetchSeries();
  }, [study]);

  useEffect(() => {
    const fetchInstances = async () => {
      try {
        const response = await axios.get(
          REACT_APP_API_URL + `GetDicomInstancesForSeriesAsync?seriesId=${series.id}`
        );
        setAllInstances(response.data);
      } catch (error) {
        console.error('Error fetching instances:', error);
      }
    };

    if (!series || series == '') {
      return;
    }

    fetchInstances();
  }, [series]);

  useEffect(() => {
    const fetchDicomSeriesData = async () => {
      const response = await axios.get(
        REACT_APP_API_URL + `GetDicomSeriesDataAsync?seriesId=${series.id}`,
        { responseType: 'blob' }
      );

      try {
        const arrayOfBlobs = await convertZippedBlobToArrayOfBlobs(response.data);
        setSeriesData(arrayOfBlobs);
      } catch (error) {
        console.error('Error processing zipped file:', error);
      }

      // const url = URL.createObjectURL(response.data);
      // const link = document.createElement('a');
      // link.href = url;
      // link.download = 'series.zip';
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    };

    if (!allInstances || allInstances.length == 0) {
      return;
    }

    fetchDicomSeriesData();
  }, [allInstances]);

  const formatDate = dateString => {
    dateString = dateString.insert(4, '-');
    dateString = dateString.insert(7, '-');
    if (dateString.length > 10) {
      dateString = dateString.insert(13, ':');
      dateString = dateString.insert(16, ':');
    }
    return dateString;
  };

  const handleStudiesExpansion = () => {
    setStudiesExpanded(!studiesExpanded);
  };

  const handleSeriesExpansion = () => {
    setSeriesExpanded(!seriesExpanded);
  };

  const StudyIdsList = () => {
    return (
      <Box sx={{ w: '100%' }}>
        {allStudies.map(study => {
          var studyDate = new Date(formatDate(study.mainDicomTags.studyDate));
          var lastUpdatedDate = new Date(formatDate(study.lastUpdate));
          studyDate.setDate(studyDate.getDate() + 1);

          return (
            <Button
              sx={{ p: 0, m: 1, w: '100%' }}
              onClick={() => {
                setStudy(study);
                setSeriesExpanded(true);
                setStudiesExpanded(false);
              }}
            >
              <Item sx={{ w: '100%', textAlign: 'start', display: 'block' }}>
                <Typography variant='body1'>
                  {study.mainDicomTags.studyDescription != ''
                    ? study.mainDicomTags.studyDescription
                    : 'Unknown'}
                </Typography>
                <Typography variant='caption'>
                  {study.mainDicomTags.institutionName != ''
                    ? study.mainDicomTags.institutionName
                    : 'Unknown'}
                </Typography>
                <br />
                <Typography variant='caption'>
                  Created on:{' '}
                  {studyDate.toLocaleDateString() != ''
                    ? studyDate.toLocaleDateString()
                    : 'Unknown'}
                </Typography>
                <br />
                <Typography variant='caption'>
                  Last updated:{' '}
                  {lastUpdatedDate.toLocaleDateString() != ''
                    ? lastUpdatedDate.toLocaleDateString()
                    : 'Unknown'}
                </Typography>
              </Item>
            </Button>
          );
        })}
      </Box>
    );
  };

  const SeriesIdsList = () => {
    return (
      <Box>
        {allSeries.map(series => {
          var seriesDate = new Date(formatDate(series.mainDicomTags.seriesDate));
          var lastUpdatedDate = new Date(formatDate(series.lastUpdate));
          seriesDate.setDate(seriesDate.getDate() + 1);

          return (
            <Button
              sx={{ p: 0, m: 1 }}
              onClick={() => {
                setSeries(series);
                setIsLoading(true);
                setSeriesExpanded(false);
                setStudiesExpanded(false);
              }}
            >
              <Item sx={{ w: '100%', textAlign: 'start', display: 'block' }}>
                <Typography variant='body1'>
                  {series.mainDicomTags.seriesDescription != ''
                    ? series.mainDicomTags.seriesDescription
                    : 'Unknown'}
                </Typography>
                <Typography variant='caption'>
                  {series.mainDicomTags.modality != '' ? series.mainDicomTags.modality : 'Unknown'}
                </Typography>
                <br />
                <Typography variant='caption'>
                  Created on:{' '}
                  {seriesDate.toLocaleDateString() != ''
                    ? seriesDate.toLocaleDateString()
                    : 'Unknown'}
                </Typography>
                <br />
                <Typography variant='caption'>
                  Last updated:{' '}
                  {lastUpdatedDate.toLocaleDateString() != ''
                    ? lastUpdatedDate.toLocaleDateString()
                    : 'Unknown'}
                </Typography>
              </Item>
            </Button>
          );
        })}
      </Box>
    );
  };

  return (
    <Stack>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant='subtitle1'
            color='gray'
            sx={{ fontSize: '.775rem' }} // Adjust the font size as needed
          >
            {' '}
            <strong>MEDICAL IMAGES</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        </Grid>

        <Grid item xs={12}>
          <CasePhotos
            caseSeq={formik.values.caseSummary?.caseSeq}
            caseNumber={formik.values.caseSummary?.caseNumber}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        </Grid>

        {/* 
        <Grid item xs={12}>

            <strong>PACS SERVER IMAGES</strong>
      
        </Grid>
 */}

        {/* implementation of inline orthanc viewer  */}

        {/* <Grid item xs={12} style={{ marginTop: '0rem' }}>
          <Accordion expanded={studiesExpanded} onChange={handleStudiesExpansion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Studies</Typography>
            </AccordionSummary>
            <AccordionDetails>{allStudies.length > 0 ? <StudyIdsList /> : <></>}</AccordionDetails>
          </Accordion>
          <Accordion expanded={seriesExpanded} onChange={handleSeriesExpansion} sx={{ mb: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Series</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {allSeries.length > 0 ? (
                <Stack>
                  <SeriesIdsList />
                </Stack>
              ) : (
                <></>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>

 <Collapse in={expanded}>
        {allStudies.length > 0 ? (
          <StudyIdsList />
        ) : (
          <></>
        )}

        {allSeries.length > 0 ? (
          <Stack>
            <SeriesIdsList />
          </Stack>
        ) : (
          <></>
        )}
      </Collapse>

        {allInstances.length > 0 ? (
          <DicomViewer
            dicomInstanceData={instanceData}
            dicomSeriesData={seriesData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        ) : (
          <></>
        )}  */}
      </Grid>
    </Stack>
  );
}
