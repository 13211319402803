import { Chip, Stack } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';

export function DecedentNameCell(
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
) {
  return (
    <Stack
      direction='row'
      gap={1}
      alignItems='center'
      justifyContent='center'
      sx={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}
      whiteSpace='normal'
    >
      {params.row.decedentname?.toUpperCase()}
      {params.row.decedentName?.toUpperCase()}

      {Boolean(params.row.isTentative) && (
        <Chip
          label='TENT'
          size='small'
          color='error'
          sx={{
            maxHeight: 16,
            '& .MuiChip-label': {
              padding: '0 6px',
            },
          }}
        />
      )}

      {Boolean(params.row?.isUnidentified) && (
        <Chip
          label='UNID'
          size='small'
          color='error'
          sx={{
            maxHeight: 16,
            '& .MuiChip-label': {
              padding: '0 6px',
            },
          }}
        />
      )}

      {Boolean(params.row?.hasObjection) && (
        <Chip
          label='OBJ'
          color='error'
          sx={{
            maxHeight: 16,
            '& .MuiChip-label': {
              padding: '0 6px',
            },
          }}
        />
      )}

      {Boolean(params.row?.isGrouped) && (
        <Chip
          label='G'
          color='warning'
          sx={{
            maxHeight: 16,
            '& .MuiChip-label': {
              padding: '0 6px',
            },
          }}
        />
      )}
    </Stack>
  );
}
