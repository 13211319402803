import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import CustomHeader from '../../../../utils/components/CustomHeader';
import CustomPagination from '../../../../utils/components/CustomPagination';

const { REACT_APP_API_URL } = process.env;

type FeedbackRecord = {
  feedbackSeq: string;
  feedbackSubject: string;
  feedbackBody: string;
  feedbackSubmittedOnDateTime: string;
  submittedByUserName: string;
  submittedByUserEmail: string;
  submittedByPersonFirstName: string;
  submittedByPersonLastName: string;
  isOpen: boolean;
  isActive: boolean;
};

type FilterStatus = 'all' | 'open' | 'closed';

type DraftReply = {
  feedbackSeq: string;
  subject: string;
  body: string;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
));

export function UserFeedbackView() {
  const apiRef = useGridApiRef();
  const auth = useAuth();
  const theme = useTheme();
  const [loadingResolve, setLoadingResolve] = useState<{ [key: string]: boolean }>({});

  const [feedbackLoading, setFeedbackLoading] = useState(true);
  const [feedbackRecords, setFeedbackRecords] = useState<FeedbackRecord[]>([]);
  const [filterStatus, setFilterStatus] = useState<FilterStatus>('all');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });

  const [replyDialogOpen, setReplyDialogOpen] = useState(false);
  const [replyTo, setReplyTo] = useState<FeedbackRecord | null>(null);
  const [replySubject, setReplySubject] = useState('');
  const [replyBody, setReplyBody] = useState('');
  const [sendingReply, setSendingReply] = useState(false);

  const [supportEmailAddress, setSupportEmailAddress] = useState('');
  const [draftReplies, setDraftReplies] = useState<DraftReply[]>([]);

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'info',
  });

  const showSnackbar = (message: string, severity: 'success' | 'info' | 'warning' | 'error') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const fetchFeedback = async () => {
    setFeedbackLoading(true);
    try {
      const response = await fetch(REACT_APP_API_URL + 'getuserfeedback', {
        headers: {
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch feedback');
      }
      const feedback = await response.json();
      setFeedbackRecords(feedback);
    } catch (err) {
      console.error(err);
      showSnackbar('Failed to fetch feedback', 'error');
    } finally {
      setFeedbackLoading(false);
    }
  };

  useEffect(() => {
    fetchFeedback();
    fetchSupportEmailAddress();
  }, []);

  const handleStatusChange = async (feedbackSeq: string, newStatus: boolean) => {
    setLoadingResolve(prev => ({ ...prev, [feedbackSeq]: true }));
    try {
      const response = await fetch(`${REACT_APP_API_URL}updatefeedbackstatus/${feedbackSeq}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
        body: JSON.stringify(!newStatus),
      });

      if (!response.ok) {
        throw new Error('Failed to update feedback status');
      }

      const updatedFeedback = await response.json();

      setFeedbackRecords(prevRecords =>
        prevRecords.map(record =>
          record.feedbackSeq === feedbackSeq
            ? { ...record, isOpen: updatedFeedback.isOpen }
            : record
        )
      );
      showSnackbar('Feedback status updated successfully', 'success');
    } catch (err) {
      console.error(err);
      showSnackbar('Failed to update feedback status', 'error');
    } finally {
      setLoadingResolve(prev => ({ ...prev, [feedbackSeq]: false }));
    }
  };

  const handleReplyClick = (feedback: FeedbackRecord) => {
    setReplyTo(feedback);
    const existingDraft = draftReplies.find(draft => draft.feedbackSeq === feedback.feedbackSeq);
    if (existingDraft) {
      setReplySubject(existingDraft.subject);
      setReplyBody(existingDraft.body);
    } else {
      setReplySubject(`Re: ${feedback.feedbackSubject}`);
      setReplyBody(`
------------------------------
${feedback.feedbackBody}`);
    }
    setReplyDialogOpen(true);
  };

  const handleReplyClose = () => {
    // Remove the confirmation dialog
    setReplyDialogOpen(false);
    setReplyTo(null);
    setReplySubject('');
    setReplyBody('');
  };

  const handleReplySend = async () => {
    if (!replyTo) return;

    const confirmSend = window.confirm('Are you sure you want to send this reply?');
    if (!confirmSend) return;

    setSendingReply(true);
    try {
      const response = await fetch(`${REACT_APP_API_URL}senduserfeedbackreply`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
        body: JSON.stringify({
          feedbackSeq: replyTo.feedbackSeq,
          replyingToEmailAddress: replyTo.submittedByUserEmail,
          subject: replySubject,
          body: replyBody,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send reply');
      }

      setDraftReplies(prevDrafts =>
        prevDrafts.filter(draft => draft.feedbackSeq !== replyTo.feedbackSeq)
      );

      handleReplyClose();
      showSnackbar('Reply sent successfully', 'success');
    } catch (err) {
      console.error(err);
      showSnackbar('Failed to send reply', 'error');
    } finally {
      setSendingReply(false);
    }
  };

  const fetchSupportEmailAddress = async () => {
    try {
      const response = await fetch(REACT_APP_API_URL + 'getsupportemailaddress', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch support email address');
      }
      const email = await response.text();
      setSupportEmailAddress(email);
    } catch (err) {
      console.error(err);
      showSnackbar('Failed to fetch support email address', 'error');
    }
  };

  const filteredFeedbackRecords = useMemo(() => {
    switch (filterStatus) {
      case 'open':
        return feedbackRecords.filter(record => record.isOpen);
      case 'closed':
        return feedbackRecords.filter(record => !record.isOpen);
      default:
        return feedbackRecords;
    }
  }, [feedbackRecords, filterStatus]);

  const columns: GridColDef[] = [
    {
      field: 'feedbackBody',
      headerName: 'Feedback',
      flex: 3,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams) => (
        <Box
          sx={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            width: '100%',
            maxHeight: '100%',
            overflow: 'auto',
          }}
        >
          <Typography
            component='pre'
            style={{
              fontFamily: 'inherit',
              margin: 0,
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {params.row.feedbackBody}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'submittedBy',
      headerName: 'Submitted By',
      flex: 1,
      minWidth: 150,
      valueGetter: params =>
        `${params.row.submittedByPersonLastName}, ${params.row.submittedByPersonFirstName}`,
    },
    {
      field: 'submittedByUserName',
      headerName: 'User Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'submittedByUserEmail',
      headerName: 'Email',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'feedbackSubmittedOnDateTime',
      headerName: 'Created Date/Time',
      type: 'dateTime',
      valueGetter: params => params.value && new Date(params.value),
      valueFormatter: params => {
        return params.value ? format(new Date(params.value), 'MM/dd/yyyy, HH:mm') : null;
      },
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'isOpen',
      headerName: 'Status',
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Typography color={params.value ? theme.palette.warning.main : theme.palette.success.main}>
          {params.value ? 'Open' : 'Closed'}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <LoadingButton
            loading={loadingResolve[params.row.feedbackSeq] || false}
            variant='contained'
            color={params.row.isOpen ? 'success' : 'warning'}
            onClick={() => handleStatusChange(params.row.feedbackSeq, params.row.isOpen)}
            sx={{ mr: 1 }}
          >
            {params.row.isOpen ? 'Close' : 'Reopen'}
          </LoadingButton>
          {params.row.isOpen && (
            <Button variant='outlined' color='primary' onClick={() => handleReplyClick(params.row)}>
              Reply
            </Button>
          )}
        </Box>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ justifyContent: 'space-between', p: 1.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h6' sx={{ mr: 2 }}>
            {filteredFeedbackRecords.length} Feedback Items
          </Typography>
          <ToggleButtonGroup
            value={filterStatus}
            exclusive
            onChange={(event, newFilter) => {
              if (newFilter !== null) {
                setFilterStatus(newFilter);
              }
            }}
            aria-label='feedback status filter'
            size='small'
          >
            <ToggleButton value='all' aria-label='show all'>
              All
            </ToggleButton>
            <ToggleButton value='open' aria-label='show open'>
              Open
            </ToggleButton>
            <ToggleButton value='closed' aria-label='show closed'>
              Closed
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Button onClick={fetchFeedback} startIcon={<RefreshIcon />}>
          Refresh
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <Box>
      <CustomHeader
        title='User Feedback'
        description='View and manage user feedback submissions.'
        showMenuButton={false}
      />

      <Grid container spacing={2}>
        <Grid item xs='auto'>
          <DataGridPremium
            apiRef={apiRef}
            density='comfortable'
            columns={columns}
            rows={filteredFeedbackRecords}
            loading={feedbackLoading}
            getRowId={row => row.feedbackSeq}
            autoHeight
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            getRowHeight={() => 'auto'}
            sx={{
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
                lineHeight: 'normal',
                padding: '8px',
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiDataGrid-row': {
                maxHeight: 'none !important',
              },
            }}
          />
        </Grid>
      </Grid>

      <Dialog open={replyDialogOpen} onClose={handleReplyClose} maxWidth='md' fullWidth>
        <DialogTitle>Reply to Feedback</DialogTitle>
        <DialogContent>
          <TextField
            margin='dense'
            label='From'
            type='email'
            fullWidth
            variant='standard'
            value='ocsme.support@doh.nj.gov'
            disabled
          />
          <TextField
            margin='dense'
            label='To'
            type='email'
            fullWidth
            variant='standard'
            value={replyTo?.submittedByUserEmail || ''}
            disabled
          />
          <TextField
            margin='dense'
            label='BCC'
            type='email'
            fullWidth
            variant='standard'
            value={auth.user.primaryEmail}
            disabled
          />
          <TextField
            margin='dense'
            label='Subject'
            type='text'
            fullWidth
            variant='filled'
            value={replySubject}
            onChange={e => setReplySubject(e.target.value)}
          />
          <TextField
            margin='dense'
            label='Body'
            multiline
            minRows={4}
            rows={12}
            fullWidth
            variant='filled'
            value={replyBody}
            onChange={e => setReplyBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReplyClose}>Cancel</Button>
          <LoadingButton onClick={handleReplySend} loading={sendingReply} variant='contained'>
            Send Reply
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
