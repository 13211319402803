import { SxProps, TextField, Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface EditingTypographyProps extends TypographyProps {
  editing?: boolean;
  children?: React.ReactNode;
  sx?: SxProps;
  placeholder?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export default function EditingTypography({
  editing = false,
  children,
  variant = 'body1',
  sx = {},
  placeholder,
  value,
  onChange,
  ...typographyProps
}: EditingTypographyProps) {
  const variantFontSizeMap: { [key: string]: string } = {
    h1: '4.4rem',
    h2: '2.75rem',
    h3: '2.2rem',
    h4: '1.55rem',
    h5: '1.1rem',
    h6: '0.915rem',
    subtitle1: '0.73rem',
    subtitle2: '0.64rem',
    body1: '0.73rem',
    body2: '0.64rem',
    caption: '0.54rem',
    overline: '0.555rem',
  };

  const variantFontWeightMap: { [key: string]: number } = {
    h1: 300,
    h2: 300,
    h3: 400,
    h4: 400,
    h5: 400,
    h6: 500,
    subtitle1: 400,
    subtitle2: 500,
    body1: 400,
    body2: 400,
    caption: 400,
    overline: 300,
  };

  const fontSize = (sx as any)?.fontSize ?? (variantFontSizeMap[variant] || '1rem');
  const fontWeight = (sx as any)?.fontWeight ?? (variantFontWeightMap[variant] || 400);

  return editing ? (
    <TextField
      multiline
      variant='standard'
      value={typeof children === 'string' ? children : ''}
      onChange={onChange}
      fullWidth
      InputProps={{
        style: { fontSize, fontWeight },
      }}
      sx={sx}
      placeholder={placeholder}
    />
  ) : (
    <Typography variant={variant} sx={sx} {...typographyProps}>
      {children}
    </Typography>
  );
}
