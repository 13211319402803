import { Close, Delete, Save } from '@mui/icons-material';
import Edit from '@mui/icons-material/Edit';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import EditingTypography from './EditingTypography';
import { Card } from './HomeCardsAdminView';

interface HomeCardProps {
  allCards: Card[];
  setAllCards: React.Dispatch<React.SetStateAction<Card[]>>;
  cardSeq: string;
  initEditing?: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function HomeCard({
  allCards,
  setAllCards,
  cardSeq,
  initEditing = false,
  setRefresh,
}: HomeCardProps) {
  const [editing, setEditing] = useState(initEditing);
  const [card, setCard] = useState(allCards.find(card => card.homeCardSeq === cardSeq));
  const cardBuffer = allCards.find(card => card.homeCardSeq === cardSeq);
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const isCardEmpty = card => {
    return (
      card.title === '' &&
      card.subtitle === '' &&
      card.content1 === '' &&
      card.content2 === '' &&
      card.content3 === ''
    );
  };

  const deleteCard = async () => {
    if (isCardEmpty(cardBuffer)) {
      setAllCards((prevCardContent: Card[]) =>
        prevCardContent.filter(currentCard => currentCard.homeCardSeq !== cardSeq)
      );
    } else {
      try {
        const response = await fetch(
          `${REACT_APP_API_URL}home/deletehomecard?homeCardSeq=${cardSeq}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );

        if (response.ok) {
          // Remove the card from the local state
          setAllCards((prevCardContent: Card[]) =>
            prevCardContent.filter(currentCard => currentCard.homeCardSeq !== cardSeq)
          );
          setRefresh(true); // Trigger a refresh to reload data
        } else {
          console.error('Failed to delete card');
        }
      } catch (error) {
        console.error('Error deleting card:', error);
      }
    }
  };

  const cancelChanges = () => {
    setCard(cardBuffer);
    setEditing(false);
  };

  useEffect(() => {
    console.log(card);
  }, [card]);

  const saveCard = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}home/updatehomecard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify(card),
      });

      if (response.ok) {
        setRefresh(true);
        setEditing(false);
      } else {
        console.error('Failed to update card');
      }
    } catch (error) {
      console.error('Error updating card:', error);
    }
  };

  return (
    <Paper sx={{ p: 3, borderRadius: 4, position: 'relative' }}>
      <EditingTypography
        value={card.title}
        onChange={event => setCard({ ...card, title: event?.target?.value })}
        editing={editing}
        variant='h3'
        sx={{ fontWeight: 500 }}
        placeholder='Title'
      >
        {card.title}
      </EditingTypography>

      <EditingTypography
        value={card.subtitle}
        onChange={event => setCard({ ...card, subtitle: event?.target?.value })}
        editing={editing}
        variant='h5'
        sx={{ opacity: 0.6 }}
        placeholder='Subtitle'
      >
        {card.subtitle}
      </EditingTypography>

      <Box sx={{ py: 2 }}>
        <EditingTypography
          value={card.content1}
          onChange={event => setCard({ ...card, content1: event?.target?.value })}
          editing={editing}
          variant='body1'
          sx={{ fontSize: 13 }}
          placeholder='Content 1'
        >
          {card.content1}
        </EditingTypography>
        <EditingTypography
          value={card.content2}
          onChange={event => setCard({ ...card, content2: event?.target?.value })}
          editing={editing}
          variant='body2'
          sx={{ fontSize: 11, pt: 1 }}
          placeholder='Content 2'
        >
          {card.content2}
        </EditingTypography>
        <EditingTypography
          value={card.content3}
          onChange={event => setCard({ ...card, content3: event?.target?.value })}
          editing={editing}
          variant='body2'
          sx={{ fontSize: 11, pt: 1, opacity: 0.6 }}
          placeholder='Content 3'
        >
          {card.content3}
        </EditingTypography>
      </Box>

      <Stack direction='row' spacing={2} sx={{ position: 'absolute', top: 0, right: 0, p: 2 }}>
        {editing ? (
          <IconButton onClick={saveCard}>
            <Save color='primary' />
          </IconButton>
        ) : (
          <IconButton onClick={toggleEditing}>
            <Edit color='primary' />
          </IconButton>
        )}

        {editing ? (
          <IconButton onClick={cancelChanges}>
            <Close color='error' />
          </IconButton>
        ) : (
          <IconButton onClick={deleteCard}>
            <Delete color='error' />
          </IconButton>
        )}
      </Stack>
    </Paper>
  );
}
