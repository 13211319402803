import { Card, CardContent, CardHeader, Typography, useTheme, Paper, Box } from '@mui/material';

export default function DesktopCard({ title, subtitle = '', content }) {
  const theme = useTheme();

  return (
    <Paper sx={{ p: 3, borderRadius: 4 }}>
      <Typography variant='h3' sx={{ fontWeight: 500 }}>
        {title}
      </Typography>
      <Typography variant='h5' sx={{ opacity: 0.6 }}>
        {subtitle}
      </Typography>
      <Box sx={{ py: 2 }}>{content}</Box>
    </Paper>
    // <Card sx={{ borderRadius: 4, height: '100%', width: '100%' }}>
    //   <CardHeader title={title} subheader={subtitle} />
    //   <CardContent sx={{ p: 0, pb: 0 }}>{content}</CardContent>
    // </Card>
  );
}
